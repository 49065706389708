<template>
  <el-collapse-item>
    <template slot="title">
      <i class="fad fa-fw fa-warehouse" />
      <span class="collapse-text">{{ T.exports_page.receiptGood.head }}</span>
    </template>
    <div class="receiptGoods si-control vue-items-list">
      <div class="filters">
        <date-range-picker
          v-model="dateRange"
          :value-format="'yyyy-MM-dd'"
          :application-date-format="dateFormat"
        />
      </div>
      <div class="buttons vue-list">
        <button
          class="vue-list-item"
          @click="exportReceiptGoods()"
        >
          {{ T.download }}
        </button>
      </div>
    </div>
  </el-collapse-item>
</template>

<script>
import { receiptGoods } from '../request_data.js'
import { headers } from '../const.js'
import eventMethods from '../events.js'

import DateRangePicker from '@/vue_components/common/select/date_range_picker.vue'

export default {
  name: 'ReceiptGoodsExport',

  components: {
    DateRangePicker,
  },

  props: {
    withLocale: {
      default: false,
      type: Boolean,
    },
  },

  data () {
    return {
      dateRange: [],
      dateFormat: gon.localization.date_format,
    }
  },

  methods: {
    ...eventMethods,

    exportReceiptGoods () {
      this.$emit('request-state')

      const data = {
        ...receiptGoods,
        withLocale: this.withLocale,
        date: this.dateRange,
      }

      fetch(Routes.exports_receipt_goods_path(), {
        method: 'POST',
        headers: {
          ...headers,
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        credentials: 'same-origin',
        body: JSON.stringify(data),
      })
        .then(this._handleErrors)
        .then((result) => {
          Utils.downloadExportFile(result, T.exports_page.receiptGood.head)
        })
        .catch((error) => {
          this._errorNotify(error)
        })
        .finally(() => {
          this.$emit('request-state')
        })
    },
  },
}
</script>
