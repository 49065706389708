import ApiClient from '@/vue_components/api/client.vue'
import { createVueApp } from '@/vue_components/create_vue_app'

$document.on('rez/api/clients', function () {
  if (gon.specific.moduleIsEnabled) {
    createVueApp({
      el: '#api_clients_root_container',
      name: 'ApiClientApp',
      render: (h) => h(ApiClient),
    })
  }
})
