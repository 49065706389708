import { createVueApp } from '@/vue_components/create_vue_app'
import ResponsibleApp from '@/vue_apps/TreatmentPlans/ResponsibleApp/ResponsibleApp.vue'

let vueApp = null
export const createResponsibleApp = (el = '#treatment_plan_responsible_id') => {
  if (vueApp?.destroy) { return vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'ResponsibleAppRoot',
    render: (h) => h(ResponsibleApp, {
      props: {
        elementId: el.slice(1),
      },
    }),
  })
}
