<template>
  <div
    id="reminders-list-wrapper"
    class="side-menu-wrapper"
  >
    <div class="schedule-reminders-list-control-element">
      <div class="reminders-datepicker">
        <div
          class="btn btn-sm btn-success reminders-datepicker-left"
          @click="_datePickerOnClick('prev')"
        >
          <span class="fad fa-fw fa-angle-double-left" />
        </div>
        <div class="date-container">
          {{ formattedDate }}
        </div>
        <div
          class="btn btn-sm btn-success reminders-datepicker-right"
          @click="_datePickerOnClick('next')"
        >
          <span class="fad fa-fw fa-angle-double-right" />
        </div>
      </div>
      <guarded-control
        tag="button"
        :permissions="['canManageReminder']"
        class="add-reminder btn btn-success btn-with-icon btn-sm"
        @click:allowed="$emit('create-reminder-item')"
      >
        <span class="btn-with-icon_icon fad fa-fw fa-pen" />
        <span class="btn-with-icon_text">
          {{ T.add_reminder }}
        </span>
      </guarded-control>
    </div>
    <div
      ref="list"
      class="reminders-list"
    >
      <ul v-if="remindersList.length">
        <li
          v-for="reminder in remindersList"
          :key="reminder.id"
          :data-id="reminder.id"
          @click="_reminderOnClick(reminder)"
        >
          <div class="right-container cut">
            <span
              class="reminder-status-icon fad fa-fw"
              :class="_getStatusIcon(reminder.status)"
            />

            <span
              class="fad fa-fw"
              :class="_getTypeIcon(reminder.reminder_type)"
            />

            <span class="reminder-title cut">{{ reminder.title }}</span>
          </div>
        </li>
      </ul>
      <div v-if="showEmpty">
        {{ t('has_no_upcoming_reminders') }}
      </div>
    </div>
  </div>
</template>

<script>
import { fetchReminders } from '@/vue_components/doctor_schedules/rest'
import { mapGetters } from 'vuex'
import GuardedControl from '@/vue_components/common/guarded_control'
import formatter from '@/lib/formatters/formatter'
import {
  ITEM_CREATED,
  ITEM_DELETED,
  ITEM_UPDATED,
  REMINDERS_LIST_ITEM_HEIGHT,
  SIDEBAR_LAZY_LOAD_DEBOUNCE,
  SIDEBAR_LAZY_LOAD_THRESHOLD,
} from '@/vue_components/doctor_schedules/consts'
import { debounce, throttle } from 'lodash/function'
import { enrichReminder } from '@/vue_components/doctor_schedules/logic/reminder'

const REQUEST_DELAY = 500

export default {
  name: 'RemindersList',
  components: { GuardedControl },
  props: {
    visibility: Boolean,

    remindersCount: {
      type: Number,
      required: true,
    },

    currentUserId: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data () {
    return {
      remindersList: [],
      request: null,
      requestDelay: null,
      requestWithDelay: false,
      limit: null,
      offset: 0,
    }
  },

  computed: {
    ...mapGetters([
      'GET_SCHEDULE_CURRENT_CLINIC_ID',
      'GET_SCHEDULE_DATE',
      'GET_LOCALIZATION_DATE_FORMAT',
      'GET_LOADING_RIGHT_SIDEBAR',
      'GET_APP_CONF_CURRENT_USER_ID',
    ]),

    showEmpty () {
      return !(this.GET_LOADING_RIGHT_SIDEBAR || this.remindersList.length)
    },

    date: {
      get () {
        return this.$store.getters.GET_SCHEDULE_DATE
      },
      set (value) {
        this.$store.dispatch('setScheduleDate', value)
      },
    },

    formattedDate () {
      return Utils.formatClosestDates(this.date, 'ddd, DD MMMM', this.GET_LOCALIZATION_DATE_FORMAT).title
    },

    canLoadMoreItems () {
      return !this.GET_LOADING_RIGHT_SIDEBAR && !this.allItemsLoaded
    },

    allItemsLoaded () {
      return this.remindersList.length === this.remindersCount
    },
  },

  watch: {
    visibility () {
      if (this.visibility) {
        this.requestData()
      }
    },

    remindersList () {
      this.offset = this.remindersList.length
    },

    GET_SCHEDULE_CURRENT_CLINIC_ID () {
      this.remindersList = []
    },

    date () {
      this.$emit('count-change')
      if (this.requestWithDelay) {
        this.requestWithDelay = false
      } else {
        this.remindersList = []
        this.requestDataDebounced()
      }
    },
  },

  mounted () {
    const { list } = this.$refs
    list.addEventListener('scroll', this.loadMoreItems)
    window.addEventListener('resize', this.loadMoreItems)

    Services.wsSubscriptions.reminder.connect(
      (reminder) => {
        reminder.data = enrichReminder(reminder.data)
        this.$emit('count-change')
        this._remindersListUpdatesHandler(reminder)
      }
    )

    this.visibility && this.requestData()
  },

  beforeDestroy () {
    const list = this.$refs.list
    list.removeEventListener('scroll', this.loadMoreItems)
    window.removeEventListener('resize', this.loadMoreItems)
  },

  methods: {
    _reminderOnClick (reminder) {
      this.$emit('edit-reminder-item', reminder)
    },

    /**
     * @param {'next'|'prev'} step
     */
    _datePickerOnClick (step) {
      if (this.requestDelay) window.clearTimeout(this.requestDelay)

      const momentDate = moment(this.GET_SCHEDULE_DATE, this.GET_LOCALIZATION_DATE_FORMAT)
      const updatedDate = step === 'next' ? momentDate.add(1, 'days') : momentDate.subtract(1, 'days')
      this.requestWithDelay = true
      this.date = updatedDate.format(this.GET_LOCALIZATION_DATE_FORMAT)

      this.requestDelay = window.setTimeout(() => {
        if (this.request) {
          this.request.abort()
        }
        this.requestData(true)
      }, REQUEST_DELAY)
    },

    getItemIndex (reminder) {
      return this.remindersList.findIndex((item) => item.id === reminder.id)
    },

    _remindersListUpdatesHandler (packet) {
      const reminder = packet.data

      switch (packet.action) {
        case ITEM_CREATED: {
          if (this.reminderMatchesFilters(reminder)) {
            this.addItem(reminder)
          }
          break
        }
        case ITEM_UPDATED: {
          const reminderIndex = this.getItemIndex(reminder)
          if (this.reminderMatchesFilters(reminder)) {
            if (reminderIndex >= 0) {
              this.updateItem(reminder, reminderIndex)
            } else {
              this.addItem(reminder)
            }
          } else {
            // если после обновления задача перестала удовлетворять фильтрам расписания,
            // её нужно удалить из списка
            this.removeItem(reminderIndex)
          }
          break
        }
        case ITEM_DELETED: {
          this.removeItem(this.getItemIndex(reminder))
        }
      }
    },

    _removeReminder (id) {
      this.remindersList = this.remindersList.filter((reminder) => reminder.id !== id)
    },

    /**
     * @param {object}
     * @return {boolean}
     */
    reminderMatchesFilters ({ date, clinic_id: clinicId, responsibles }) {
      return formatter.formatValue(date, ['date:localDate']) === this.date &&
        (clinicId === null || clinicId === this.GET_SCHEDULE_CURRENT_CLINIC_ID) &&
        (!responsibles.length || responsibles.some(({ id }) => id === this.GET_APP_CONF_CURRENT_USER_ID))
    },

    _getStatusIcon (status) {
      switch (status) {
        case 'done': return 'fa-check-square'
        case 'pending': return 'fa-square'
        case 'suspend': return 'fa-pause-circle'
        case 'fail': return 'fa-handshake-slash'
        case 'cancel': return 'fa-ban'
        default: return ''
      }
    },

    _getTypeIcon (type) {
      switch (type) {
        case 'call': return 'fa-phone-volume'
        case 'business': return 'fa-user-hard-hat'
        case 'email': return 'fa-envelope'
        default: return ''
      }
    },

    loadMoreItems: throttle(function (e) {
      const bottomListPosition = (e.target.scrollTop + e.target.clientHeight) / e.target.scrollHeight
      if (bottomListPosition < SIDEBAR_LAZY_LOAD_THRESHOLD) return
      if (!this.canLoadMoreItems) return

      this.requestDataDebounced()
    }, SIDEBAR_LAZY_LOAD_DEBOUNCE),

    requestDataDebounced: debounce(function (resetAll) {
      this.requestData(resetAll)
    }, SIDEBAR_LAZY_LOAD_DEBOUNCE),

    requestData (resetAll = false) {
      if (!this.$security.canViewReminder) return
      if (!this.visibility) return

      this.$store.commit('SET_LOADING_RIGHT_SIDEBAR', true)
      this.calculateLimit()
      if (resetAll) {
        this.offset = 0
      }

      const request = fetchReminders({
        date: Utils.dateJStoRuby(moment(this.date, this.GET_LOCALIZATION_DATE_FORMAT).toDate()),
        current_clinic_id: this.GET_SCHEDULE_CURRENT_CLINIC_ID,
        offset: this.offset,
        limit: this.limit,
      })
      this.request = request
        .done((reminders) => {
          if (resetAll) {
            this.scrollUp()
            this.remindersList = []
          }

          this.remindersList.push(...reminders)
        })
        .fail(() => {
          Notificator.error(t('abstract_error_message'))
        })
        .always(() => {
          this.$store.commit('SET_LOADING_RIGHT_SIDEBAR', false)
        })
    },

    calculateLimit () {
      this.limit = Math.ceil(this.$refs.list.clientHeight / REMINDERS_LIST_ITEM_HEIGHT) * 2
    },

    scrollUp () {
      this.$refs.list.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },

    addItem (reminder) {
      this.remindersList.unshift(reminder)
    },

    updateItem (reminder, index) {
      if (index >= 0) {
        this.$set(this.remindersList, index, reminder)
      }
    },

    removeItem (index) {
      if (index >= 0) {
        this.remindersList.splice(index, 1)
      }
    },
  },
}
</script>
