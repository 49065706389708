import './feedbacks/client_feedback'
import MergeClientModal from '@/vue_components/merge_client_modal.vue'
import BonusPointsModal from '@/vue_components/bonus_points/bonus_points_modal.vue'
import pubSub from '@/services/epic_pub_sub.js'
import { showPaymentComments } from '../payments/payment_comments'
import { Intent } from '@/services/intents'
import { setSubmitClickHook, snilsAddMask } from '@/specific/clients/methods/snils'
import { createEgiszClientCard } from '@/vue_components/egisz/egisz_client_card'
import { clientLegacyPromobotHook } from '@/helpers/Promobot/clientLegacyHook/clientLegacyPromobotHook'
import { createClientsListApp } from '@/vue_apps/ClientsModule/components/ClientsList'
import { createDebtCheckerApp } from '@/vue_apps/ClientsModule/components/DebtChecker'
import { createTreatmentPlansListClientApp } from '@/vue_apps/TreatmentPlans/TreatmentPlansList'
import { createClientsModuleDocumentsApp } from '@/vue_apps/ClientsModule/components/Documents'
import { createMedicalPolicesApp } from '@/vue_apps/ClientsModule/components/MedicalPolices'
import { createVueApp } from '@/vue_components/create_vue_app'
import { cloneDeep } from 'lodash'

$document.on('rez/clients', function () {
  $('.order-link').on('click', function (e) {
    e.stopPropagation()
  })

  if (gon.page.action === 'show') {
    createVueApp({
      el: '#client_merge_modal_container',
      name: 'MergeClientModalApp',
      render: (h) => h(MergeClientModal, {
        props: { client: cloneDeep(gon.specific.client) },
      }),
    })

    $('#merge_client_modal_btn').click(() => pubSub.emitAsync('MERGE_CLIENT_MODAL.SHOW'))

    showPaymentComments(gon.specific.client_id)

    const changeBonusSelector = '#change_bonus_points_modal'
    if (document.querySelector(changeBonusSelector)) {
      createVueApp({
        el: changeBonusSelector,
        name: 'ChangeBonusPointsModal',
        render: (h) => h(BonusPointsModal, {
          props: { clientId: gon.specific.client_id },
        }),
      })
    }

    Services.pubSub.subscribe('CLIENT_BONUS_POINTS.SET_VALUE', (bonusPoints) => {
      $('.bonus_points_value').text(bonusPoints)
    })

    createEgiszClientCard('#vue_send_client_data_to_egisz')
  }
})

$document.on('rez/clients/index', function () {
  createClientsListApp()
})

$document.on('rez/clients/new rez/clients/edit', function () {
  let form
  switch (pageInfo.action) {
    case 'new':
      form = FormFactory.build('client', { modal: false, mode: 'new' })
      form.newItem()
      break
    case 'edit':
      form = FormFactory.build('client', { modal: false, mode: 'edit' })
      form.editItem()
      break
  }

  const btnRandData = $('#past_random_data')
  btnRandData.pastFakeClientData()
})

$document.on('rez/clients/show', function () {
  const $delete = $('#delete-record')

  $delete.deleteButton({
    url: Routes.client_path(gon.specific.client.id),
    redirect: Routes.clients_path(),
    name: t('client_lowercase'),
  })

  createDebtCheckerApp()
})

/// /////////////////////infocard_methods///////////////////////////////////

$document.on('rez/clients/documents', function () {
  DocumentForm.init({
    documents: gon.specific.documents,
    client_id: gon.specific.client_id,
    clientLegalRepresentatives: gon.specific.client.legal_representatives,
    clientLegalRepresentativesModalSelector: '#client_legal_representatives_modal',
    documentDatesModalSelector: '#document_dates_modal',
    itemEditable: true,
    groupEditable: true, //enable document type edit in client
    inModal: true,
    groupEditOlnly: true,
    source: 'show',
    redirectDestination: 'client_show', //method in controller for redirect after action
  })

  createClientsModuleDocumentsApp()
})

$document.on('rez/clients/orders', function () {
  $('#user_id').select2({ width: '180px' })
})

$document.on('rez/clients/entries', function () {
  $('#entry_type_id').select2({ width: '180px' })
  $('#user_id').select2({ width: '180px' })
})

$document.on('rez/clients/reminders', function () {
  ReminderModule.init()
})

$document.on('rez/clients/calls', function () {
  CallModule.init()

  $('.call_record_player_show').popover({
    trigger: 'manual',
    toggle: 'popover',
    html: true,
    content: '<audio controls=true ></audio>',
    placement: 'auto',
  })
  Utils.updateTooltips(document.querySelector('#modal_call_show'))
  recordBtnEvents()

  function recordBtnEvents () {
    $('tbody').on('scroll', function () {
      if ($('.popover').length > 0) {
        const popover = $('.popover')
        const sender = $('.call_record_player_show.active')
        const adjustment = (sender.position().top - popover.height()) + 58
        popover.css({ top: adjustment })
      }
    })

    $('.call_record_player_show').on('show.bs.popover', function () {
      hidePopovers(this)
    })
    $('.call_record_player_show').on('shown.bs.popover', function () {
      $(this).addClass('active')
      $(this).parents('tr').addClass('selected')
    })
    $('.call_record_player_show').on('hidden.bs.popover', function () {
      $(this).removeClass('active')
      $(this).parents('tr').removeClass('selected')
    })
    $('.call_record_player_show').on('click', function (e) {
      e.stopImmediatePropagation()
      const fileLink = $(this).data('file-link')

      const mangoId = fileLink.split('!')
      // if provider is mango fileLink is somthing like mango!MToxMDEzNDgzNTo5MDQ0Njg2NDc2OjA=
      // split fileLink and get record id stored in mangoId[1]
      if (mangoId[0] === 'mango') {
        getMangoRecordUrl(mangoId[1], this)
      } else {
        // for all other providers
        $(this).popover('toggle')
        $(this.nextSibling).find('audio').html("<source src='" + fileLink + "' type='audio/mpeg'></source>")
      }
    })
  }

  function getMangoRecordUrl (recordId, el) {
    $.ajax({
      url: Routes.call_record_get_telephony_telephony_route_index_path('mango', { record_id: recordId }),
      method: 'POST',
      beforeSend () {
        $(el).popover('toggle')
        $('.popover-content').loadSpin('start')
      },
      success (data) {
        if (data.url.status) {
          Notificator.error(t('telephony.mango.errors.' + data.url.status))
          $(el).popover('toggle')

          return
        }
        // mp3 file load from mango temp link and insert in player like blob
        const xhr = new XMLHttpRequest()
        xhr.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            const audio = window.URL.createObjectURL(this.response)
            $(el.nextSibling).find('audio').html("<source src='" + audio + "' type='audio/mpeg' preload='auto'></source>")
            $('.popover-content').loadSpin('stop')
          }
        }
        xhr.open('GET', data.url)
        xhr.responseType = 'blob'
        xhr.send()
      },
      error (data) {
        console.error(data)
        $(el).popover('toggle')
        $('.popover-content').loadSpin('stop')
        Notificator.error(t('telephony.mango.errors.' + data.responseJSON))
      },
    })
  }

  function hidePopovers (filterElement = null) {
    $('.call_record_player_show').not(filterElement).popover('hide')
    $('.call_record_player_show').removeClass('active')
    $('.call_row').removeClass('selected')
  }

  $('.panel-body').on('click', function () {
    $('.popover').hide()
  })
})

$document.on('rez/clients/appointments', function () {
  $('#user_id').select2({ width: '180px' })
  $('#specialty_id').select2({ width: '180px' })
  $('.logo-clinics-in-table').on('error', function () {
    $(this).parent().html(`<span class="clinic-name-in-table">${$(this).data('clinic-name')}</span>`)
  })
  document.getElementById('add-appointment').addEventListener('click', function () {
    const params = {
      clientId: +this.dataset.insertClient || null,
    }
    const newWindow = window.open(Routes.doctor_schedules_path(), '_blank')
    Intent.setIntent(
      newWindow,
      Intent.SCHEDULE_APPOINTMENT_CREATE,
      params
    )
  })
})

$document.on('rez/clients/new rez/clients/edit', () => {
  snilsAddMask('.client-snils')
  setSubmitClickHook(
    '#clients_submit',
    '#send_data_to_egisz',
    '#client_snils'
  )

  clientLegacyPromobotHook()
})

$document.on('rez/clients/treatment_plans', function () {
  createTreatmentPlansListClientApp(undefined, parseInt(gon.page.params.id))
})

$document.on('rez/clients/medical_policies', function () {
  createMedicalPolicesApp()
})
