import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('enums.appointment.status')

export const DOCTOR_APPOINTMENTS_STATUS_ICON_MAP = [
  { // approved
    icon: 'not-ready',
    title: ts('approved'),
    classes: 'appointment-status-icon fa-circle',
  },
  { // need_approval
    icon: 'needApproval',
    title: ts('need_approval'),
    classes: 'appointment-status-icon fa-fw fa-circle-notch',
  },
  { // billed
    icon: 'billed',
    title: ts('billed'),
    classes: 'appointment-status-icon fa-credit-card',
  }, { // canceled
    icon: 'close',
    title: ts('canceled'),
    classes: 'appointment-status-icon fa-times',
  },
  { // failed
    icon: 'failed',
    title: ts('failed'),
    classes: 'appointment-status-icon fa-user-times',
  },
  { // arrived
    icon: 'arrived',
    title: ts('arrived'),
    classes: 'appointment-status-icon fa-user',
  },
  { // serviced
    icon: 'serviced',
    title: ts('serviced'),
    classes: 'appointment-status-icon fa-clipboard-check',
  },
  { // paid
    icon: 'paid',
    title: ts('paid'),
    classes: 'appointment-status-icon fa-check-square',
  },
  { // confirmed_by_administrator
    icon: 'failedMessage',
    title: ts('confirmed_by_administrator'),
    classes: 'appointment-status-icon fa-thumbs-up',
  },
]
