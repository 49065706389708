export const doctorAppointmentsReportSortingMap = {
  dateCell: 'appointmentDate',
  timeCell: 'appointmentTime',
  statusCell: 'appointmentStatus',
  attractionSourceCell: 'attractionSource',
  clientCell: 'clientSurname',
  entryTypesCell: 'entryTypeTitle',
  administratorCell: 'administratorSurname',
  createdAtCell: 'appointmentCreatedAt',
  doctorCell: 'doctorSurname',
  noteCell: 'appointmentNote',
} as const
