<template>
  <div
    v-show="visibility"
    id="waiting-list-wrapper"
    ref="waitingListWrapper"
    class="side-menu-wrapper"
  >
    <div
      class="schedule-waiting-list-control-element"
    >
      <guarded-control
        tag="button"
        :permissions="['canManageWaitingList']"
        class="btn btn-warning btn-with-icon btn-sm"
        @click:allowed="$emit('create-waiting-item')"
      >
        <span class="btn-with-icon_icon fad fa-fw  fa-plus" />
        <span class="btn-with-icon_text">
          {{ t('add_to_list') }}
        </span>
      </guarded-control>

      <date-range-picker
        v-model="dateRange"
        :disabled="false"
        :fallback-value="[]"
        :format="GET_LOCALIZATION_DATEPICKER_FORMAT"
      />

      <el-select
        v-if="preset === availablePresets.ADMINISTRATOR_PRESET"
        ref="elSelect"
        :value="selectedUser"
        value-key="id"
        :placeholder="t('enter_doctor_surname')"
        :remote-method="findUsers"
        filterable
        remote
        reserve-keyword
        clearable
        @focus="setUsers"
        @change="setUser"
        @clear="setUser(null)"
      >
        <el-option
          v-for="user in filteredUsers"
          :key="user.id"
          :label="user.short_name"
          :value="user"
        />
      </el-select>
    </div>
    <div
      ref="list"
      class="waiting-list"
    >
      <ul v-if="waitingList.length">
        <li
          v-for="item in waitingList"
          :key="item.id"
          @click="$emit('edit-waiting-item', item)"
        >
          <div :class="[item.urgent && 'waiting-list-urgent', 'date-client-line', 'cut']">
            <span :class="dateClass(item.date)">
              {{ formatDate(item.date) }}
            </span>
            <span class="client-data">
              {{ item.client.short_name }}
            </span>
          </div>
          <div class="flex waiting-list__client_phone">
            {{ extractPhoneFromClient(item.client) }}
          </div>
          <div class="doctor-line cut">
            <span
              v-if="item.appointment_id"
              v-tooltip="t('moved_from_appointmrnt')"
              class="appointment-reference-icon fad fa-fw fa-arrow-alt-right tooltip-bottom"
            />
            <span class="prompt-notice">
              {{ item.user.short_name }}
            </span>
            <span
              v-if="item.depended_appointment"
              v-tooltip="t('have_depended_appointment')"
              :data-item-id="item.id"
              class="tooltip-bottom fad fa-fw fa-link"
              data-hintPosition="top-right"
            />
          </div>
        </li>
      </ul>
      <div v-if="showEmpty">
        {{ t('waiting_list_empty') }}
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from '@/vue_components/common/select/date_range_picker.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
import globalObjectsAccessHelpers from '../global_objects_access_helpers.js'
import { DEFAULT_CALENDAR_RANGES } from '@/vue_components/calendar_ranges.js'
import { fetchDoctorsList, fetchUsersWaitingList } from './rest.js'
import {
  ADMINISTRATOR_PRESET,
  DOCTOR_PRESET,
  ITEM_CREATED,
  ITEM_DELETED,
  ITEM_UPDATED,
  SIDEBAR_LAZY_LOAD_DEBOUNCE,
  SIDEBAR_LAZY_LOAD_THRESHOLD,
  WAITING_LIST_ITEM_HEIGHT,
} from './consts'
import GuardedControl from '@/vue_components/common/guarded_control'
import { debounce, throttle } from 'lodash/function'

export default {
  name: 'WaitingList',

  components: { GuardedControl, DateRangePicker },

  props: {
    visibility: Boolean,

    preset: {
      type: String,
      default: ADMINISTRATOR_PRESET,
    },

    waitingListCount: {
      type: Number,
      required: true,
    },
  },

  data () {
    return {
      availablePresets: {
        ADMINISTRATOR_PRESET,
        DOCTOR_PRESET,
      },
      users: [],
      waitingList: [],
      waitingListSet: new Set(), // из-за неточной синхронизации записи задваиваются
      waitingListForm: null,
      /**
       * Список пользователей, для которых загружены записи
       */
      usersWithRecords: [],
      availableUserIds: new Set(),
      /**
       * Список пользователей, которые отображаются в выпадающем списке
       */
      filteredUsers: [],
      dateRange: null,
      lastRequestTimeStamp: null,
      pickerOptions: {
        shortcuts: DEFAULT_CALENDAR_RANGES,
      },
      range: '',
      limit: 0,
      offset: 0,

      previousClinicId: this.GET_SCHEDULE_CURRENT_CLINIC_ID,
    }
  },

  computed: {
    ...mapState({
      userFilter: (state) => state.waitingList.user,
      appUser: (state) => state.appConf.current_user,
    }),

    ...mapGetters([
      'GET_LOCALIZATION_DATEPICKER_FORMAT',
      'GET_SCHEDULE_CURRENT_CLINIC_ID',
      'GET_CURRENT_USER_CLINICS_LIST',
      'GET_LOADING_RIGHT_SIDEBAR',
    ]),

    showEmpty () {
      return !(this.GET_LOADING_RIGHT_SIDEBAR || this.waitingList.length)
    },

    selectedUser () {
      return this.preset === ADMINISTRATOR_PRESET
        ? this.userFilter
        : this.appUser
    },

    dateRangeReq () {
      if (Array.isArray(this.dateRange)) {
        return `${this.dateRange[0]}_${this.dateRange[1]}`
      }

      return null
    },

    waitingListDynamicReq () {
      return {
        user_id: this.selectedUser && this.selectedUser.id,
        date_range: this.dateRangeReq,
        clinic_id: this.GET_SCHEDULE_CURRENT_CLINIC_ID,
      }
    },

    waitingListReq () {
      return {
        ...this.waitingListDynamicReq,
        limit: this.limit,
        offset: this.offset,
      }
    },

    canLoadMoreItems () {
      return !this.GET_LOADING_RIGHT_SIDEBAR && !this.allItemsLoaded
    },

    allItemsLoaded () {
      return this.waitingList.length === this.waitingListCount
    },
  },

  watch: {
    'GET_SCHEDULE_CURRENT_CLINIC_ID' (to) {
      if (this.previousClinicId === to) { return }
      this.previousClinicId = to
      this.dateRange = null
      this.$store.commit('waitingList/SET_USER', null)
    },

    waitingList () {
      if (this.$refs.waitingListWrapper) {
        Utils.updateTooltips(this.$refs.waitingListWrapper)
      }

      this.offset = this.waitingList.length
    },

    selectedUser (user) {
      if (user && !this.filteredUsers.some(({ id }) => id === user.id)) {
        this.filteredUsers.push(user)
      }
    },

    waitingListDynamicReq (newValue) {
      this.waitingListSet = new Set()
      this.offset = 0
      this.requestData(true)
      this.$emit('filters-change', newValue)
    },

    visibility () {
      if (this.visibility) {
        this.requestData()
      }
    },
  },

  mounted () {
    const { list } = this.$refs
    list.addEventListener('scroll', this.loadMoreItems)
    window.addEventListener('resize', this.loadMoreItems)

    if (this.visibility) {
      this.requestData()
    }

    Services.wsSubscriptions.waitingList.connect(
      (packet) => {
        this.$emit('count-change', this.waitingListDynamicReq)
        if (packet.batch) {
          this.waitingListBatchUpdatesHandler(packet)
        } else {
          this.waitingListUpdatesHandler(packet)
        }
      }
    )
  },

  beforeDestroy () {
    const { list } = this.$refs
    list.removeEventListener('scroll', this.loadMoreItems)
    window.removeEventListener('resize', this.loadMoreItems)
  },

  methods: {
    ...globalObjectsAccessHelpers,

    ...mapMutations({
      setUser: 'waitingList/SET_USER',
    }),

    formatDate (date) {
      return `${Utils.formatClosestDates(date, Utils.momentDateFormat).title}, `
    },

    dateClass (date) {
      return `waiting-date ${Utils.formatClosestDates(date, Utils.momentDateFormat).className}`
    },

    fetchUsers () {
      fetchDoctorsList()
        .then((response) => {
          this.users = response
        })
        .catch((error) => {
          console.error(error)
          Notificator.error(t('abstract_error_message'))
        })
    },

    waitingListBatchUpdatesHandler (batchUpdatePacket) {
      batchUpdatePacket.data.forEach((waitingListItem) => {
        this.waitingListUpdatesHandler({
          type: batchUpdatePacket.type,
          action: batchUpdatePacket.action,
          data: waitingListItem,
        })
      })
    },

    waitingListUpdatesHandler (packet) {
      const newItem = packet.data
      const clinicConform = newItem.clinic_id === null ||
        newItem.clinic_id === this.GET_SCHEDULE_CURRENT_CLINIC_ID
      const itemConform = this.itemConform(newItem)
      const shouldShowItem = clinicConform && itemConform

      switch (packet.action) {
        case ITEM_CREATED: {
          if (shouldShowItem) {
            this.addItem(newItem)
          }
          break
        }
        case ITEM_UPDATED: {
          const existingItemIndex = this.waitingList.findIndex((item) => item.id === newItem.id)
          const itemIsPresent = existingItemIndex >= 0

          if (itemIsPresent && !shouldShowItem) {
            this.removeItem(newItem.id)
          } else if (itemIsPresent && shouldShowItem) {
            this.updateItem(newItem, existingItemIndex)
          } else if (!itemIsPresent && shouldShowItem) {
            this.addItem(newItem)
          }
          break
        }
        case ITEM_DELETED: {
          if (clinicConform) {
            this.removeItem(newItem.id)
          }
        }
      }

      this.setAvailableUsers()
    },

    requestData: debounce(function (resetAll = false) {
      this.$store.commit('SET_LOADING_RIGHT_SIDEBAR', true)
      this.calculateLimit()
      if (resetAll) {
        this.offset = 0
      }

      fetchUsersWaitingList(this.waitingListReq)
        .then((waitingList) => {
          if (resetAll) {
            this.scrollUp()
            this.waitingList = []
            this.waitingListSet = new Set()
          }

          this.addItems(waitingList.filter((item) => item.client && item.user && !this.waitingListSet.has(item.id)))
          this.setAvailableUsers()
        })
        .finally(() => {
          this.$store.commit('SET_LOADING_RIGHT_SIDEBAR', false)
        })
    }, SIDEBAR_LAZY_LOAD_DEBOUNCE),

    setAvailableUsers () {
      if (this.preset !== ADMINISTRATOR_PRESET) return

      this.availableUserIds.clear()
      this.usersWithRecords = []
      this.filteredUsers = []

      this.waitingList
        .map((item) => item.user)
        .forEach((user) => {
          if (!this.availableUserIds.has(user.id)) {
            this.availableUserIds.add(user.id)
            this.filteredUsers.push(user)
            this.usersWithRecords.push(user)
          }
        })
    },

    findUsers (searchString) {
      const reg = new RegExp(searchString, 'i')
      this.filteredUsers = this.usersWithRecords.filter((user) => reg.test(user.full_name))
    },

    setUsers () {
      if (!this.filteredUsers.length) this.filteredUsers = this.usersWithRecords
    },

    addItem (newItem) {
      if (!this.waitingListSet.has(newItem)) {
        this.waitingList.push(newItem)
        this.addItemsToWaitingListSet([newItem.id])
      }

      this.sortList()
    },

    /**
     * @param {Array} items
     */
    addItemsToWaitingListSet (items) {
      items.forEach((item) => this.waitingListSet.add(item.id))
    },

    /**
     * @param {Array} items
     */
    addItems (items) {
      this.waitingList.push(...items)
      this.addItemsToWaitingListSet(items)
      this.sortList()
    },

    updateItem (newItem, index) {
      const oldItem = this.waitingList[index]
      const needSort =
        oldItem.date_in_milliseconds !== newItem.date_in_milliseconds ||
        oldItem.urgent !== newItem.urgent

      this.$set(this.waitingList, index, newItem)
      if (needSort) {
        this.sortList()
      }
    },

    removeItem (id) {
      this.waitingList = this.waitingList.filter((item) => item.id !== id)
      this.waitingListSet.delete(id)
    },

    sortList () {
      this.waitingList.sort((wli1, wli2) => {
        let compare = wli2.date_in_milliseconds - wli1.date_in_milliseconds

        if (compare === 0) {
          compare = wli2.created_at_in_milliseconds - wli1.created_at_in_milliseconds
        }

        if (compare === 0 && wli1.urgent !== wli2.urgent) {
          compare = wli1.urgent ? -1 : 1
        }

        return compare
      })
    },

    itemConform (item) {
      const userId = item.user_id
      const millisecondsTime = item.date_in_milliseconds

      return (!this.selectedUser || this.selectedUser.id === userId) &&
             (!this.dateRange ||
               (this.dateRange[0].getTime() <= millisecondsTime &&
               this.dateRange[1].getTime() >= millisecondsTime))
    },

    loadMoreItems: throttle(function (e) {
      const bottomListPosition = (e.target.scrollTop + e.target.clientHeight) / e.target.scrollHeight
      if (bottomListPosition < SIDEBAR_LAZY_LOAD_THRESHOLD) return
      if (!this.canLoadMoreItems) return

      this.requestData()
    }, SIDEBAR_LAZY_LOAD_DEBOUNCE),

    calculateLimit () {
      this.limit = Math.ceil(this.$refs.list.clientHeight / WAITING_LIST_ITEM_HEIGHT) * 2
    },

    scrollUp () {
      this.$refs.list.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },

    extractPhoneFromClient (client) {
      return client.formatted_phone || client.masked_phone || `+${Services.phoneMask.setMask(client.phone)}`
    },
  },
}
</script>
