<template>
  <entries
    ref="report"
    :is-hidden="isHidden"
    v-bind="filters"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getFilters } from '@/specific/reports/entries'
import Entries from '@/vue_components/reports/EntriesReport/Entries.vue'
import ReportWrapper from '@/vue_components/mixins/reportWrapper'

export default defineComponent({
  name: 'EntriesReportApp',

  components: { Entries },

  mixins: [ReportWrapper],

  data () {
    return {
      filters: getFilters(),
    }
  },
})
</script>
