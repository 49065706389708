<template>
  <div class="col-container">
    <div
      :class="activeTab !== API_CLIENTS_LIST ? 'margin-right-5px' : ''"
      class="col-item-40"
    >
      <div
        class="panel panel-primary"
      >
        <epic-spinner
          :visibility="activeRequestState"
        />
        <div
          class="panel-heading panel-heading-flex"
        >
          <div
            class="panel-heading-title"
          >
            <span
              class="panel-heading-icon fad fa-fw fa-cloud"
            />
            <h3
              class="panel-title"
            >
              {{ T.api_page.clients.api_clients }}
            </h3>
          </div>
        </div>
        <div
          class="panel-top-btn-container"
        >
          <div
            class="flex-item"
          >
            <button
              class="btn btn-with-icon btn-success btn-sm hidden-print clients-options-btn"
              :title="t('api_page.clients.add_api_client')"
              @click="activeTab = NEW_API_CLIENT, newApiClient()"
            >
              <span class="btn-with-icon_icon fad fa-fw fa-cloud" />
              <span class="fas fa-fw fa-plus" />
            </button>
          </div>
        </div>
        <div
          class="panel-body panel-list-body"
        >
          <table
            class="table table-striped table-hover"
          >
            <tbody>
              <tr
                v-for="apiClient in apiClients"
                :key="apiClient.id"
                :class="apiClient.id === activeTr[0] && 'active'"
                @click="editApiClient(apiClient)"
              >
                <td class="options p-2">
                  <i class="fad fa-fw fa-cloud" />
                </td>
                <td
                  class="name cut"
                  data-name="title"
                >
                  {{ apiClient.title }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination
          v-if="apiClientsCount > PAGINATION_LIMIT"
          :current-page.sync="currentPage"
          :page-count="pageCount"
          :margin-top="10"
          :margin-bottom="10"
          :margin-left="15"
          :margin-right="15"
          layout="prev, pager, next"
          @current-change="loadApiClients"
        />
      </div>
    </div>
    <div
      v-show="activeTab !== API_CLIENTS_LIST"
      class="col-item-60"
    >
      <api-client-new
        ref="createForm"
        :show="activeTab === NEW_API_CLIENT"
        @created="apiClientCreated"
        @close="activeTab = API_CLIENTS_LIST"
      />
      <api-client-edit
        ref="editForm"
        :show="activeTab === EDIT_API_CLIENT"
        @updated="loadApiClients"
        @close="activeTab = API_CLIENTS_LIST"
      />
    </div>
  </div>
</template>

<script>
import ApiClientNew from '@/vue_components/api/client_new.vue'
import ApiClientEdit from '@/vue_components/api/client_edit.vue'
import Pagination from '@/vue_components/common/pagination.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'

const PAGINATION_LIMIT = 30
const API_CLIENTS_LIST = 2
const NEW_API_CLIENT = 4
const EDIT_API_CLIENT = 8

export default {
  name: 'ApiClient',

  components: {
    ApiClientNew,
    ApiClientEdit,
    Pagination,
    EpicSpinner,
  },

  data () {
    return {
      PAGINATION_LIMIT,
      API_CLIENTS_LIST,
      NEW_API_CLIENT,
      EDIT_API_CLIENT,

      activeTab: API_CLIENTS_LIST,
      currentPage: 1,
      pageCount: 0,
      activeRequestState: false,
      apiClients: [],
      apiRoles: [],
      apiClientsCount: 0,
      activeTr: [],
    }
  },

  watch: {
    apiClientsCount () {
      this.pageCount = Math.ceil(this.apiClientsCount / PAGINATION_LIMIT)
    },
  },

  created () {
    this.loadApiClientsCount()
    this.loadApiClients()
    this.loadApiRoles()
  },

  methods: {
    loadApiRoles () {
      const god = this

      god.activeRequestState = true

      $.ajax({
        type: 'GET',
        url: Routes.list_api_roles_path(),
        success (apiRoles) {
          god.apiRoles = apiRoles
        },
        complete () {
          god.activeRequestState = false
        },
      })
    },

    loadApiClients () {
      const god = this

      god.activeRequestState = true

      $.ajax({
        type: 'GET',
        url: Routes.list_api_clients_path(),
        data: {
          offset: (god.currentPage - 1) * PAGINATION_LIMIT,
          limit: PAGINATION_LIMIT,
        },
        success (apiClients) {
          god.apiClients = apiClients
        },
        complete () {
          god.activeRequestState = false
        },
      })
    },

    loadApiClientsCount () {
      const god = this

      god.activeRequestState = true

      $.ajax({
        type: 'GET',
        url: Routes.count_api_clients_path(),
        success (apiClientsCount) {
          god.apiClientsCount = apiClientsCount
        },
        complete () {
          god.activeRequestState = false
        },
      })
    },

    apiClientCreated (apiClient) {
      this.loadApiClientsCount()
      this.loadApiClients()
    },

    newApiClient () {
      this.$refs.createForm.new(this.apiRoles)
      if (this.activeTr.length > 0) {
        this.activeTr.pop()
      }
    },

    editApiClient (apiClient) {
      this.$refs.editForm.edit(apiClient, this.apiRoles)

      if (this.activeTr.length > 0) {
        this.activeTr.pop()
      }
      this.activeTr.push(apiClient.id)

      this.activeTab = EDIT_API_CLIENT
    },
  },
}
</script>
