<template>
  <procedures-report
    ref="report"
    :is-hidden="isHidden"
    :doctor-area-module-enabled="doctorAreaModuleEnabled"
    :workplaces-enabled="workplacesEnabled"
    :money-symbol="moneySymbol"
    v-bind="filters"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ReportWrapper from '@/vue_components/mixins/reportWrapper'
import ProceduresReport from '@/vue_components/reports/ProceduresReport/ProceduresReport.vue'
import { proceduresReportFiltersAccessors } from '@/specific/reports/procedures/filtersAccessors'

export default defineComponent({
  name: 'ProceduresReportApp',

  components: { ProceduresReport },

  mixins: [ReportWrapper],

  data () {
    return {
      filters: proceduresReportFiltersAccessors.get(),
      doctorAreaModuleEnabled: gon.application.doctor_area_module_enabled,
      workplacesEnabled: gon.application.workplaces_module_enabled,
      moneySymbol: gon.localization.currency.symbol_character,
    }
  },
})
</script>
