<template>
  <div
    :id="elementId"
    class="responsible-app"
  >
    <input
      type="hidden"
      name="treatment_plan[responsible_id]"
      :value="inputHiddenValue"
    >
    <reusable-doctors-list
      v-model="responsible"
      class="treatment-plan__responsible"
      :current-clinic="clinicIds"
      label=""
      @onDoctorChange="responsible = $event"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'

export default defineComponent({
  name: 'ResponsibleApp',

  components: { ReusableDoctorsList },

  props: {
    elementId: { type: String, required: true },
  },

  data () {
    return {
      responsible: gon.specific.treatmentPlanResponsibleUser || null,
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      clinicIds: 'vxGetDoctorClinicsIds',
    }),

    inputHiddenValue () {
      return this.responsible?.id || ''
    },
  },
})
</script>
