<template>
  <si v-if="!isHidden">
    <div class="report-subtitle cut">
      {{ subtitle }}
    </div>

    <si-table
      :spinner="isLoading"
      class="dashed print-flex"
      horizontal
    >
      <template #header>
        <payments-report-table-header
          :header="header"
          :footnotes="footnotes"
          :sortable-column="sortableColumn"
          @onSortChange="onSortChange"
        />
      </template>

      <template
        v-if="rows.length"
        #body
      >
        <payments-report-table-row
          v-for="(row, rowIndex) in rows"
          :key="rowIndex"
          :row="row"
        />
      </template>

      <template #footer>
        <payments-report-table-footer
          v-if="rows.length && isDirty"
          class="total"
          :footer="footer"
          :footnotes="footnotes"
        />
      </template>
    </si-table>

    <si-footer v-if="rows.length">
      <pagination
        :page-count="pageCount"
        layout="prev, pager, next"
        class="hidden-print"
        @current-change="loadData"
      />
    </si-footer>
  </si>
</template>

<script>
import Pagination from '@/vue_components/common/pagination.vue'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import Report from '@/vue_components/mixins/report.js'
import SortableReport from '@/vue_components/mixins/sortableReport.js'
import ReportWithTooltips from '@/vue_components/mixins/withTooltips.js'
import Si from '@/vue_components/sort_items/si.vue'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'
import { footnotes, header } from '@/vue_components/reports/PaymentsReport/const'
import PaymentsReportTableHeader from '@/vue_components/reports/PaymentsReport/components/PaymentsReportTableHeader.vue'
import PaymentsReportTableRow from '@/vue_components/reports/PaymentsReport/components/PaymentsReportTableRow.vue'
import PaymentsReportTableFooter from '@/vue_components/reports/PaymentsReport/components/PaymentsReportTableFooter.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'PaymentsReport',

  components: {
    PaymentsReportTableFooter,
    PaymentsReportTableRow,
    PaymentsReportTableHeader,
    Pagination,
    Si,
    SiTable,
    SiFooter,
  },

  mixins: [
    Report,
    SortableReport,
    ReportWithTooltips,
  ],

  props: {
    clinic: { type: Object, required: true },
    period: { type: String, default: '' },
    paymentKinds: { type: Array, required: true },
    paymentType: { type: Object, required: true },
    payerType: { type: Object, required: true },
    doctorId: { type: Object, required: true },
    chequePrinted: PropsTypes.Object(undefined, true),
  },

  data () {
    return {
      header,
      footnotes,
    }
  },

  computed: {
    subtitleParts () {
      return [
        this.period,
        this.clinic.name,
        this.paymentType.name,
        this.paymentKinds.map((kind) => kind.name).join(', '),
        this.chequePrinted.name,
      ]
    },
  },

  created () {
    this.reportClient.init({
      url: Routes.report_payments_path(),
    })
    this.requestBuilder.addStep(() => ReportFiltersFactory.defineFor('payments', this))
  },

  methods: {
    fillTable (result) {
      this.rows = result.data.map(({ payment }) => {
        if (payment.destinationOrder) {
          payment.orderLink = Routes.order_path(payment.destinationOrder.id)
        }

        if (payment.payerClient) {
          payment.payerLink = Routes.client_path(payment.payerClient.id)
        } else if (payment.payerCompany) {
          payment.payerLink = Routes.company_path(payment.payerCompany.id)
        }

        return payment
      })

      this.footer = {
        total: T.total,
        ...result.total,
      }
    },

    afterReportUpdated () {
      this.updateTooltips()
    },
  },
}
</script>
