<template>
  <div class="flex">
    <validation-wrapper
      :errors="errorMessages"
    >
      <payer-finder
        v-model="payerSelect"
        v-click-outside="clearSearchSelect"
        :search-query.sync="searchQuery"
        :payer-type="payerType"
        :clients-payers="payers.clients"
        :companies-payers="payers.companies"
        :loading="loading"
        :allow-search="allowSearch"
        :not-results="notResults"
        :disabled="disabled.payerFinderSelector"
        :payer-is-deleted="payerIsDeleted"
        @change="$pubSub.emit('ORDERS.INSURANCE_COMPANY_CLEAR_MEDICAL_POLICY')"
      />
      <input
        class="ignore-showing-errors"
        type="hidden"
        name="order[customer_client_id]"
        :value="payerClient ? payer.id : ''"
      >
      <input
        class="ignore-showing-errors"
        type="hidden"
        name="order[customer_company_id]"
        :value="payerCompany ? payer.id : ''"
      >
    </validation-wrapper>
    <add-btn
      :title="T.create_customer_individual"
      :disabled="disabled.payerFinderSelector"
      class="btn-create-customer"
      @click="openModalCreateCustomerIndividual"
    >
      <span class="fad fa-user-friends" />
    </add-btn>
    <add-btn
      :title="T.create_customer_legal_entity"
      :disabled="disabled.payerFinderSelector"
      class="btn-create-customer"
      @click="openModalCreateCustomerLegalEntity"
    >
      <span class="fad fa-building" />
    </add-btn>
    <confirmation-modal
      :modal-visibility="confirmModal.visibility"
      :modal-class="confirmModal.modalClass"
      :header-message="confirmModal.headerMessage"
      :message="confirmModal.message"
      @yes="confirmModal.visibility = false; submitOrderForm()"
      @no="confirmModal.visibility = false; unblockSaveButton()"
    />
  </div>
</template>

<script>
import AddBtn from '@/vue_components/common/buttons/add_btn.vue'
import PayerFinder from '@/vue_components/common/payer_finder/payer_finder.vue'
import ConfirmationModal from '@/vue_components/confirmation_modal.vue'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'

import { findPayers } from './rest.js'

import { PAYER_TYPE, SEPARATOR } from './consts.js'

export default {
  name: 'OrderLeftInfo',

  components: {
    AddBtn,
    PayerFinder,
    ConfirmationModal,
    ValidationWrapper,
  },

  data () {
    return {
      payer: {},
      payerType: '',
      searchQuery: '',
      payers: {
        clients: [],
        companies: [],
      },
      client: {},
      action: null,
      loading: false,
      permitSubmitForm: false,
      clientMedicalPolicies: gon.specific.client_medical_policies,
      orderForm: $('#order_form'),
      errorMessages: [],
      defaultList: [],

      confirmModal: {
        visibility: false,
        headerMessage: t('warning'),
        message: t('have_insurance_policy'),
        modalClass: 'confirm-order-form-submiting',
      },
      disabled: {
        payerFinderSelector: !(
          gon.specific.order.paid_status === 'not_paid' ||
        gon.specific.order.paid_status === 'free'
        ),
      },
      SEPARATOR,
      PAYER_TYPE,
    }
  },

  computed: {
    payerClient () {
      return this.payerType === PAYER_TYPE.CLIENT
    },

    payerCompany () {
      return this.payerType === PAYER_TYPE.COMPANY
    },

    payerIsClientInsuranceCompany () {
      if (this.payerClient) return false

      return this.clientMedicalPolicies
        .some((policy) => policy.insuranceCompany?.id === this.payer.id)
    },

    payerIsDeleted () {
      return !!this.payer.deleted_at
    },

    allowSearch () {
      return this.searchQuery.length >= 3
    },

    notResults () {
      return this.allowSearch && this.payers.clients.length < 1 && this.payers.companies.length < 1
    },

    payerSelect: {
      set (value) {
        const [id, type] = value.split(this.SEPARATOR)
        const payersArr = type === PAYER_TYPE.COMPANY ? 'companies' : 'clients'
        this.payer = this.payers[payersArr].find((p) => p.id === parseInt(id))
        this.payerType = type
        this.clearSearchSelect()
      },

      get () {
        if (!this.payer) {
          return ''
        }

        if (this.payerIsClient(this.payer)) {
          return `${this.payer.surname} ${this.payer.name} ${this.payer.second_name}`
        }

        if (this.payerIsCompany(this.payer)) {
          return this.payer.title
        }

        return ''
      },
    },
  },

  watch: {
    client: {
      deep: true,
      handler (updateClient, prevClient) {
        this.payers.clients = [updateClient, ...updateClient.legal_representatives]

        if (
          prevClient.legal_representatives && prevClient.legal_representatives &&
          this.payerInclude(prevClient.legal_representatives, this.payer) &&
          !this.payerInclude(prevClient.legal_representatives, this.payer)
        ) {
          this.payer = updateClient
        }

        if (updateClient.id === this.payer.id) {
          this.payer = updateClient
        }
      },
    },

    payer (newPayer) {
      if (!newPayer) return
      this.addPayer(newPayer)

      const payer = { ...newPayer }

      if (payer.company_discount) {
        payer.discount = payer.company_discount
      }

      payer.is_company = this.payerIsCompany(payer)

      this.payerSelect = this.getComplexId(payer)
      this.$pubSub.emit('ORDER.PAYER_SELECTOR.PAYER_CHANGED', payer)

      this.errorMessages = []
    },

    searchQuery (newSearchQuery) {
      if (newSearchQuery === '') {
        this.clearSearchSelect()
      }
      if (!this.allowSearch) return

      this.loading = true

      findPayers(newSearchQuery)
        .then((rawPayers) => {
          this.payers.clients = rawPayers.clients
          this.payers.companies = rawPayers.companies
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false
        })
    },
  },

  mounted () {
    const vm = this
    vm.client = gon.specific.client

    if (gon.page.action === 'new') {
      const payer = gon.specific.payer_company ||
          gon.specific.payer_client ||
          this.client

      if (payer?.legal_representatives?.length === 1) {
        vm.payer = payer.legal_representatives[0]
      } else {
        vm.payer = payer
      }
    }

    if (gon.page.action === 'edit') {
      vm.payer = gon.specific.payer_company || gon.specific.payer_client
    }

    vm.$pubSub.subscribe('ORDER.PAYER_SELECTOR.UPDATE_CLIENT_INFO', (client) => {
      vm.client = client
    })

    vm.$pubSub.subscribe('ORDERS.INSURANCE_COMPANY_SELECTED_MEDICAL_POLICY',
      (insuranceCompany) => {
        if (this.disabled.payerFinderSelector) return
        if (insuranceCompany) {
          vm.payer = insuranceCompany
        }
      })

    vm.$pubSub.subscribe('ORDER.PAYER_SELECTOR.SET_PAYER', (payer) => {
      vm.payer = payer
    })

    vm.orderForm.submit(function (event) {
      if (vm.permitSubmitForm) return

      $(this).find('.actions input:submit').loadSpin('stop')

      vm.getPermissionToSubmitForm()

      event.preventDefault()
      event.stopImmediatePropagation()

      return false
    })

    vm.orderForm.on('ajax:error', (event, response) => {
      if (response.status !== 422) return

      try { vm.errorMessages = response.responseJSON.customer || [] } catch (err) {
        console.warn(err)
      }
    })

    vm.$pubSub.subscribe('MEDICAL_POLICES.SYNC_ITEMS', (items) => {
      vm.clientMedicalPolicies = items
    })
  },

  methods: {
    payerIsClient (payer) {
      return payer.title === undefined
    },

    payerIsCompany (payer) {
      return payer.surname === undefined
    },

    payerIsCompanyInsurance (payer) {
      return this.clientMedicalPolicies
        .some((policy) => policy.insuranceCompany?.id === payer.id)
    },

    addPayer (payer) {
      if (!payer) return

      if (this.payerIsClient(payer)) {
        if (this.payerInclude(this.payers.clients, payer.id)) return
        this.payers.clients = [...this.payers.clients, payer]
      }

      if (this.payerIsCompany(payer)) {
        if (this.payerInclude(this.payers.companies, payer.id)) return
        this.payers.companies = [...this.payers.companies, payer]
      }
    },

    getComplexId (payer) {
      if (!payer) return ''
      if (this.payerIsClient(payer)) return payer.id + this.SEPARATOR + this.PAYER_TYPE.CLIENT
      if (this.payerIsCompany(payer)) return payer.id + this.SEPARATOR + this.PAYER_TYPE.COMPANY
    },

    clearSearchSelect () {
      this.searchQuery = ''
      const clientPayers = [this.client, ...this.client.legal_representatives]
      const companyPayers = []

      if (this.payerIsClient(this.payer)) {
        if (!this.payerInclude(clientPayers, this.payer)) {
          clientPayers.push(this.payer)
        }
      }
      if (this.payerIsCompany(this.payer)) {
        companyPayers.push(this.payer)
      }
      this.payers.clients = clientPayers
      this.payers.companies = companyPayers
    },

    payerInclude (arr, item) {
      return arr.some((c) => c.id === item.id)
    },

    getPermissionToSubmitForm () {
      if (this.clientMedicalPolicies.length) {
        if (this.payerClient) {
          this.confirmModal.visibility = true
        }

        if (this.payerCompany) {
          this.confirmModal.visibility = !this.payerIsClientInsuranceCompany
        }
      }

      if (this.confirmModal.visibility) return

      this.submitOrderForm()
    },

    submitOrderForm () {
      this.permitSubmitForm = true
      this.orderForm.submit()
    },

    unblockSaveButton () {
      const submitButton = this.orderForm.find('button[type="submit"]')
      if (!submitButton) { return }
      submitButton.loadSpin('stop')
    },

    openModalCreateCustomerIndividual () {
      this.$pubSub.emitAsync('ORDER.OPEN_MODAL_CREATE_CUSTOMER_INDIVIDUAL')
    },

    openModalCreateCustomerLegalEntity () {
      this.$pubSub.emitAsync('ORDER.OPEN_MODAL_CREATE_CUSTOMER_LEGAL_ENTITY')
    },
  },
}
</script>
