<!--TODO: удалить когда будет реализовываться https://app.clickup.com/t/djz9et 2 /4 -->
<template>
  <el-tabs
    class="discount-tabs"
    :tab-position="'left'"
    :type="'border-card'"
  >
    <slot name="discount-editor">
      <el-tab-pane>
        <i
          slot="label"
          class="fad fa-fw fa-percentage"
          data-toggle="tooltip"
          :title="t('discount_settings')"
        />
        <discount-form
          ref="df"
          v-bind.sync="discountCatalogProps"
          @updateDiscountValues="updateDiscountValues"
          @checkDiscountValue="checkDiscountValue"
          @setDiscountReasonId="setDiscountReasonId"
        >
          <template #catalog>
            <span />
          </template>
        </discount-form>
      </el-tab-pane>
    </slot>

    <slot name="discount-history">
      <el-tab-pane>
        <i
          slot="label"
          class="fad fa-fw fa-history"
          data-toggle="tooltip"
          :title="t('discount_history')"
        />
        <discount-history
          v-bind.sync="discountHistoryProps"
        />
      </el-tab-pane>
    </slot>
  </el-tabs>
</template>

<script>
import DiscountForm from './discount_form.vue'
import { DISCOUNT_TYPES, FAKE_NODE_ID1, MANUAL_DISCOUNT_ID } from './const'
import { getHistoryArray } from './rest'
import DiscountHistory from './discount_history.vue'
import {
  getDiscountReasonsSystemNameByReasonId,
  getMaxPayerDiscount,
  getSystemDiscountByReasonId,
} from './system_discounts'

export default {
  name: 'DiscountMaster',

  components: {
    DiscountHistory,
    DiscountForm,
  },

  props: {
    initialRoundedPrices: Boolean,
  },

  data () {
    return {
      discountCatalogProps: {
        discountArray: [],
        /* поля для el-tree */
        defaultFields: {
          id: 'id',
          label: 'title',
        },
        discountTypeArray: [
          { name: DISCOUNT_TYPES.PERCENT, label: '%' },
          { name: DISCOUNT_TYPES.CURRENCY, label: Utils.currencySign() },
        ],
        discountReasonId: 0,

        discountType: DISCOUNT_TYPES.PERCENT,
        discountValue: 0,

        isRoundOrderPrices: this.initialRoundedPrices,
        maxDiscount: 0,
        orderDiscountableSum: 0,
        entrySum: 0,
        entryTitle: '',
        entryElement: null,
        isMassDiscount: false,
      },

      discountHistoryProps: {
        historyArray: [],
        discountTypeObj: {
          [DISCOUNT_TYPES.PERCENT]: '%',
          [DISCOUNT_TYPES.CURRENCY]: Utils.currencySign(),
        },
        entryId: null,
        entryTitle: '',
        isAllEntries: true,
      },

      discountReasonIdManual: 0,
      prevMassDiscount: null,
      orderRoundingPrices: $('[name="order[rounded_prices]"]'),
    }
  },

  created () {
    this.getRoutesData()
      .then(() => {
        this.$emit('ready')
      })
  },

  methods: {
    getRoutesData (name = '') {
      this.discountReasonIdManual = MANUAL_DISCOUNT_ID
      this.discountCatalogProps.discountArray = []

      return getHistoryArray(gon.specific.order_id)
        .then((data) => {
          this.discountHistoryProps.historyArray = data
        })
        .catch((reason) => {
          console.log('Error loading the discounts history: ', reason)
        })
    },

    setMaxDiscount () {
      this.discountCatalogProps.maxDiscount = 100 /* скидка 100% по умолчанию */

      if (this.discountCatalogProps.discountType === DISCOUNT_TYPES.CURRENCY) {
        this.discountCatalogProps.maxDiscount = this.discountCatalogProps.orderDiscountableSum

        if (this.discountCatalogProps.entryTitle) {
          this.discountCatalogProps.maxDiscount = this.discountCatalogProps.entrySum
        }
      }
    },

    /**
     * Пересчёт значения скидки
     * Пересчет максимального значения скидки
     */
    updateDiscountValues () {
      this.setMaxDiscount()
      this.checkDiscountValue()
    },

    /**
     * Присвоение discountValue крайних значений границ диапазона
     * при условии выхода discountValue за его границы
     */
    checkDiscountValue () {
      let discountValueFloat = parseFloat(this.discountCatalogProps.discountValue)

      if (
        this.discountCatalogProps.discountType === DISCOUNT_TYPES.CURRENCY &&
        this.discountCatalogProps.isRoundOrderPrices
      ) {
        discountValueFloat = Math.round(discountValueFloat)
        this.discountCatalogProps.discountValue = discountValueFloat
      }

      if (discountValueFloat > this.discountCatalogProps.maxDiscount) {
        this.discountCatalogProps.discountValue = this.discountCatalogProps.maxDiscount
      }

      if (discountValueFloat < 0) {
        this.discountCatalogProps.discountValue = 0
      }
    },

    setDiscountReasonId (reasonId) {
      this.discountCatalogProps.discountReasonId = reasonId
      this.setSystemDiscount(reasonId)
    },

    /**
     * При выборе системной скидки автоматически подставляются значения, тип и макс скидка
     * Если не системная, то значение макс скидки пересчитывается
     */
    setSystemDiscount (reasonId) {
      const maxSum = this.discountCatalogProps.entryElement
        ? this.discountCatalogProps.entrySum
        : this.discountCatalogProps.orderDiscountableSum

      const systemDiscount = getSystemDiscountByReasonId(reasonId, maxSum)
      if (!systemDiscount) {
        this.setMaxDiscount()

        return
      }

      this.discountCatalogProps.maxDiscount = systemDiscount.maxValue
      this.discountCatalogProps.discountType = systemDiscount.type
      this.discountCatalogProps.discountValue = systemDiscount.value
    },

    onClose () {
      this.discountCatalogProps.discountReasonId = null
      this.discountCatalogProps.entryTitle = ''
      this.discountCatalogProps.entryElement = null

      this.$refs.df.resetCheckedKeys() /* костыль? для очистки дерева discount_reasons */
    },

    setField (fieldName, value) {
      if (!fieldName) return

      this.discountCatalogProps[fieldName] = value
    },

    /**
     * Установка значений, прилетают из счёта.
     * При установке массовой скидки (isMassDiscount: true) нужно всегда
     * передавать скидки заказчика (payer). Дефолтного заказчика нет,
     * так как если брать его из `gon`, то он будет неактуальным,
     * когда выберется другой заказчик, и скидки будут неправильными.
     **/
    setData (payload = {
      discountValue: 0,
      discountType: DISCOUNT_TYPES.PERCENT,
      discountReasonId: 0,
      orderDiscountableSum: 0,
      isRoundOrderPrices: false,
      isMassDiscount: false,
      entryTitle: '',
      entryId: 0,
      entryElement: null,
      entrySum: 0,
      payer: null,
    }) {
      this.setModalTitle(payload)
      this.setDiscountCatalogProps(payload)
      this.setInitialDiscount(payload)
      this.setMaxDiscount(payload)

      this.setHistoryProps(payload)
    },

    setModalTitle (payload) {
      let title = t('set_discount_for_all_positions')
      if (payload.entryElement) {
        title = t('set_discount_for_one_position') + ': ' + (payload.entryTitle || '')
      }

      $('#discount-select-modal').find('.modal-title__text').text(title)
    },

    setDiscountCatalogProps (payload) {
      this.discountCatalogProps.discountValue = +payload.discountValue
      this.discountCatalogProps.discountType = payload.discountType
      this.discountCatalogProps.isMassDiscount = payload.isMassDiscount

      this.discountCatalogProps.discountReasonId = this.discountReasonIdManual
      this.setDiscountRounding(payload.isRoundOrderPrices)
      this.discountCatalogProps.entryTitle = payload.entryTitle
      this.discountCatalogProps.entryElement = payload.entryElement
      this.discountCatalogProps.orderDiscountableSum = +payload.orderDiscountableSum
      this.discountCatalogProps.entrySum = +payload.entrySum
    },

    setInitialDiscount (payload) {
      if (payload.isMassDiscount) {
        this.setMassDiscount(payload)
      }
    },

    setMassDiscount (payload) {
      const prevMassDiscount = this.restoreMassDiscount()

      if (prevMassDiscount) {
        this.setField('discountValue', prevMassDiscount.discountValue)
        this.setField('discountType', prevMassDiscount.discountType)
        this.setField('discountReasonId', prevMassDiscount.discountReasonId)
        this.setField('isRoundOrderPrices', prevMassDiscount.isRoundOrderPrices)
      } else {
        this.setSystemDiscounts(payload)
      }
    },

    setSystemDiscounts (payload) {
      if (payload.entryElement) return

      const { payer } = payload

      if (!payer) {
        console.debug('[DiscountMaster]: setSystemDiscounts', payload)
        throw new Error('Should pass a payer in payload when setting mass discount')
      }

      const maxDiscount = getMaxPayerDiscount(payer)
      if (maxDiscount.value === 0) return

      this.discountCatalogProps.discountType = DISCOUNT_TYPES.PERCENT
      this.discountCatalogProps.discountValue = maxDiscount.value
      this.discountCatalogProps.discountReasonId = maxDiscount.reasonId
    },

    setHistoryProps (payload) {
      this.discountHistoryProps.entryId = +payload.entryId || 0
      this.discountHistoryProps.isAllEntries = !payload.entryElement
      this.discountHistoryProps.entryTitle = payload.entryTitle
    },

    getData () {
      let discountReasonId = this.discountCatalogProps.discountReasonId
      if (discountReasonId >= FAKE_NODE_ID1) {
        discountReasonId = null
      }

      return {
        discountValue: this.discountCatalogProps.discountValue,
        discountType: this.discountCatalogProps.discountType,
        discountReasonId,
        discountReasonName: getDiscountReasonsSystemNameByReasonId(discountReasonId),
        isRoundOrderPrices: this.discountCatalogProps.isRoundOrderPrices,
        entryTitle: this.discountCatalogProps.entryTitle,
        entryElement: this.discountCatalogProps.entryElement,
      }
    },

    saveMassDiscount () {
      this.prevMassDiscount = this.getData()
    },

    restoreMassDiscount () {
      return this.prevMassDiscount
    },

    setDiscountRounding (isRoundOrderPrices) {
      if (isRoundOrderPrices !== undefined) {
        this.discountCatalogProps.isRoundOrderPrices = isRoundOrderPrices
      }
    },
  },
}
</script>
