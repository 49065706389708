<template>
  <si v-if="!isHidden">
    <div class="report-subtitle cut">
      {{ subtitle }}
    </div>
    <si-table :spinner="isLoading">
      <template #header>
        <component
          :is="tableHeaderComponent"
          :header="header"
          :sortable-column="sortableColumn"
          @sort-change="onSortChange"
        />
      </template>
      <template
        v-if="rows.length"
        #body
      >
        <component
          :is="tableRowComponent"
          v-for="(row, rowIndex) in rows"
          :key="rowIndex"
          :row="row"
        />
      </template>
      <template #footer>
        <component
          :is="tableFooterComponent"
          :footer="footer"
          class="total"
        />
      </template>
    </si-table>
    <si-footer v-if="rows.length">
      <pagination
        :page-count="pageCount"
        layout="prev, pager, next"
        class="hidden-print"
        @current-change="loadData"
      />
    </si-footer>
  </si>
</template>

<script>
import Pagination from '@/vue_components/common/pagination.vue'
import Si from '@/vue_components/sort_items/si.vue'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import Report from '@/vue_components/mixins/report.js'
import SortableReport from '@/vue_components/mixins/sortableReport.js'
import ReportWithTooltips from '@/vue_components/mixins/withTooltips.js'
import ExpensesRowDefault from './ExpensesRowDefault.vue'
import ExpensesRowGrouped from './ExpensesRowGrouped.vue'
import ExpensesHeaderDefault from './ExpensesHeaderDefault.vue'
import ExpensesHeaderGrouped from './ExpensesHeaderGrouped.vue'
import ExpensesFooterDefault from './ExpensesFooterDefault.vue'
import ExpensesFooterGrouped from './ExpensesFooterGrouped.vue'
import {
  EXPENSES_NO_GROUPING,
  getHeader,
  GROUP_EXPENSES_BY_EXPENSE_GROUP,
  GROUP_EXPENSES_BY_TITLE,
} from '@/specific/reports/expenses/common'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'

const aliases = new Map([
  ['expenseId', 'number'],
  ['expenseDate', 'date'],
  ['expenseTitle', 'title'],
  ['expensePrice', 'sum'],
  ['expenseAdditional', 'additional'],
  ['expenseGroupTitle', 'expenseGroup'],
  ['expenseAmount', 'amount'],
])

export default {
  name: 'Expenses',

  components: {
    Pagination,
    Si,
    SiTable,
    SiField,
    SiFooter,
    ExpensesRowDefault,
    ExpensesRowGrouped,
    ExpensesHeaderDefault,
    ExpensesHeaderGrouped,
    ExpensesFooterDefault,
    ExpensesFooterGrouped,
  },

  mixins: [Report, SortableReport, ReportWithTooltips],

  props: {
    clinic: { type: Object, required: true },
    period: { type: String, default: '' },
    expenseCategories: { type: Object, required: true },
    expenseConsumable: { type: Object, required: true },
    expenseSource: { type: Object, required: true },
    expensesGroupType: { type: Object, required: true },
  },

  data () {
    return {
      reportType: EXPENSES_NO_GROUPING,
      header: this.mapHeader(EXPENSES_NO_GROUPING),
    }
  },

  computed: {
    tableFooterComponent () {
      // check for current report layout, not from props
      switch (this.reportType) {
        case GROUP_EXPENSES_BY_TITLE:
        case GROUP_EXPENSES_BY_EXPENSE_GROUP: {
          return 'ExpensesFooterGrouped'
        }
        default: {
          return 'ExpensesFooterDefault'
        }
      }
    },
    tableHeaderComponent () {
      switch (this.reportType) {
        case GROUP_EXPENSES_BY_TITLE:
        case GROUP_EXPENSES_BY_EXPENSE_GROUP: {
          return 'ExpensesHeaderGrouped'
        }
        default: {
          return 'ExpensesHeaderDefault'
        }
      }
    },
    tableRowComponent () {
      switch (this.reportType) {
        case GROUP_EXPENSES_BY_TITLE:
        case GROUP_EXPENSES_BY_EXPENSE_GROUP: {
          return 'ExpensesRowGrouped'
        }
        default: {
          return 'ExpensesRowDefault'
        }
      }
    },
    subtitleParts () {
      return [
        this.period,
        this.clinic.name,
        this.expenseCategories.name,
        this.expenseConsumable.name,
        this.expenseSource.name,
        this.expensesGroupType.name,
      ]
    },
  },

  created () {
    this.reportClient.init({
      url: Routes.report_expenses_path(),
    })
    this.requestBuilder.addStep(() => ReportFiltersFactory.defineFor('expenses', this))
  },

  methods: {
    fillTable (result) {
      // set report type here to avoid showing old table data in new layout
      this.header = this.mapHeader(this.expensesGroupType.id)
      this.reportType = this.expensesGroupType.id

      this.rows = result.data.map(({ expense, expenseGroup }) => {
        return this.reportType === GROUP_EXPENSES_BY_EXPENSE_GROUP
          ? expenseGroup
          : expense
      })

      this.footer = result.total
    },

    mapHeader (type) {
      const header = getHeader(type)

      return Object
        .keys(header)
        .reduce((acc, key) => {
          acc[aliases.get(key)] = {
            text: header[key],
            sortable: key,
          }

          return acc
        }, {})
    },

    afterReportUpdated () {
      this.updateTooltips()
    },
  },
}
</script>
