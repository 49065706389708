<template>
  <div class="schedule-template-body-left">
    <div class="schedule-template-form-item">
      <div class="schedule-template-form-left">
        <label>
          <span>{{ T.doctor }}</span>
        </label>
      </div>
      <div class="schedule-template-form-right">
        <el-select
          v-model="selectedUser"
          :placeholder="T.enter_doctor_surname"
          :remote-method="_findUsers"
          filterable
          remote
          reserve-keyword
          clearable
          @change="_triggerChanges"
          @focus="_setUsers"
        >
          <el-option
            v-for="user in filteredUsers"
            :key="user.id"
            :label="user.short_name"
            :value="user.id"
          />
        </el-select>
      </div>
    </div>
    <div class="schedule-template-form-item">
      <div class="schedule-template-form-left">
        <label>
          <span>{{ T.cabinet }}</span>
        </label>
      </div>
      <div class="schedule-template-form-right">
        <el-select
          v-model="selectedCabinet"
          :placeholder="T.all_cabinets"
          clearable
          @change="_triggerChanges"
        >
          <el-option
            v-for="cabinet in cabinets"
            :key="cabinet.id"
            :label="cabinet.title"
            :value="cabinet.id"
          />
        </el-select>
      </div>
    </div>
    <div class="schedule-template-form-item">
      <div class="schedule-template-form-left">
        <label>
          <span>{{ T.period }}</span>
        </label>
      </div>
      <div class="schedule-template-form-right">
        <el-date-picker
          v-model="dateRange"
          :picker-options="{ firstDayOfWeek: 1 }"
          :format="'dd.MM.yyyy'"
          type="daterange"
          align="right"
          @change="_triggerChanges"
        />
      </div>
    </div>
    <div class="schedule-template-form-item">
      <div class="schedule-template-form-left">
        <label>
          <span>{{ T.type }}</span>
        </label>
      </div>
      <div class="schedule-template-form-right">
        <items-list
          v-model="active"
          v-bind="multiSelectTemplateActiveProps"
          @change="_activeOnChange"
        />
      </div>
    </div>
    <div class="schedule-template-form-item">
      <div class="schedule-template-form-left">
        <label>
          <span>{{ T.stitched_only }}</span>
        </label>
      </div>
      <div class="schedule-template-form-right">
        <input
          v-model="expired"
          type="checkbox"
          class="checkbox"
          @change="_expiredOnChange"
        >
      </div>
    </div>
  </div>
</template>

<script>
import ItemsList from '../../../../items_list.vue'
import { multiSelectTemplateActiveProps } from '../modal_form_config.js'
import { WITHOUT_CABINET_ID } from '../../../const.js'

export default {
  name: 'ScheduleTemplateModalBodyLeft',
  components: { ItemsList },
  props: {
    initialSelectors: { type: Object, required: true },
    users: { type: Array, required: true },
    cabinets: { type: Array, required: true },
    allCIDs: { type: Array, required: true },
    currentClinic: { type: Object, required: true },
  },
  data () {
    return {
      multiSelectTemplateActiveProps,
      allUIDs: null,
      filteredUsers: [],
      selectedUser: '',
      selectedCabinet: '',
      dateRange: null,
      active: ['true'],
      expired: false,
    }
  },
  created () {
    this.allUIDs = this.users.map((user) => user.id)
    this._setUsers()
  },
  mounted () {
    this.selectedUser = this.initialSelectors.userId || ''
    this._triggerChanges()
  },
  methods: {
    _getRequestData () {
      const params = {}

      if (Array.isArray(this.dateRange)) {
        params.start_date = Utils.dateJStoRuby(this.dateRange[0])
        params.end_date = Utils.dateJStoRuby(this.dateRange[1])
      }

      params.user_ids = JSON.stringify(this.selectedUser === '' ? this.allUIDs : [this.selectedUser])

      let cabinetIds

      switch (this.selectedCabinet) {
        case '': cabinetIds = this.allCIDs
          break
        case WITHOUT_CABINET_ID: cabinetIds = [null]
          break
        default: cabinetIds = [this.selectedCabinet]
      }

      params.cabinet_ids = JSON.stringify(cabinetIds)
      params.active = JSON.stringify(this.active.map((active) => JSON.parse(active)))
      params.expired = this.expired
      params.clinic_id = this.currentClinic.id

      return params
    },

    _activeOnChange () {
      if (this.active.includes('false')) this.expired = false
      this._triggerChanges()
    },

    _expiredOnChange () {
      if (this.expired) this.active = ['true']
      this._triggerChanges()
    },

    _triggerChanges () {
      this.$emit('triggerChanges', this._getRequestData())
    },

    _findUsers (searchString) {
      const reg = new RegExp(searchString, 'i')
      this.filteredUsers = this.users.filter((user) => reg.test(user.full_name))
    },

    _setUsers () {
      if (!this.filteredUsers.lenght) this.filteredUsers = this.users.slice()
    },
  },
}
</script>
