import RefuseResultsCatalog from './refuse_results'
import { createVueApp } from '@/vue_components/create_vue_app'

export const VueRefuseResultsEntryPoint = () => {
  const el = '#vue_refuse_results_catalog'

  if (!document.querySelector(el)) return
  createVueApp({
    el,
    render: (h) => h(RefuseResultsCatalog),
  })
}
