<template>
  <m-select-lazy
    :value="value"
    :fetch-method="(params) => api.fetchAttractionSources(params)"
    :label="t('attraction_source')"
    :placeholder="t('all_attraction_sources')"
    :multiple="multiple"
    use-first-time-fetch
    full-width
    filterable
    @changeByLazy="$emit('change', $event)"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { FiltersApi } from '@/vue_apps/ReportsModule/_filters/api/FiltersApi'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'

export default defineComponent({
  name: 'AttractionSourcesFilter',

  components: { MSelectLazy },

  props: {
    value: { type: [Array, Object], default: null },
    multiple: Boolean,
  },

  emits: ['change'],

  data () {
    return {
      api: new FiltersApi(),
    }
  },
})
</script>
