<template>
  <div
    v-show="show"
    class="panel panel-primary panel-edit"
  >
    <api-actions-modal
      v-bind="apiActionsModal"
      @close="apiActionsModal.visibility = false"
      @updateAuthenticationToken="updateAuthenticationToken"
      @updateCompositeKey="updateCompositeKey"
      @deleteApiClient="deleteApiClient"
    />
    <api-key-modal
      ref="apiKeyModal"
      v-bind="apiKeyModal"
      @close="apiKeyModal.visibility = false"
      @createApiClientAccessKey="createApiClientAccessKey"
      @updateApiClientAccessKey="updateApiClientAccessKey"
      @deleteApiClientAccessKey="deleteApiClientAccessKey"
    />
    <epic-spinner
      :visibility="activeRequestState"
    />
    <div
      class="panel-heading panel-heading-flex"
    >
      <div
        class="panel-heading-title"
      >
        <span
          class="panel-heading-icon fad fa-fw fa-cloud"
        />
        <h3
          class="panel-title"
        >
          {{ T.api_page.clients.editing_api_client }}
        </h3>
      </div>
      <div
        class="panel-heading-buttons"
      >
        <span
          class="panel_close_button fad fa-fw fa-times hover-white-icon"
          @click="$emit('close')"
        />
      </div>
    </div>
    <div
      class="panel-body"
    >
      <form
        class="simple_form flex-form"
      >
        <div
          class="form-label"
        >
          <label
            class="string required"
            for="api_client_title"
          >
            <span>
              {{ T.activerecord.attributes.api_client.title }}
            </span>
            <abbr
              :title="T.field_required"
            >
              *
            </abbr>
          </label>
        </div>
        <div
          class="form-value"
        >
          <div
            :class="titleError ? 'has-error' : ''"
            class="form-group string required api_client_title"
          >
            <input
              id="api_client_title"
              v-model="title"
              class="form-control string required tooltip-bottom"
              type="text"
              autocomplete="off"
            >
            <div
              v-if="titleError"
              class="under_field_error"
            >
              {{ titleError }}
            </div>
          </div>
        </div>
        <div class="flex-form api-client-roles">
          <div class="form-label">
            {{ T.api_page.clients.api_client_roles }}
          </div>

          <el-select
            v-model="apiRoleIds"
            multiple
            size="small"
            :placeholder="T.api_page.roles.api_roles"
          >
            <el-option
              v-for="role in apiRoles"
              :key="role.id"
              :label="role.name"
              :value="role.id"
            />
          </el-select>
        </div>
        <div
          class="form-label"
        >
          <label
            class="string optional"
            for="api_client_authentication_token"
          >
            {{ `${t('activerecord.attributes.api_client.simple_token')}` }}
            <i
              v-tooltip="t('activerecord.attributes.api_client.simple_token_tooltip')"
              class="fad fa-info-circle explanation-mark"
            />
          </label>
        </div>
        <div
          class="form-value"
        >
          {{ authenticationToken }}
          <el-popover
            placement="top"
            trigger="hover"
            transition="el-fade-in"
            popper-class="tooltip-bottom"
            :content="T.api_page.clients.update_api_client_authentication_token"
          >
            <span
              slot="reference"
              class="fad fa-fw fa-pen"
              @click="showUpdateAuthenticationTokenModal"
            />
          </el-popover>
        </div>
        <a
          ref="downloadCsv"
          href=""
          :v-if="false"
        />
        <div
          class="form-label"
        >
          <label
            class="string optional"
            for="api_client_authentication_token"
          >
            {{ T.api_page.clients.api_keys }}
          </label>
        </div>
        <button
          class="btn btn-primary btn-with-icon btn-sm"
          @click.prevent.stop="showCreateApiKeyModal"
        >
          <div
            class="btn-with-icon_icon fad fa-fw fa-key"
          />
          <div
            class="btn-with-icon_text"
          >
            {{ T.add }}
          </div>
        </button>
        <table
          class="table table-striped table-hover"
        >
          <tbody>
            <tr
              v-for="(apiKey) in apiKeysList"
              :key="apiKey.id"
            >
              <td
                @click="showUpdateApiKeyModal(apiKey.name, apiKey.id)"
              >
                {{ apiKey.name }}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="form-btns"
        >
          <div>
            <button
              :disabled="!formValid || activeRequestState"
              class="btn btn-primary btn-sm btn-with-icon"
              @click="updateApiClient"
            >
              <span class="btn-with-icon_icon fad fa-save" />
              <span class="btn-with-icon_text">{{ t('save') }}</span>
            </button>
          </div>
          <span
            class="fad fa-fw fa-trash btn-show-delete delete-record-button"
            @click="showDeleteApiClientModal"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ApiActionsModal from '@/vue_components/api/api_actions_modal.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import ApiKeyModal from '@/vue_components/api/api_key_modal.vue'

export default {
  name: 'ApiClientEdit',

  components: {
    EpicSpinner,
    ApiActionsModal,
    ApiKeyModal,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      activeRequestState: false,
      apiActionsModal: {
        visibility: false,
        actionButtonDisabled: false,
        expectedEvent: null,
        headerMessage: '',
        message: '',
        permission: '',
        actionButtonMessage: '',
      },
      apiKeyModal: {
        visibility: false,
        actionButtonDisabled: false,
        expectedEvent: null,
        keyName: '',
        keyId: null,
        deleteButtonVisibility: false,
        headerMessage: '',
        actionButtonMessage: '',
      },

      id: null,
      title: '',
      titleError: null,
      authenticationToken: '',
      apiRoles: Object.create(null),
      apiRoleIds: [],
      createdTokens: Object.create(null),
      keyName: '',
      apiKeysList: Object.create(null),
    }
  },

  computed: {
    formValid () {
      return this.title && this.title.trim().length > 0
    },
  },

  watch: {
    show () {
      if (!this.show) this.resetForm()
    },
  },

  methods: {
    edit (apiClient, apiRoles) {
      this.id = apiClient.id
      this.title = apiClient.title
      this.titleError = null
      this.authenticationToken = apiClient.authentication_token
      this.apiRoles = apiRoles
      this.apiRoleIds = apiClient.api_role_ids
      this.loadApiClientAccessKeysList()
    },

    showUpdateAuthenticationTokenModal () {
      this.apiActionsModal.visibility = true
      this.apiActionsModal.expectedEvent = 'updateAuthenticationToken'
      this.apiActionsModal.headerMessage = T.api_page.clients.api_client_authentication_token_updates
      this.apiActionsModal.message = T.api_page.clients.update_api_client_authentication_token_warning
      this.apiActionsModal.permission = ''
      this.apiActionsModal.actionButtonMessage = T.refresh
    },

    showDeleteApiClientModal () {
      this.apiActionsModal.visibility = true
      this.apiActionsModal.expectedEvent = 'deleteApiClient'
      this.apiActionsModal.headerMessage = T.api_page.clients.api_client_removal
      this.apiActionsModal.message = `${T.api_page.clients.remove_api_client}?`
      this.apiActionsModal.permission = ''
      this.apiActionsModal.actionButtonMessage = T.delete
    },

    showCreateApiKeyModal () {
      this.apiKeyModal.visibility = true
      this.apiKeyModal.expectedEvent = 'createApiClientAccessKey'
      this.apiKeyModal.keyName = ''
      this.apiKeyModal.keyId = null
      this.apiKeyModal.deleteButtonVisibility = false
      this.apiKeyModal.headerMessage = T.api_page.clients.creating_api_key
      this.apiKeyModal.actionButtonMessage = T.create
    },

    showUpdateApiKeyModal (apiKeyName, apiKeyId) {
      this.$refs.apiKeyModal.setApiKeyName(apiKeyName)
      this.apiKeyModal.visibility = true
      this.apiKeyModal.expectedEvent = 'updateApiClientAccessKey'
      this.apiKeyModal.keyId = apiKeyId
      this.apiKeyModal.deleteButtonVisibility = true
      this.apiKeyModal.headerMessage = T.api_page.clients.api_key_updates
      this.apiKeyModal.actionButtonMessage = T.api_page.confirm
    },

    loadIntegrationData () {
      const god = this

      if (god.activeRequestState) return

      god.activeRequestState = true

      $.ajax({
        type: 'GET',
        url: Routes.integration_data_api_client_path(god.id),
        success (integrationData) {
          console.log(integrationData)
        },
        error () {
          Services
            .ui
            .notificator()
            .error(god.T.abstract_error_message, god.T.error, 0)
        },
        complete () {
          god.activeRequestState = false
        },
      })
    },

    loadApiClientAccessKeysList () {
      const god = this

      if (god.activeRequestState) return

      god.activeRequestState = true

      $.ajax({
        type: 'GET',
        url: Routes.list_api_client_access_keys_path(god.id),
        success (apiKeysList) {
          god.apiKeysList = apiKeysList
          god.activeRequestState = false
        },
      })
    },

    createApiClientAccessKey (keyName) {
      const god = this

      $.ajax({
        type: 'POST',
        url: Routes.api_client_access_keys_path(god.id),
        data: {
          api_access_key: {
            name: keyName,
          },
        },
        success (apiKey) {
          god.loadApiClientAccessKeysList()
          god.generateCsv(apiKey.identity, apiKey.secret_key)
          god.apiKeyModal.visibility = false
          god.$refs.apiKeyModal.setApiKeyName('')
        },
      })
    },

    updateApiClientAccessKey (apiKeyName, apiKeyId) {
      const god = this

      $.ajax({
        type: 'PUT',
        url: Routes.api_client_access_key_path(god.id, apiKeyId),
        data: {
          api_access_key: {
            name: apiKeyName,
          },
        },
        success () {
          god.loadApiClientAccessKeysList()
          god.apiKeyModal.visibility = false
          god.$refs.apiKeyModal.setApiKeyName('')
        },

      })
    },

    deleteApiClientAccessKey (apiKeyId) {
      const god = this

      $.ajax({
        type: 'DELETE',
        url: Routes.api_client_access_key_path(god.id, apiKeyId),
        success () {
          god.loadApiClientAccessKeysList()
          god.apiKeyModal.visibility = false
          god.$refs.apiKeyModal.setApiKeyName('')
          Services
            .ui
            .notificator()
            .success(T.api_page.clients.api_key_deleted)
        },
      })
    },

    updateApiClient () {
      const god = this

      if (god.activeRequestState) return

      god.activeRequestState = true

      $.ajax({
        type: 'PUT',
        url: Routes.api_client_path(god.id),
        data: {
          api_client: {
            title: god.title,
            api_role_ids: god.apiRoleIds,
          },
        },
        success (apiClient) {
          god.$emit('updated')
          god.edit(apiClient, god.apiRoles)
        },
        error ({ status, responseJSON: errors }) {
          if (status !== 422) return

          if (Array.isArray(errors.title) && errors.title.length) {
            god.titleError = errors.title[0]
          }
        },
        complete () {
          Services
            .ui
            .notificator()
            .success(T.changes_updated)
          god.activeRequestState = false
        },
      })
    },

    updateAuthenticationToken () {
      const god = this

      if (god.activeRequestState) return

      god.activeRequestState = true

      $.ajax({
        type: 'POST',
        url: Routes.update_authentication_token_api_client_path(god.id),
        success (authenticationToken) {
          god.$emit('updated')
          god.authenticationToken = authenticationToken
          god.apiActionsModal.visibility = false
        },
        error () {
          Services
            .ui
            .notificator()
            .error(god.T.abstract_error_message, god.T.error, 0)
        },
        complete () {
          god.activeRequestState = false
        },
      })
    },

    updateCompositeKey () {
      const god = this

      if (god.activeRequestState) return

      god.activeRequestState = true

      $.ajax({
        type: 'POST',
        url: Routes.update_composite_key_api_client_path(god.id),
        success () {
          god.$emit('updated')
          god.apiActionsModal.visibility = false
        },
        error () {
          Services
            .ui
            .notificator()
            .error(god.T.abstract_error_message, god.T.error, 0)
        },
        complete () {
          god.activeRequestState = false
        },
      })
    },

    deleteApiClient () {
      const god = this

      if (god.activeRequestState) return

      god.activeRequestState = true

      $.ajax({
        type: 'DELETE',
        url: Routes.api_client_path(god.id),
        success () {
          god.$emit('updated')
          god.apiActionsModal.visibility = false
          god.$emit('close')
          Services
            .ui
            .notificator()
            .success(T.record_successfully_deleted)
        },
        error () {
          Services
            .ui
            .notificator()
            .error(god.T.abstract_error_message, god.T.error, 0)
        },
        complete () {
          god.activeRequestState = false
        },
      })
    },

    generateCsv (identity, accessKey) {
      const data = [
        ['identity', 'secretKey'],
        [identity, accessKey],
      ]

      let csvContent = ''
      data.forEach(function (infoArray, index) {
        const dataString = infoArray.join(';')
        csvContent += index < data.length ? dataString + '\n' : dataString
      })

      const csvFile = URL.createObjectURL(
        new Blob([csvContent], { type: 'text/csv;encoding:utf-8' })
      )

      this.$refs.downloadCsv.href = csvFile
      this.$refs.downloadCsv.setAttribute('download', 'apiKey.csv')
      this.$refs.downloadCsv.click()
    },

    resetForm () {
      this.id = null
      this.title = ''
      this.authenticationToken = ''
    },
  },
}
</script>
