<template>
  <checkbox-filter
    v-bind="$attrs"
    :label="t('reports.entry_with_order')"
    v-on="$listeners"
  />
</template>

<script>
import CheckboxFilter from '@/vue_apps/ReportsModule/_filters/components/share/CheckboxFilter.vue'

export default {
  name: 'EntryWithOrderFilter',
  components: { CheckboxFilter },
}
</script>
