import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('enums.appointment.status')

export const APPOINTMENT_STATUSES_ITEMS = [
  ts('approved'),
  ts('need_approval'),
  ts('billed'),
  ts('canceled'),
  ts('failed'),
  ts('arrived'),
  ts('serviced'),
  ts('paid'),
  ts('confirmed_by_administrator'),
].map((title, idx) => ({ id: idx + 1, title }))
