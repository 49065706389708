<template>
  <modal
    id="waiting-create-modal"
    :modal-visibility="visibility"
    :modal-header-buttons="modalButtonsAll"
    modal-class="modal-xl modal-header-orange"
    @close="close"
  >
    <template #header>
      <span class="fad fa-tasks" />
      {{ t('waiting_list') }}
    </template>

    <template #body>
      <epic-spinner :visibility="loading" />
      <div class="appointment-form-flex d-flex">
        <client-form
          ref="clientForm"
          :strict="forcedClientEdit"
          :disabled="clientFormDisabled"
          :errors="validationMessages"
          :sexes="[...SEXES_VALUES]"
          :online-access-options="[...ALLOWED_PROHIBITED_FORM]"
          :client-groups="clientGroups"
          :clinic-id="form.waitingItem.currentClinicId"

          :second-name.sync="form.client.secondName"
          :name.sync="form.client.name"
          :surname.sync="form.client.surname"
          :birthdate.sync="form.client.birthdate"
          :phone.sync="form.client.phone"
          :sex.sync="form.client.sex"
          :entry-online-access.sync="form.client.onlineAccess"
          :groups.sync="form.client.groups"
          :email="form.client.email"
          :treating-doctor.sync="form.client.treatingDoctor"
          :personal-discount.sync="form.client.personalDiscount"
          :legal-representatives.sync="legalRepresentatives"
          :send-data-to-egisz="form.client.sendDataToEgisz"
          :snils.sync="form.client.snils"
          :additional.sync="form.client.additional"
          :patient-card-number.sync="form.client.patientCardNumber"

          @update:surname="setClientSearchParam('surname', $event)"
          @update:secondName="setClientSearchParam('second_name', $event)"
          @update:name="setClientSearchParam('name', $event)"
          @update:phone="setClientSearchParam('phone', $event)"
          @update:email="form.client.email = $event; validationMessages.email = []"
          @update:sendDataToEgisz="form.client.sendDataToEgisz = $event; resetSnils()"
          @add-legal-representative="$emit('add-legal-representative')"
          @request-demo="showDemoClient"
          @phone-mask-settings-change="clientPhoneMaskSettings = $event"
        >
          <template #betterCallSaul>
            <m-icon
              v-if="form.clientId"
              v-tooltip="t('contactWithPatient')"
              :data-client-id="form.clientId"
              :data-phone="form.client.phone"
              class="pointer phone_number"
              icon="betterCallSaul"
              color="primary"
            />
          </template>
        </client-form>

        <div class="appointment-form-middle block-header-orange">
          <appointment-similar-clients
            :clients="similarClients.clients"
            :selected.sync="similarClients.selected"
            @update:selected="updateClientSummary"
          />

          <appointment-client-summary
            :client-id="formData.clientSummary.clientId"
            :medical-record-id="formData.clientSummary.medicalRecordId"
            :paid-sum="formData.clientSummary.paidSum"
            :credit-debt="formData.clientSummary.creditDebt"
            :last-visit="formData.clientSummary.lastVisit"
            :previous-appointment-failed="formData.clientSummary.previousAppointmentFailed"
            :last-visit-to-the-same-doctor-days-ago="formData.clientSummary.lastVisitToTheSameDoctorDaysAgo"
            :last-visit-to-the-same-doctor="formData.clientSummary.lastVisitToTheSameDoctor"
            :adv-distribution="formData.clientSummary.advDistribution"
            :client-groups="formData.clientSummary.clientGroups"
            :client-group-black-list="formData.clientSummary.clientGroupBlackList"
            :client-group-vip="formData.clientSummary.clientGroupVip"
            :active-medical-policies-titles="formData.clientSummary.activeMedicalPoliciesTitles"
          />

          <client-service-card
            v-if="formData.clientSummary.clientId"
            :disabled="clientFormDisabled"
            :content.sync="form.client.serviceCard"
          />
        </div>

        <waiting-list-form
          :disabled="!$security.canManageWaitingList"
          :validation-messages="validationMessages"
          :date.sync="form.date"
          :clinics-array="GET_CURRENT_USER_CLINICS_LIST"
          :current-doctor.sync="form.waitingItem.currentDoctor"
          :current-clinic-id.sync="form.waitingItem.currentClinicId"
          :note.sync="form.waitingItem.note"
          :is-urgent.sync="form.waitingItem.isUrgent"
          :has-depend-appointment.sync="form.waitingItem.hasDependAppointment"
          :created-by="form.waitingItem.createdBy"
          :updated-by="form.waitingItem.updatedBy"
        />
      </div>
    </template>

    <template #footer-right>
      <guarded-control
        tag="button"
        :permissions="['canManageWaitingList']"
        :disabled="buttonsDisabled"
        class="btn btn-success btn-with-icon modal-save"
        type="button"
        @click:allowed="submit"
      >
        <span class="btn-with-icon_icon fad fa-save" />
        <span class="btn-with-icon_text">
          {{ t('save') }}
        </span>
      </guarded-control>
      <guarded-control
        tag="button"
        :permissions="['canManageClient']"
        :disabled="!clientFormDisabled"
        class="btn btn-warning btn-with-icon modal-save"
        type="button"
        @click:allowed="editClient"
      >
        <span class="btn-with-icon_icon fad fa-fw fa-pencil" />
        <span class="btn-with-icon_text">
          {{ t('reception.edit_client') }}
        </span>
      </guarded-control>
      <button
        class="btn btn-primary btn-with-icon modal-close"
        type="button"
        @click="close"
      >
        <span class="btn-with-icon_icon fad fa-times" />
        <span class="btn-with-icon_text">
          {{ t('close') }}
        </span>
      </button>
    </template>
  </modal>
</template>

<script>
import { birthdate, required } from '@/lib/validators/rules'
import { createPhoneValidator, requiredValidator } from '@/lib/validators/validators'
import AppointmentClientSummary from '@/vue_components/appointment/appointment_client_summary.vue'
import AppointmentSimilarClients from '@/vue_components/appointment/appointment_similar_clients.vue'
import WaitingListForm from '@/vue_components/appointment/waiting_list_form/waiting_list_form.vue'
import ClientForm from '@/vue_components/client/client_form.vue'
import { ClientFormConsumer } from '@/vue_components/client/client_form_consumer'
import ClientServiceCard from '@/vue_components/client/client_service_card.vue'
import { SimilarClientsConsumer } from '@/vue_components/client/similar_clients_consumer'
import GuardedControl from '@/vue_components/common/guarded_control'
import { clientAdapter } from '@/vue_components/doctor_schedules/adapters'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import { ModalConsumer } from '@/vue_components/mixins/modals/modal_consumer'
import { ValidationHolder } from '@/vue_components/mixins/validationHolder'
import Modal from '@/vue_components/modal.vue'

import { mapGetters } from 'vuex'
import { ALLOWED_PROHIBITED_FORM, SEXES_VALUES } from '../consts.js'
import {
  getDefaultFormClient,
  getDefaultFormClientSummary,
  getDefaultFormWaitingItem,
  getDefaultValidationsWaitingList,
} from '../methods/appointment_default_data.js'
import { clientEndpoint } from '@/api_entities/client/clients_endpoint.js'
import { waitingListEndpoint } from '../methods/waiting_list_methods.js'
import { fetchClientById } from '@/vue_components/doctor_schedules/rest'
import { validateSnils } from '@/vue_components/doctor_schedules/doctor_schedules_methods'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

/**
 * @see {WaitingCreateModalConsumer}
 */
export default {
  components: {
    MIcon,
    GuardedControl,
    AppointmentClientSummary,
    AppointmentSimilarClients,
    ClientForm,
    ClientServiceCard,
    EpicSpinner,
    Modal,
    WaitingListForm,
  },

  mixins: [SimilarClientsConsumer, ClientFormConsumer, ValidationHolder, ModalConsumer],

  props: {
    visibility: Boolean,
    clientGroups: {
      type: Array,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: Number,
      default: null,
    },
    clientPhone: {
      type: String,
      default: '',
    },
    doctor: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      loading: false,
      clientEdited: true,
      forcedClientEdit: false,
      clientPhoneMaskSettings: { ...Services.phoneMask.defaultMaskSettings },

      formData: {
        similarClients: {
          clients: [],
          selected: null,
        },

        clientSummary: { ...getDefaultFormClientSummary() },
      },

      form: {
        date: '',
        clientId: null,

        client: getDefaultFormClient(),
        waitingItem: getDefaultFormWaitingItem(this.doctor),
      },

      validationMessages: getDefaultValidationsWaitingList(),

      ALLOWED_PROHIBITED_FORM,
      SEXES_VALUES,
    }
  },

  computed: {
    ...mapGetters([
      'GET_CLIENT_GROUPS',
      'GET_CURRENT_USER_CLINICS_LIST',
      'GET_SCHEDULE_DATE',
    ]),

    legalRepresentatives: {
      get () {
        return this.$store.getters['waitingList/GET_LEGAL_REPS']
      },
      set (value) {
        this.form.client.legalRepresentatives = value
        this.$store.commit('waitingList/SET_LEGAL_REPS', value)
      },
    },

    clientFormDisabled () {
      return !this.clientEdited ||
        this.disabled ||
        this.loading
    },

    buttonsDisabled () {
      return Object
        .values(this.validationMessages)
        .some((el) => el.length > 0) ||
        this.disabled ||
        this.loading
    },
  },

  watch: {
    'form.client.phone' (newValue) {
      const minPhoneLength = this.clientPhoneMaskSettings.length
      this.validate('phone', newValue, createPhoneValidator(minPhoneLength))
      this.removeValidationMessage('surname', t('client_with_the_same_fio_phone_and_birthdate_exist'))
    },
    'form.client.surname' (newValue) {
      this.validate('surname', newValue, requiredValidator)
    },
    'form.client.secondName' () {
      this.removeValidationMessage('surname', t('client_with_the_same_fio_phone_and_birthdate_exist'))
    },
    'form.client.name' (newValue) {
      this.removeValidationMessage('surname', t('client_with_the_same_fio_phone_and_birthdate_exist'))
      if (!this.forcedClientEdit) return

      this.validate('name', newValue, requiredValidator)
    },
    'form.client.birthdate' (newValue) {
      const rules = [birthdate]
      if (this.forcedClientEdit) {
        rules.push(required)
      }
      const messages = {
        required: t('activerecord.required.text'),
        birthdate: t('incorrect_birthdate'),
      }
      this.validateRules('birthdate', newValue, rules, messages)
      this.removeValidationMessage('surname', t('client_with_the_same_fio_phone_and_birthdate_exist'))
    },
    'form.client.sex' (newValue) {
      if (!this.forcedClientEdit) return

      this.validate('sex_id', newValue, requiredValidator)
    },

    'form.client.snils' (newValue) {
      if (!this.form.client.sendDataToEgisz) { return }
      this.validate('snils', newValue, requiredValidator)
    },

    'form.date' (newValue) {
      this.validate('date', newValue, requiredValidator)
    },
    'form.waitingItem.currentDoctor' (newValue) {
      this.validate('user_id', newValue, requiredValidator)
    },

    'form.client.patientCardNumber' () {
      this.validationMessages.patient_card_number = []
    },

    clientsSearch: {
      deep: true,
      handler (newValue) {
        if (
          !this.similarClients.selected &&
          !this.form.clientId &&
          (newValue.surname.length > 2 ||
            newValue.name.length > 2 ||
            newValue.second_name.length > 2 ||
            newValue.phone.length > 2)
        ) {
          this.findSimilarClients(newValue)
        }
      },
    },
  },

  mounted () {
    this.$refs.clientForm.focusFirstField()

    this.form.date = this.GET_SCHEDULE_DATE

    if (this.clientId) {
      this.fillClientForm(this.clientId)
      this.$updateSync('client-id', null)
    }

    if (this.clientPhone) {
      this.form.client.phone = this.clientPhone
      this.$updateSync('client-phone', '')
    }
  },

  methods: {
    fillClientForm (clientId) {
      fetchClientById(clientId)
        .then((client) => {
          this.updateClientSummary(client)
        })
    },

    editClient () {
      this.forcedClientEdit = true
      this.clientEdited = true
    },

    showDemoClient () {
      const vm = this
      Utils.fakeForms.client()
        .then((client) => {
          vm.similarClients.clients = []
          vm.setDemoClient(client, 'form.client')
        })
    },

    updateClientSummary (client) {
      if (!client) { return }
      this.setClient(client)
      this.fetchClientSummary()
    },

    setClient (client) {
      this.form.clientId = client.id
      this.form.client = {
        ...clientAdapter.toClient(client),
      }

      this.legalRepresentatives = this.form.client.legalRepresentatives

      this.similarClients.clients = []
      this.similarClients.selected = null
      this.clientEdited = false

      this.$nextTick(() => {
        Services.telephony.reset()
      })
    },

    fetchClientSummary () {
      const summaryParams = {
        client: { id: this.form.clientId },
        date: this.form.date,
        user_id: this.form.waitingItem.currentDoctor && this.form.waitingItem.currentDoctor.id,
      }

      clientEndpoint.getSummary(summaryParams)
        .then((clientSummary) => {
          this.formData.clientSummary = clientSummary

          this.form.client.groups = clientSummary.clientGroups.map((group) => group.id)
          this.$store.commit('appointment/SET_MOVED_APPOINTMENT', { ...this.form })
        })
        .catch(Utils.reportError('waiting_create_modal:fetchClientSummary'))
    },

    submit () {
      if (!validateSnils(
        { form: this.form, validationMessages: this.validationMessages },
        this.setValidationMessages
      )) { return }

      let request = Promise.resolve()

      this.loading = true

      if (this.form.clientId) {
        if (this.clientEdited) {
          request = clientEndpoint
            .update(this.form.clientId, {
              ...this.form.client,
              ...{ legalRepresentatives: this.legalRepresentatives },
            })
            .then(() => {
              this.clientEdited = false
            })
        }
      } else {
        request = clientEndpoint.create({
          ...this.form.client,
          ...{ legalRepresentatives: this.legalRepresentatives },
        })
          .then((response) => {
            this.form.clientId = response.id
            this.clientEdited = false
          })
      }

      request
        .then(() => waitingListEndpoint.create({
          date: this.form.date,
          clientId: this.form.clientId,
          ...this.form.waitingItem,
        }))
        .then(this.onCreateSuccess)
        .catch((error) => this.setValidationMessages(this.validationMessages, error.responseJSON))
        .finally(() => {
          this.loading = false
        })
    },

    clearData () {
      Object.assign(this.$data, this.$options.data())
      this.$store.dispatch('waitingList/resetForm')
      this.$nextTick(() => {
        this.validationMessages = getDefaultValidationsWaitingList()
      })
    },

    resetSnils () {
      this.form.client.snils = ''
      this.validationMessages.snils = []
    },

    close () {
      this.loading = false
      this.$emit('close')
      this.clientEdited = false
      this.forcedClientEdit = false

      this.clearData()
    },

    onCreateSuccess () {
      this.close()
      this.$store.commit('SET_MODE_GRID_SCHEDULE', $.scheduleGrid.MODE_DEFAULT)
      Notificator.success(t('record_successfully_created'))
    },

    startLoading () {
      this.loading = true
    },

    stopLoading () {
      this.loading = false
    },
  },
}
</script>
