<template>
  <div class="duration-selector-container">
    <items-list
      v-model="_current"
      :disabled="disabled"
      v-bind="durationProps"
      :items="items"
    >
      <basic-tooltip :text="t('add_your_own_version')">
        <li
          v-show="!disabled && !newValueInputVisible"
          class="add-duration-button vue-list-item"
          @click="showNewInput"
        >
          +
        </li>
      </basic-tooltip>
      <span
        v-if="newValueInputVisible"
        class="new_value_input_container"
      >
        <input
          ref="newValueInput"
          v-model="newValue"
          style="display: inline-block; width: 38px"
          @blur="newValueInputVisible = false"
          @keyup.enter="addNewValue"
        >
        <span
          class="fad fa-check new-value-submit"
          @mousedown="addNewValue"
        />
        <span
          class="fad fa-times new-value-cancel"
          @click="newValueInputVisible = false"
        />
      </span>
    </items-list>
  </div>
</template>

<script>
import ItemsList from '@/vue_components/items_list.vue'
import { MIN_DURATION } from '@/vue_components/appointment/const'
import BasicTooltip from '@/vue_components/common/basic_tooltip'

export default {
  name: 'DurationSelector',
  components: {
    BasicTooltip,
    ItemsList,
  },
  props: {
    durations: {
      type: Array,
      required: true,
    },
    current: {
      type: Number,
      default: 0,
    },
    minValue: {
      type: Number,
      default: MIN_DURATION,
    },
    maxValue: {
      type: Number,
      required: true,
    },
    disabled: Boolean,
  },
  data () {
    return {
      durationProps: {
        multiSelect: false,
        idItemField: 'selected',
        displayedItemField: 'selected',
      },
      newValueInputVisible: false,
      newValue: null,
    }
  },
  computed: {
    items () {
      return this.durations.concat()
        .sort((a, b) => a - b)
        .map((d) => ({ selected: d.toString() }))
    },
    _current: {
      get () {
        return [this.current.toString()]
      },
      set (value) {
        this.$emit('updateCurrent', +value[0])
      },
    },
  },
  methods: {
    showNewInput () {
      this.newValueInputVisible = true
      this.$nextTick(() => { this.$refs.newValueInput.focus() })
    },
    addNewValue (e) {
      const number = +this.newValue

      if (Number.isNaN(number)) {
        Notificator.error(T.value_must_be_a_number)
        e.preventDefault()

        return
      }

      if (number < this.minValue) {
        Notificator.error(T.too_small_value)
        e.preventDefault()

        return
      }

      if (number > this.maxValue) {
        Notificator.error(T.too_big_value)
        e.preventDefault()

        return
      }

      if (this.durations.includes(number)) {
        this.$emit('updateCurrent', number)
      } else {
        this.$emit('newDuration', number)
      }

      this.newValue = null
      this.newValueInputVisible = false
    },
  },
}
</script>
