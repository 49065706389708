import CabinetsCatalog from './cabinets'
import { createVueApp } from '@/vue_components/create_vue_app'

export const VueCabinetsEntryPoint = () => {
  const el = '#vue_cabinets_catalog'

  if (!document.querySelector(el)) { return }
  createVueApp({
    el,
    render: (h) => h(CabinetsCatalog),
  })
}
