<template>
  <div
    id="analysis_orders"
    class="analysis_orders_component_wrapper"
  >
    <si>
      <si-control
        @reset="resetFilters"
      >
        <el-input
          v-model="siFilters.searchString"
          class="search"
          :placeholder="t('laboratories.client_name_or_id')"
        />
        <date-range-picker-btn
          v-model="siFilters.date"
          class="component"
        />
        <date-range-picker
          :date-range="siFilters.date"
          :application-date-format="dateFormat"
          class="component"
          @change="siFilters.date = $event"
        />
        <el-select
          v-model="siFilters.state"
          class="component"
        >
          <el-option
            :label="t('all_statuses')"
            :value="null"
          />
          <el-option
            v-for="state in analysisOrderStates"
            :key="state"
            :label="analysisOrderState(state)"
            :value="state"
          />
        </el-select>

        <el-select
          v-model="siFilters.laboratoryId"
          class="component"
        >
          <el-option
            :label="t('all_analysis_laboratory')"
            :value="null"
          />
          <el-option
            v-for="laboratory in laboratories"
            :key="laboratory.id"
            :label="laboratory.title"
            :value="laboratory.id"
          />
        </el-select>

        <template #refresh>
          <span />
        </template>

        <template #toolsControl>
          <mass-orders-sending-component
            v-if="isLabsForMassSelect"
            class="analysis_orders__workplace-selector"
            :title.sync="massOrdersSendingTitle"
            :icon="'fad fa-box'"
            :items="workplaceList"
            :is-loading="(isLabsRequiringWorkplaces && isWorkplacesLoading) || isOrdersSending"
            :require-workplaces="isLabsRequiringWorkplaces"
            @selectWorkplace="sendAnalysisOrders"
            @getConnectedWorkplaces="getConnectedWorkplaces"
          />
        </template>
      </si-control>
      <si-table
        v-loading="lockUI"
      >
        <template #header>
          <si-field>
            <div
              class="id analysis_orders_si_column"
            >
              {{ t('common.number') }}
            </div>

            <div
              class="date analysis_orders_si_column"
            >
              {{ t('common.date') }}
            </div>

            <div
              class="state analysis_orders_si_column"
            >
              {{ t('status') }}
            </div>

            <div class="full_name analysis_orders_si_column">
              {{ t('client') }}
            </div>

            <div class="order analysis_orders_si_column">
              {{ t('order') }}
            </div>

            <div class="analysis_laboratory analysis_orders_si_column">
              {{ t('analysis_laboratory') }}
            </div>

            <div class="analysis_clinic analysis_orders_si_column">
              {{ t('clinic') }}
            </div>

            <div class="questionnaire analysis_orders_si_column">
              {{ t('laboratories.questionnaire_title') }}
            </div>

            <div
              v-tooltip="t('laboratories.preanalyticsTooltip')"
              class="preanalytics analysis_orders_si_column"
            >
              {{ t('laboratories.preanalytics_title') }}
            </div>

            <div
              class="order_send_checkbox analysis_orders_si_column"
              @click="onDisabledSelectAllOrders"
            >
              <el-checkbox
                :disabled="!isLabsForMassSelect || isOrdersSending"
                :value="massSelectCB"
                @change="onSelectAllOrders($event)"
              />
            </div>
          </si-field>
        </template>

        <template #body>
          <si-field
            v-for="analysisOrder in analysisOrders"
            :key="analysisOrder.id"
            class="clickable"
            @safe-click="visitAnalysisOrder(analysisOrder.id)"
          >
            <div
              class="id analysis_orders_si_column"
            >
              {{ analysisOrder.id }}
            </div>

            <div
              class="date analysis_orders_si_column"
            >
              {{ dateTime(analysisOrder.date) }}
            </div>

            <div
              class="state analysis_orders_si_column"
            >
              <span
                :class="analysisOrderStateIcon(analysisOrder.state)"
              />
              {{ analysisOrderState(analysisOrder.state) }}
            </div>

            <div class="full_name analysis_orders_si_column">
              {{ analysisOrder.clientFullName }}
            </div>

            <div class="order analysis_orders_si_column">
              <a
                v-if="analysisOrder.orderId"
                :href="$routes.order_path(analysisOrder.orderId)"
              >
                {{ analysisOrder.orderId }} </a>
            </div>

            <div class="analysis_laboratory analysis_orders_si_column">
              {{ analysisOrder.analysisLaboratoryTitle }}
            </div>

            <div class="analysis_clinic analysis_orders_si_column">
              {{ getClinicTitle(analysisOrder.clinicId) }}
            </div>

            <div class="questionnaire analysis_orders_si_column">
              <i
                :class="{
                  'fas fa-check': analysisOrder.questionnaireFilled,
                  'fad fa-circle-notch': !analysisOrder.questionnaireFilled
                }"
              />
            </div>

            <div class="preanalytics analysis_orders_si_column">
              <i
                :class="{
                  'fas fa-check': analysisOrder.preanalyticsRequested,
                  'fad fa-circle-notch': !analysisOrder.preanalyticsRequested
                }"
              />
            </div>

            <div
              class="order_send_checkbox analysis_orders_si_column"
              @click="
                (analysisOrder.state === analysisOrderStates.PREPARED) &&
                  onDisabledCheckboxClick(
                    $event,
                    analysisOrder.questionnaireFilled && analysisOrder.preanalyticsRequested
                  )"
            >
              <el-checkbox
                v-model="analysisOrder.selected"
                :disabled="!isLabsForMassSelect || isOrdersSending || analysisOrder.state !== analysisOrderStates.PREPARED"
                @change="setOrderSelected($event, analysisOrder.id)"
              />
            </div>
          </si-field>
        </template>
      </si-table>
      <si-footer>
        <pagination
          :current-page.sync="currentPage"
          :page-count="totalPages"
          layout="prev, pager, next"
          @current-change="onPageChange"
        />
      </si-footer>
    </si>
  </div>
</template>

<script>
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import Si from '@/vue_components/sort_items/si.vue'
import SiControl from '@/vue_components/sort_items/si_control.vue'
import Pagination from '@/vue_components/common/pagination.vue'

import DateRangePicker from '@/vue_components/common/select/date_range_picker.vue'
import DateRangePickerBtn from '@/vue_components/common/select/date_range_picker_btn.vue'
import {
  ANALYSIS_ORDER_HUMAN_STATES,
  ANALYSIS_ORDER_PUBSUB_PREFIX,
  ANALYSIS_ORDER_STATE_ICONS,
  ANALYSIS_ORDER_STATES,
  ANALYSIS_ORDERS_PER_PAGE,
  DEFAULT_INDEX_FILTERS,
  EVENTS,
  SEND_ORDER_MAX_WAIT_TIME_MS_X2,
} from '@/vue_components/analysis_orders/constants.js'
import { LABORATORY_SYSTEM_NAMES } from './constants'
import MassOrdersSendingComponent from './components/mass_orders_sending_component'
import { clinicsEndpoint } from '@/api_entities/catalogs/clinics/clinics_endpoint'

const LOCAL_STATE_STORAGE_KEY = 'analysis_order_index_filters'

export default {
  name: 'AnalysisOrderIndex',

  components: {
    DateRangePicker,
    DateRangePickerBtn,
    SiFooter,
    SiTable,
    SiControl,
    SiField,
    Si,
    Pagination,
    MassOrdersSendingComponent,
  },

  props: {
    clinicId: {
      type: Number,
      default: undefined,
    },

    wsHandler: {
      default: null,
      type: Object,
    },
  },

  data () {
    return {
      siFilters: store.get(LOCAL_STATE_STORAGE_KEY) ||
          store.set(LOCAL_STATE_STORAGE_KEY, DEFAULT_INDEX_FILTERS),
      analysisOrderStates: ANALYSIS_ORDER_STATES,
      analysisOrderHumanStates: ANALYSIS_ORDER_HUMAN_STATES,
      laboratories: [],
      clinics: [],
      analysisOrders: [],
      currentPage: 1,
      totalPages: 1,
      requestInProcess: false,
      dateFormat: gon.localization.date_format.replace(/\//g, '.'),
      uniqueComponentId: Math.random().toString().match(/\d+/g).pop(),

      selectedOrderIds: new Set(),
      selectedOrderIdsSize: 0,
      massSelectSupportableLabs: [LABORATORY_SYSTEM_NAMES.HELIX, LABORATORY_SYSTEM_NAMES.INVITRO],
      massSelectCB: false,
      workplaceList: [],
      isWorkplacesLoading: true,
      isOrdersSending: false,
      workplacesRequiredLabs: [LABORATORY_SYSTEM_NAMES.INVITRO],
    }
  },

  computed: {
    lockUI () {
      return this.requestInProcess
    },

    isLabsForMassSelect () {
      const labId = this.siFilters.laboratoryId
      const systemName = (this.laboratories.find((item) => item.id === labId) || {}).systemName

      return this.siFilters.state === this.analysisOrderStates.PREPARED &&
          this.massSelectSupportableLabs.includes(systemName)
    },

    isLabsRequiringWorkplaces () {
      const labId = this.siFilters.laboratoryId
      const systemName = (this.laboratories.find((item) => item.id === labId) || {}).systemName

      return this.siFilters.state === this.analysisOrderStates.PREPARED &&
          this.workplacesRequiredLabs.includes(systemName)
    },

    massOrdersSendingTitle () {
      return `${t('laboratories.send_orders_to_laboratory')}(${this.selectedOrderIdsSize})`
    },
  },

  watch: {
    siFilters: {
      deep: true,
      handler (newValue) {
        store.set(LOCAL_STATE_STORAGE_KEY, newValue)
        this.getAnalysisOrders()
      },
    },
  },

  mounted () {
    this.currentPage = 1
    this.getAnalysisOrders()
    this.fetchClinics()
  },

  created () {
    this.subscribeToEvents()
  },

  methods: {
    analysisOrderState (state) {
      return t(`enums.analysis_order.state.${ANALYSIS_ORDER_HUMAN_STATES[state]}`)
    },

    analysisOrderStateIcon (state) {
      return `${ANALYSIS_ORDER_STATE_ICONS[state]} analysis_order_state_icon`
    },

    visitAnalysisOrder (analysisOrderId) {
      Turbolinks.visit(`${window.location.pathname}/${analysisOrderId}`)
    },

    dateTime (date) {
      return moment(date).format(`${this.dateFormat.toUpperCase()}`)
    },

    analysisOrderErrors (analysisOrder) {
      if (!analysisOrder.errors.length) {
        return ''
      }

      if (analysisOrder.errors.length === 1) {
        return analysisOrder.errors[0]
      }

      return analysisOrder.errors.join('. ')
    },

    resetFilters () {
      this.currentPage = 1
      this.siFilters = { ...DEFAULT_INDEX_FILTERS }
    },

    getAnalysisOrders (page = 1) {
      this.requestInProcess = true
      const limit = ANALYSIS_ORDERS_PER_PAGE
      const offset = ANALYSIS_ORDERS_PER_PAGE * (page - 1)

      this.sendMessage(EVENTS.FETCH_ANALYSIS_ORDERS, {
        clinicId: this.clinicId,
        ...this.siFilters,
        limit,
        offset,
      })
    },

    getConnectedWorkplaces () {
      this.isWorkplacesLoading = true
      this.sendMessage(EVENTS.GET_CONNECTED_WORKPLACES)
    },

    onPageChange (page) {
      this.getAnalysisOrders(page)
    },

    sendMessage (event, params = {}) {
      params.uniqueComponentId = this.uniqueComponentId

      this.wsHandler.sendMessage(event, params)
    },

    subscribeToEvents () {
      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.GET_CONNECTED_WORKPLACES}`, (data) => {
        this.workplaceList = data.result && data.result.workplaces
          ? data.result.workplaces
          : []

        this.isWorkplacesLoading = false
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.FETCH_ANALYSIS_ORDERS}`, (data) => {
        if (parseInt(data.uniqueComponentId) === parseInt(this.uniqueComponentId)) {
          this.requestInProcess = false

          const { analysisOrders, totalItemsCount } = data.result

          // для управления чекбоксами массового выбора
          this.analysisOrders = analysisOrders
            .filter((item) => !!item.id)
            .map((item) => {
              if (item.state !== this.analysisOrderStates.PREPARED) { return item }

              const newItem = item
              newItem.selected = false

              return newItem
            })
          this.totalPages = Math.ceil(totalItemsCount / ANALYSIS_ORDERS_PER_PAGE)
        }
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.FETCH_LABORATORIES}`, (data) => {
        this.laboratories = data.result
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.WEBSOCKETS_CONNECTED}`, (data) => {
        this.loadLaboratories()
        this.getAnalysisOrders()
        this.getConnectedWorkplaces()
      })

      this.$pubSub.subscribe(`${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.ERROR}`, () => {
        this.isOrdersSending = false
      })

      this.$pubSub.subscribe(
        `${ANALYSIS_ORDER_PUBSUB_PREFIX}.${EVENTS.FETCH_ANALYSIS_ORDER}`,
        ({ analysis_order_id: analysisOrderId }) => {
          this.setOrderSelected(false, analysisOrderId)
          this.analysisOrders = this.analysisOrders.filter((item) => item.id !== analysisOrderId)

          if (this.selectedOrderIds.size === 0) {
            this.selectedOrderIds = new Set()
            this.massSelectCB = false
            this.isOrdersSending = false
          }
        })
    },

    loadLaboratories () {
      this.sendMessage(EVENTS.FETCH_LABORATORIES)
    },

    /**
     * Нажатие на заголовок массового выделения. Вывод предупреждения о недоступности для лаборатории
     * @param event
     */
    onDisabledSelectAllOrders (event) {
      if (!this.isLabsForMassSelect) {
        event.preventDefault()
        event.stopPropagation()

        this.$message.warning(t('laboratories.is_not_available_for_this_laboratory'))
      }
    },

    /**
     * Нажатие на массовый чекбокс
     * @param {Boolean} value
     */
    onSelectAllOrders (value) {
      this.massSelectCB = value

      /* then false */
      if (!value) {
        this.analysisOrders.forEach((item) => {
          item.selected = value
          this.setOrderSelected(value, item.id)
        })

        return
      }

      /* then true */
      let haveOneOrMoreNotCompletedOrders = false
      this.analysisOrders.forEach((item) => {
        if (
          item.state === this.analysisOrderStates.PREPARED &&
          item.questionnaireFilled &&
          item.preanalyticsRequested
        ) {
          item.selected = value
          this.setOrderSelected(value, item.id)

          return
        }

        haveOneOrMoreNotCompletedOrders = true
      })

      /* вывод сообщения о незаполненности одного или нескольких опросников/преаналитики */
      if (haveOneOrMoreNotCompletedOrders) {
        this.$message.warning(t('laboratories.is_not_completed_orders_title'))
      }
    },

    /**
     * Нажатие на чекбокс в заказе
     * @param {Boolean} value
     * @param {Number} analysisOrderId
     */
    setOrderSelected (value, analysisOrderId) {
      value
        ? this.selectedOrderIds.add(analysisOrderId)
        : this.selectedOrderIds.delete(analysisOrderId)

      this.selectedOrderIdsSize = this.selectedOrderIds.size
    },

    /**
     * Нажатие на ячейку в таблице с чекбоксом в заказе
     * @param event
     * @param {Boolean} isQuestionnairePreanalyticsFilled
     * Если false, то вывод сообщения
     */
    onDisabledCheckboxClick (event, isQuestionnairePreanalyticsFilled) {
      if (this.isLabsForMassSelect && isQuestionnairePreanalyticsFilled) { return }

      event.preventDefault()
      event.stopPropagation()

      if (!this.isLabsForMassSelect) {
        /* сообщение о не той лаборатории */
        this.$message.warning(t('laboratories.is_not_available_for_this_laboratory'))

        return
      }

      /* сообщение о незаполненности опросника/преаналитики */
      this.$message.error(t('laboratories.is_not_questionnaire_preanalytics_title'))
    },

    sendAnalysisOrders (workplaceId) {
      const selectedOrdersIds = Array.from(this.selectedOrderIds).map((item) => +item)
      if (!selectedOrdersIds.length) {
        this.$message.warning(t('laboratories.nothing_selected'))

        return
      }

      this.isOrdersSending = true

      const params = {
        analysis_order_ids: selectedOrdersIds,
        analysis_laboratory_id: this.siFilters.laboratoryId,
        workplaceId: workplaceId || gon.application.current_user.workplace_id,
      }

      this.sendMessage(EVENTS.SEND_ANALYSIS_ORDERS, params)

      setTimeout(() => {
        this.isOrdersSending = false
      }, SEND_ORDER_MAX_WAIT_TIME_MS_X2)
    },

    fetchClinics () {
      clinicsEndpoint.getAll({
        status: 'active',
      })
        .then((data) => {
          this.clinics = data.data
        })
        .catch((err) => Utils.reportError(
          'getClinics error',
          t('reception.errors.get_list')
        )(err))
    },

    getClinicTitle (id) {
      return this.clinics.find((item) => item.id === id)?.title
    },
  },
}
</script>
