import { createVueApp } from '@/vue_components/create_vue_app'
import { snakeToCamel } from '@/_api/_requests/helpers'
import PerformerApp from '@/vue_apps/TreatmentPlans/PerformerApp/PerformerApp.vue'

let vueApp = null
export const createPerformerApp = (el = '#treatment_plan_user_id', isNewTreatmentPlan = false) => {
  if (!document.querySelector(el)) { return }
  if (vueApp?.destroy) { return vueApp.destroy() }

  const performer = isNewTreatmentPlan
    ? snakeToCamel(gon.application.current_user)
    : gon.specific.treatment_plan_user

  vueApp = createVueApp({
    el,
    name: 'PerformerAppRoot',
    render: (h) => h(PerformerApp, {
      props: {
        elementId: el.slice(1),
        performerDefault: performer,
      },
    }),
  })
}
