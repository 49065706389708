import ClientFeedbacksConfiguration from '@/vue_components/client_feedbacks/configuration.vue'
import { createVueApp } from '@/vue_components/create_vue_app'
import ClientFeedbacksModule from '@/vue_components/client_feedbacks/ClientFeedbacksModule.vue'

const vueApps = {}

export const createClientFeedbacksModuleVue = (el = '#vue_client_feedbacks_root') => {
  if (vueApps.clientFeedbacksModule?.$destroy) { vueApps.clientFeedbacksModule.$destroy() }

  vueApps.clientFeedbacksModule = createVueApp({
    el,
    name: 'ClientFeedbacksModule',
    render: (h) => h(ClientFeedbacksModule),
  })
}

$document.on('rez/client_feedbacks/feedbacks', () => {
  createClientFeedbacksModuleVue()
})

$document.on('rez/client_feedbacks/configurations/edit', () => {
  if (gon.specific.moduleIsEnabled) {
    if (vueApps.clientFeedbacksConfigurationApp?.$destroy) { vueApps.clientFeedbacksConfigurationApp.$destroy() }

    vueApps.clientFeedbacksConfigurationApp = createVueApp({
      el: '#client-feedback-configuration',
      name: 'ClientFeedbacksConfigurationApp',
      render: (h) => h(ClientFeedbacksConfiguration),
    })
  }
})
