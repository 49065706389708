import { FORMAL_ADDRESS_ID } from '@/vue_present/Reuse/Lists/PayerSearchInput/consts/consts'

const join = (items) => Utils.joinTruthy(items, ', ')

export default {
  methods: {
    clientInfo (client) {
      const info = [this.$filters.fullName(client)]

      const clientPhone = client.phone ? `+ ${Services.phoneMask.setMask(client.phone)}` : ''
      const clientBirthdate = client.birthdate ? Utils.getFormattedDate(client.birthdate) : ''

      if (clientPhone && clientBirthdate) {
        info.push(`${clientBirthdate}, ${clientPhone}`)
      } else {
        info.push(clientPhone, clientBirthdate)
      }

      return join(info)
    },

    companyInfo (company, show = {}) {
      const info = []

      if (show.title === undefined || show.title) {
        info.push(company.title)
      }

      if (show.itm === undefined || show.itm) {
        info.push(company.individual_tax_number)
      }

      if (show.addresses === undefined || show.addresses) {
        if (Array.isArray(company.addresses)) {
          const address = company.addresses.find((address) => address.address_type === FORMAL_ADDRESS_ID)

          if (address) {
            info.push(
              address.region,
              address.area,
              address.city,
              address.street,
              address.house ? `д ${address.house}` : '',
              address.flat ? `оф ${address.flat}` : ''
            )
          } else {
            console.warn('Formal address not found')
          }
        } else {
          console.warn('Addresses is missing')
        }
      }

      return join(info)
    },

    companyInfoTooltip (company) {
      const info = []

      return join(info)
    },
  },
}
