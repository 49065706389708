<template>
  <m-switch
    class="report-switch-filter"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'

export default {
  name: 'SwitchFilter',

  components: { MSwitch },
}
</script>
