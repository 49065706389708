import { DateHelpers } from '@/helpers/DateHelpers'

export const getDefaultProtocol = ({
  id,
  entry,
  clientId,
  clinic,
  doctor,
  printEntryTitle,
  printEntryInfo,
  printClinicHeader,
  printDoctorSignature,
}) => {
  return {
    id,
    title: entry?.title,
    entries: entry ? [entry] : [],
    templateData: JSON.stringify({}),
    templateHtml: '<p>&#xFEFF</p>',
    clientId,
    clinic,
    doctor,
    date: DateHelpers.getBaseFormattedCurrentTimezonedDate(),
    printEntryTitle,
    printEntryInfo,
    printClinicHeader,
    printDoctorSignature,
  }
}
