export const PAYMENT_TYPE_ITEMS = [
  {
    id: 'false',
    title: t('by_cash'),
  },
  {
    id: 'true',
    title: t('reception.by_dms'),
  },
] as const
