<template>
  <div
    :id="elementId"
    class="performer-app"
  >
    <input
      type="hidden"
      name="treatment_plan[user_id]"
      :value="inputHiddenValue"
    >
    <reusable-doctors-list
      v-model="performer"
      class="treatment-plan__performer"
      :current-clinic="currentClinicId"
      label=""
      @onDoctorChange="performer = $event"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'

export default defineComponent({
  name: 'PerformerApp',

  components: { ReusableDoctorsList },

  props: {
    elementId: { type: String, required: true },
    performerDefault: { type: Object, required: true },
  },

  data () {
    return {
      currentClinicId: gon.application.current_clinic?.id,
      performer: null,
    }
  },

  computed: {
    inputHiddenValue () {
      return this.performer?.id || ''
    },
  },

  created () {
    this.performer = this.performerDefault
  },
})
</script>
