<template>
  <div class="entry-types__list-item">
    <div
      class="list-item__title cut flex-grow-1"
    >
      <basic-tooltip
        v-if="entryType.additional || entryType.tip"
        :text="tooltipTipsEntryType(entryType)"
      >
        <span class="fad fa-info-circle more-info-company" />
      </basic-tooltip>
      <basic-tooltip :text="tooltipEntryType(entryType)">
        <span>{{ entryType.title }}</span>
      </basic-tooltip>
    </div>
    <span
      v-if="!readonly"
      class="list-item__remove fad fa-fw fa-times"
      @click="$emit('entryRemove', entryType.id)"
    />
  </div>
</template>

<script>
import BasicTooltip from '@/vue_components/common/basic_tooltip'

export default {
  name: 'EntryTypeListItem',

  components: { BasicTooltip },

  props: {
    entryType: {
      type: Object,
      required: true,
    },

    readonly: Boolean,
  },

  methods: {
    tooltipEntryType (entryType) {
      return `${entryType.title} ${this.$filters.currency(entryType.price)}`
    },

    tooltipTipsEntryType (entryType) {
      const additional = entryType.additional ? `<span>${entryType.additional}.</span>` : ''
      const tip = entryType.tip ? `<span>${entryType.tip}.</span>` : ''

      return additional + tip
    },
  },
}
</script>
