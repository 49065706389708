<template>
  <orders
    ref="report"
    :is-hidden="isHidden"
    v-bind="filters"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getFilters } from '@/specific/reports/orders'
import Orders from '@/vue_components/reports/OrdersReport/orders.vue'
import ReportWrapper from '@/vue_components/mixins/reportWrapper'

export default defineComponent({
  name: 'OrdersReportApp',

  components: { Orders },

  mixins: [ReportWrapper],

  data () {
    return {
      filters: getFilters(),
    }
  },
})
</script>
