<template>
  <si v-if="!isHidden">
    <div class="report-subtitle cut">
      {{ subtitle }}
    </div>
    <epic-spinner :visibility="isLoading" />
    <si-table
      ref="table"
      horizontal
    >
      <template #header>
        <si-field>
          <si-column
            :name="header.number.sortable"
            :active="sortableColumn"
            class="number"
            @sort-change="onSortChange"
          >
            {{ header.number.text }}
          </si-column>
          <si-column
            :name="header.date.sortable"
            :active="sortableColumn"
            class="datetime"
            @sort-change="onSortChange"
          >
            {{ header.date.text }}
          </si-column>
          <si-column
            :name="header.type.sortable"
            :active="sortableColumn"
            class="type"
            @sort-change="onSortChange"
          >
            {{ header.type.text }}
          </si-column>
          <si-column
            :name="header.status.sortable"
            :active="sortableColumn"
            class="status"
            @sort-change="onSortChange"
          >
            {{ header.status.text }}
          </si-column>
          <div class="short_name cut">
            {{ header.responsible.text }}
          </div>
          <si-column
            :name="header.client.sortable"
            :active="sortableColumn"
            class="short_name cut"
            @sort-change="onSortChange"
          >
            {{ header.client.text }}
          </si-column>
          <si-column
            :name="header.phone.sortable"
            :active="sortableColumn"
            class="phone"
            @sort-change="onSortChange"
          >
            {{ header.phone.text }}
          </si-column>
          <si-column
            :name="header.duration.sortable"
            :active="sortableColumn"
            class="duration"
            @sort-change="onSortChange"
          >
            {{ header.duration.text }}
          </si-column>
          <si-column
            :name="header.result.sortable"
            :active="sortableColumn"
            class="result cut"
            @sort-change="onSortChange"
          >
            {{ header.result.text }}
          </si-column>
          <si-column
            :name="header.refuseReason.sortable"
            :active="sortableColumn"
            class="refuse_result cut"
            @sort-change="onSortChange"
          >
            {{ header.refuseReason.text }}
          </si-column>
          <si-column
            :name="header.comment.sortable"
            :active="sortableColumn"
            class="comment cut"
            @sort-change="onSortChange"
          >
            {{ header.comment.text }}
          </si-column>
          <si-column
            :name="header.attractionSource.sortable"
            :active="sortableColumn"
            class="attraction_source cut"
            @sort-change="onSortChange"
          >
            {{ header.attractionSource.text }}
          </si-column>
        </si-field>
      </template>
      <template
        v-if="rows.length"
        #body
      >
        <si-field
          v-for="(row, rowIndex) in rows"
          :key="rowIndex"
        >
          <div class="number">
            {{ row.id }}
          </div>
          <div class="datetime">
            {{ utcDateTime(row.dateTime) }}
          </div>
          <div class="type">
            <span :class="row.type.icon" />
            {{ row.type.text }}
          </div>
          <div class="status">
            <span :class="row.status.icon" />
            {{ row.status.text }}
          </div>
          <div
            :data-tooltip="responsible(row.responsibles)"
            class="short_name cut"
          >
            {{ responsible(row.responsibles) }}
          </div>
          <div
            :data-tooltip="$filters.fullName(row.client)"
            class="short_name wrap"
          >
            <a
              v-if="row.client && !row.client.deletedAt"
              :href="row.client.link"
            >
              {{ $filters.fullName(row.client) }}
            </a>
            <span
              v-else
              class="deleted"
            >
              {{ $filters.fullName(row.client) }}
            </span>
          </div>
          <div class="phone">
            {{ phone(row.phone) }}
          </div>
          <div class="duration">
            {{ duration(row.duration) }}
          </div>
          <div
            :data-tooltip="row.result"
            class="result cut"
          >
            {{ row.result }}
          </div>
          <div
            :data-tooltip="row.refuseResult"
            class="refuse_result cut"
          >
            {{ row.refuseResult }}
          </div>
          <div
            :data-tooltip="row.comment"
            class="comment cut"
          >
            {{ row.note }}
          </div>
          <div
            :data-tooltip="row.attractionSource && row.attractionSource.title"
            class="attraction_source cut"
          >
            {{ row.attractionSource && row.attractionSource.title }}
          </div>
        </si-field>
      </template>
      <template #footer>
        <si-field
          v-if="isDirty"
          class="total"
        >
          <div class="number no-clip">
            {{ footer.total }}: {{ recordsCount }}
          </div>
          <div class="datetime" />
          <div class="type" />
          <div class="status" />
          <div class="short_name cut" />
          <div class="short_name cut" />
          <div class="phone" />
          <div class="duration" />
          <div class="result cut" />
          <div class="refuse_result cut" />
          <div class="comment cut" />
          <div class="attraction_source cut" />
        </si-field>
      </template>
    </si-table>
    <si-footer v-if="rows.length">
      <pagination
        :page-count="pageCount"
        layout="prev, pager, next"
        class="hidden-print"
        @current-change="loadData"
      />
    </si-footer>
  </si>
</template>

<script>
import Pagination from '@/vue_components/common/pagination.vue'
import Report from '@/vue_components/mixins/report.js'
import HorizontalReport from '@/vue_components/mixins/horizontalReport.js'
import SortableReport from '@/vue_components/mixins/sortableReport.js'
import ReportWithTooltips from '@/vue_components/mixins/withTooltips.js'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import SiColumn from '@/vue_components/sort_items/si_table/si_column.vue'
import Si from '@/vue_components/sort_items/si.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import formatter from '@/lib/formatters/formatter'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'

export default {
  name: 'Calls',

  components: {
    Pagination,
    Si,
    SiTable,
    SiField,
    SiFooter,
    SiColumn,
    EpicSpinner,
  },

  mixins: [
    Report,
    HorizontalReport,
    SortableReport,
    ReportWithTooltips,
  ],

  props: {
    clinic: { type: Object, required: true },
    user: { type: Object, required: true },
    callType: { type: Object, required: true },
    callStatus: { type: Object, required: true },
    callResult: { type: Object, required: true },
    refuseReason: { type: Object, required: true },
    attractionSources: { type: Array, required: true },
    period: { type: String, default: '' },
  },

  data () {
    return {
      header: {
        number: {
          text: '№',
          sortable: 'callId',
        },
        date: {
          text: t('date_translate'),
          sortable: 'callDateTime',
        },
        type: {
          text: t('telephony.type'),
          sortable: 'callType',
        },
        status: {
          text: t('status'),
          sortable: 'callStatus',
        },
        responsible: {
          text: t('responsible_users'),
          sortable: 'responsibleSurnameInitials',
        },
        client: {
          text: t('client'),
          sortable: 'clientSurname',
        },
        phone: {
          text: t('phone'),
          sortable: 'callPhone',
        },
        duration: {
          text: t('duration'),
          sortable: 'callDuration',
        },
        result: {
          text: t('result'),
          sortable: 'callResult',
        },
        refuseReason: {
          text: t('telephony.refuse_result'),
          sortable: 'refuseResultTitle',
        },
        comment: {
          text: t('comment'),
          sortable: 'callNote',
        },
        attractionSource: {
          text: t('attraction_source'),
          sortable: 'attractionSourceTitle',
        },
      },
      callTypesCollection: [
        {}, //костыль т.к. нет callType с enum соответствующим 0
        {
          icon: 'fad fa-phone-alt hidden-print',
          text: t('enums.call.call_type.incoming'),
        }, {
          icon: 'fad fa-headset hidden-print',
          text: t('enums.call.call_type.outcoming'),
        },
      ],
      callStatusesCollection: [
        {}, //костыль т.к. нет callStatus с enum соответствующим 0
        {
          icon: 'fad fa-phone-plus hidden-print',
          text: t('enums.call.status.accepted'),
        }, {
          icon: 'fad fa-phone-slash hidden-print',
          text: t('enums.call.status.lost'),
        },
      ],
      callResultsCollection: [
        '',
        t('enums.call.result.in_progress'),
        t('enums.call.result.appointment_added'),
        t('enums.call.result.refused'),
        t('enums.call.result.finished'),
        t('enums.call.result.appointment_confirmed'),
        t('enums.call.result.appointment_canceled'),
        t('enums.call.result.on_hold'),
      ],
      footer: {
        total: t('reports.total_calls'),
      },
    }
  },

  computed: {
    subtitleParts () {
      return [
        this.clinic.name,
        this.period,
        this.user.name,
        this.attractionSources.map((source) => source.name),
        this.callType.name,
        this.callStatus.name,
        this.callResult.name,
        this.refuseReason.name,
      ]
    },
  },

  created () {
    this.reportClient.init({
      url: Routes.report_calls_path(),
    })
    this.requestBuilder.addStep(() => ReportFiltersFactory.defineFor('calls', this))
  },

  methods: {
    responsible (users) {
      return users.map((user) => formatter.formatValue(user, ['client:fullName'])).join(', ')
    },

    phone (phone) {
      return formatter.formatValue(phone, ['client:phone'])
    },

    utcDateTime (date) {
      return formatter.formatValue(date, ['date:utcDateTime'])
    },

    duration (date) {
      return formatter.formatValue(date, ['date:duration'])
    },

    fillTable (result) {
      this.rows = result.data.map(({ call }) => {
        call.type = this.callTypesCollection[+call.type]
        call.status = this.callStatusesCollection[+call.status]
        call.result = this.callResultsCollection[+call.result]
        call.refuseResult = call.refuseResult ? call.refuseResult.title : ''
        if (call.client) {
          call.client.link = Routes.client_path(call.client.id)
        }

        return call
      })
    },
    afterReportUpdated () {
      this.updateTooltips()
    },
  },
}
</script>
