import { MReportPresenterBase } from '@/_api/Reports/_base/MReportPresenterBase'
import {
  taxCertificatesReportAdapter,
} from '@/vue_apps/ReportsModule/TaxSertificatesReport/api/taxCertificatesReportAdapter'

export class TaxCertificatesReportPresenter extends MReportPresenterBase {
  constructor () {
    super(Routes.report_certificate_payments_path)
  }

  list (data, config = {}) {
    return super.list(data, {
      toServerAdapter: taxCertificatesReportAdapter.toServer,
      toClientAdapter: taxCertificatesReportAdapter.toClient,
      ...config,
    })
  }

  export (data: {} = {}) {
    super.export(data, t('reports.long_titles.certificate_payments'))
  }
}
