<template>
  <div class="flex-form">
    <div class="form-label">
      <slot name="title-label">
        <label for="reminder_title">
          {{ t('title') }}
          <required-mark />
        </label>
      </slot>
    </div>
    <div class="form-value">
      <slot name="title-value">
        <validation-wrapper :errors="errors.title">
          <div class="form-group reminder_title">
            <input
              id="reminder_title"
              class="form-control"
              type="text"
              autocomplete="off"
              :value="title"
              :disabled="targetedDisable.title || disabled"
              @input="$updateSync('title', $event.target.value)"
            >
          </div>
        </validation-wrapper>
      </slot>
    </div>
    <div class="form-label">
      <slot name="date-label">
        <label for="reminder_date">
          {{ t('custom_date') }}
          <required-mark />
        </label>
      </slot>
    </div>
    <div class="form-value">
      <slot name="date-value">
        <validation-wrapper :errors="errors.date">
          <div class="form-group reminder_date">
            <date-picker
              id="reminder_date"
              :date-format="GET_LOCALIZATION_DATEPICKER_FORMAT"
              class="full-width"
              :value="date"
              :disabled="targetedDisable.date || disabled"
              @input="$updateSync('date', $event ? $event.toString() : $event)"
            />
          </div>
        </validation-wrapper>
      </slot>
    </div>
    <div class="form-label">
      <slot name="type-label">
        <label for="reminder_reminder_type">
          {{ t('type') }}
          <required-mark />
        </label>
      </slot>
    </div>
    <div class="form-value f-field-container">
      <slot name="type-value">
        <validation-wrapper :errors="errors.reminder_type">
          <items-list
            id="reminder_reminder_type"
            id-item-field="id"
            displayed-item-field="title"
            :items="types"
            :max-selected-items="1"
            :min-selected-items="0"
            :multi-select="false"
            :selected-items-ids="[type]"
            :disabled="targetedDisable.type || disabled"
            @change="$updateSync('type', $event[0])"
          />
        </validation-wrapper>
      </slot>
    </div>
    <div class="form-label">
      <slot name="status-label">
        <label for="reminder_status">
          {{ t('status') }}
          <required-mark />
        </label>
      </slot>
    </div>
    <div class="form-value f-field-container">
      <slot name="status-value">
        <validation-wrapper :errors="errors.status">
          <items-list
            id="reminder_status"
            id-item-field="id"
            displayed-item-field="title"
            :items="statuses"
            :max-selected-items="1"
            :min-selected-items="0"
            :multi-select="false"
            :selected-items-ids="[status]"
            :disabled="targetedDisable.status || disabled"
            @change="$updateSync('status', $event[0])"
          />
        </validation-wrapper>
      </slot>
    </div>
    <div class="form-label">
      <slot name="client-label">
        <label for="reminder_client">
          {{ t('patient') }}
        </label>
      </slot>
    </div>
    <div class="form-value reminder-client-input">
      <slot name="client-value">
        <div class="reminder-client-container">
          <button
            id="reminder-client-actions"
            class="client-actions-modal"
            :data-client-id="client && client.id"
            :disabled="!client"
            @click="showContextMenu"
          >
            <span class="fad fa-fw fa-bars" />
          </button>
          <client-select
            :clinic-id="clinicId"
            :value="client"
            :disabled="targetedDisable.client || disabled"
            :date-format="GET_LOCALIZATION_DATE_FORMAT"
            :clearable="false"
            @change="$updateSync('client', $event)"
          />
          <span
            v-if="$security.canManageReminder || targetedDisable.client"
            class="fad fa-pencil"
            @click="$emit('enable-client')"
          />
        </div>
      </slot>
    </div>
    <div class="form-label">
      <slot name="responsible-label">
        <label for="reminder_responsibles">
          {{ t('responsible_users') }}
        </label>
      </slot>
    </div>
    <div class="form-value f-field-container">
      <slot name="responsible-value">
        <validation-wrapper :errors="errors.responsible">
          <user-simple-select
            :get-users="fetchResponsiblesList"
            :value="responsible"
            :clinic-id="clinicId"
            :disabled="targetedDisable.responsible || disabled"
            full-width
            multiple
            placeholder=""
            @change="$updateSync('responsible', $event)"
          >
            <template #prefix>
              <span />
            </template>
          </user-simple-select>
        </validation-wrapper>
      </slot>
    </div>
    <div class="form-label">
      <slot name="doctor-label">
        <label for="reminder_doctor">
          {{ t('doctor') }}
        </label>
      </slot>
    </div>
    <div class="form-value f-field-container">
      <slot name="doctor-value">
        <user-simple-select
          :value="doctor"
          :clinic-id="clinicId"
          :disabled="targetedDisable.doctor || disabled"
          full-width
          @change="$updateSync('doctor', $event)"
        />
      </slot>
    </div>
    <div class="form-label">
      <slot name="note-label">
        <label for="reminder_note">
          {{ t('additional') }}
        </label>
      </slot>
    </div>
    <div class="form-value">
      <slot name="note-value">
        <div class="form-group reminder_note">
          <el-input
            id="reminder_note"
            :value="note"
            :disabled="targetedDisable.note || disabled"
            name="reminder[note]"
            type="textarea"
            :rows="5"
            :autosize="{ minRows: 5, maxRows: 5}"
            @input="$updateSync('note', $event)"
          />
        </div>
      </slot>
    </div>
    <div class="form-label">
      <slot name="notice-label" />
    </div>
    <div class="form-value fs-appointment-creator-container">
      <slot name="notice-value">
        <div class="prompt-notice">
          {{ t('created_by') }}: {{ created }}
        </div>
        <div class="prompt-notice">
          {{ t('updated_by') }}: {{ updated }}
        </div>
      </slot>
    </div>
    <ctx-menu
      ref="contextMenu"
      in-body
    >
      <ctx-item
        :underline="true"
        @select="openInfoCard"
      >
        <template #title>
          <span class="fad fa-external-link-square ctx-icon" />
          {{ t('go_info_card') }}
        </template>
      </ctx-item>
      <ctx-item
        :underline="true"
        @select="openClientTasks"
      >
        <template #title>
          <span class="fad fa-external-link-square ctx-icon" />
          {{ t('go_client_tasks') }}
        </template>
      </ctx-item>
      <ctx-item
        :underline="true"
        @select="openNewAppointment"
      >
        <template #title>
          <span class="fad fa-external-link-square ctx-icon" />
          {{ t('make_appointment') }}
        </template>
      </ctx-item>
    </ctx-menu>
  </div>
</template>

<script>
import ItemsList from '@/vue_components/items_list'
import DatePicker from '@/vue_components/common/select/date_picker'
import props from './reminder_form_props'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import CtxMenu from '@/vue_components/common/ctx_menu/ctx_menu'
import CtxItem from '@/vue_components/common/ctx_menu/ctx_item'
import ClientSelect from '@/vue_components/common/select/client_select'
import RequiredMark from '@/vue_components/common/required_mark'
import { mapGetters } from 'vuex'
import { Intent } from '@/services/intents'
import UserSimpleSelect from '@/vue_components/common/select/UserSimpleSelect'
import { fetchResponsiblesList } from '@/vue_components/doctor_schedules/rest'

export default {
  name: 'ReminderForm',

  components: {
    UserSimpleSelect,
    RequiredMark,
    ClientSelect,
    CtxItem,
    CtxMenu,
    ValidationWrapper,
    DatePicker,
    ItemsList,
  },

  props,

  data () {
    return {
      fetchResponsiblesList,
    }
  },

  computed: {
    ...mapGetters([
      'GET_LOCALIZATION_DATE_FORMAT',
      'GET_LOCALIZATION_DATE_TIME_FORMAT',
      'GET_LOCALIZATION_DATEPICKER_FORMAT',
    ]),

    created () {
      return this.createdAt
        ? this.timestamp(this.createdBy, this.createdAt)
        : '-'
    },

    updated () {
      return this.updatedAt
        ? this.timestamp(this.updatedBy, this.updatedAt)
        : '-'
    },
  },

  methods: {
    /**
     * @param {string} user
     * @param {string} ts
     * @return {string}
     */
    timestamp (user, ts) {
      const datetime = moment(Utils.getTimezonedDateTime(ts))
        .tz(Utils.getTZByRubyTZ(gon.application.timezone))
        .format(this.GET_LOCALIZATION_DATE_TIME_FORMAT)

      return user
        ? `${user} ${datetime}`
        : datetime
    },

    showContextMenu (e) {
      this.$refs.contextMenu.open(e)
    },

    openNewAppointment () {
      if (this.client) {
        const newWindow = window.open(Routes.doctor_schedules_path(), '_blank')
        Intent.setIntent(
          newWindow,
          Intent.SCHEDULE_APPOINTMENT_CREATE,
          { clientId: this.clientId }
        )
      }
    },

    openClientTasks () {
      if (this.client) {
        window.open(Routes.reminders_client_path(this.client.id), '_blank')
      }
    },

    openInfoCard () {
      if (this.client) {
        window.open(Routes.client_path(this.client.id), '_blank')
      }
    },
  },
}
</script>
