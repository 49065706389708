<template>
  <div
    :id="elementId"
    class="treatment-plan-clinics-app"
    style="width: 200px"
  >
    <input
      type="hidden"
      name="treatment_plan[clinic_id]"
      :value="clinicId"
    >
    <m-select
      v-model="clinicId"
      class="treatment-plan__clinic"
      :items="vxGetDoctorClinics"
      :m-fixed-height="false"
      :placeholder="t('enums.unspecified.it')"
      label=""
      use-custom-result="simple"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'

export default defineComponent({
  name: 'TreatmentPlanClinicsApp',

  components: {
    MSelect,
  },

  props: {
    elementId: { type: String, required: true },
  },

  data () {
    return {
      clinicId: gon.specific.treatment_plan.clinic_id,
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      vxGetDoctorClinics: 'vxGetDoctorClinics',
    }),
  },
})
</script>
