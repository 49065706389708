import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import {
  IDoctorAppointmentsReportToClientDTO,
} from '@/vue_apps/ReportsModule/DoctorAppointments/interfaces/IDoctorAppointmentsReportToClientDTO'
import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('reports.doctorAppointments')

export class DoctorAppointmentsReportTotalItemCell extends MListServiceItem {
  dateCell: MTableCell

  constructor (data: IDoctorAppointmentsReportToClientDTO) {
    super()
    this.dateCell = new MTableCell([
      this.getTotalRecords(data),
      this.getDuration(data),
      this.getUniqueClients(data),
    ].join(', '))
      .addClasses('position-absolute')
  }

  private getTotalRecords (data: IDoctorAppointmentsReportToClientDTO) {
    return `${ts('totalRecords')} ${data.count}`
  }

  private getDuration (data: IDoctorAppointmentsReportToClientDTO) {
    const durationHours = Math.trunc(data.totalDuration / 60)
    const durationMinutes = data.totalDuration % 60

    return [
      t('duration').toLowerCase(),
      durationHours,
      ts('hours'),
      durationMinutes,
      ts('minutes'),
    ].join(' ')
  }

  private getUniqueClients (data: IDoctorAppointmentsReportToClientDTO) {
    return `${ts('clients')} ${data.uniqueClients}`
  }
}
