import applicationFactory from '../plugins/dynamic_forms/application_factory'
import {snilsAddMask, subscribeToSnilsValidationError} from '@/specific/clients/methods/snils'
import {registerEntryTypesBreadcrumbsSub} from '@/specific/entry_types_helpers/entryTypesSubs'
import {createPerformerApp} from '@/vue_apps/TreatmentPlans/PerformerApp'
import {createEntryTypeSearchAdd} from '@/vue_present/Reuse/EntryTypes/EntryTypesSearchAdd'
import {createEntryTypesTreeApp} from '@/vue_present/Reuse/EntryTypes/EntryTypesTree'
import {createResponsibleApp} from '@/vue_apps/TreatmentPlans/ResponsibleApp'
import {tpAddEntryTypeCallback} from '@/specific/treatmentPlans/tpAddEntryTypeCallback'
import {tpAddEntryPackCallback} from '@/specific/treatmentPlans/tpAddEntryPackCallback'
import {
  createPersonalDataEditorPositionAppRoot,
} from '@/vue_apps/ClientsModule/components/PersonalData/PersonalDataEditor/PersonalDataEditorPositionApp'
import {subscribeToLaboratoryField} from '@/specific/entry_types_helpers/field_enable_check'
import {createTreatmentPlanClinicsApp} from '@/vue_apps/TreatmentPlans/TreatmentPlanClinicsApp'

import {
  createPersonalDataEditorSendDataToEgiszApp,
} from '@/vue_apps/ClientsModule/components/PersonalData/PersonalDataEditor/PersonalDataEditorSendDataToEgisz'

$document.on('rez/treatment_plans/new', function () {
  const TreatmentPlan = FormFactory.build('treatment_plan')
  TreatmentPlan.newItem()
})

$document.on('rez/treatment_plans/edit', function () {
  const TreatmentPlan = FormFactory.build('treatment_plan')
  TreatmentPlan.editItem()
})

$document.on('rez/treatment_plans/show', function () {
  $('.tooth-statable').on('click', function () {
    Notificator.info(t('change_dental_card_click_edit_button'))
  })

  const $delete = $('#delete-record')

  $delete.deleteButton({
    url: Routes.treatment_plan_path(gon.specific.treatment_plan.id),
    redirect: Routes.treatment_plans_path(),
    name: t('treatment_plan'),
  })

  const dentalCardBefore = gon.specific.dental_card_before_attributes
  const $clinicalPictureContainer = $('#clinical-picture-container')
  const map = {}
  $clinicalPictureContainer.find('.normal').addClass('hidden')

  $.each(dentalCardBefore, function (tooth, values) {
    const arr = _getProblems(values)

    arr.forEach(function (problem) {
      if (!map[problem]) map[problem] = []
      map[problem].push(tooth)
    })
  })

  $.each(map, function (problem, toothIds) {
    map[problem] = _.uniq(toothIds)
  })

  $.each(map, function (problem, toothIds) {
    $clinicalPictureContainer
      .find('.' + problem)
      .find('.tooth-state-title')
      .append(' ')
      .append(toothIds.toString().split(',').join(',  '))
  })

  function _getProblems (obj) {
    const list = [obj.main, obj.top, obj.bottom, obj.center, obj.left, obj.right]

    return list.filter(function (item) { return item !== '' })
  }
})

//
// stomatology
//

$document.on('rez/treatment_plans/new ' +
             'rez/treatment_plans/edit ' +
             'rez/treatment_plans/show', function () {
  /* #g760a8 OrderDiscountVue, если перейти из редактирования счета в планы лечения,
   * то OrderDiscountVue останется и приведет к ошибке открытия модалки
   */
  if (Utils.OrderDiscountVue) { Utils.OrderDiscountVue = null }

  $('#select_card_teeth_kind_teeth-map-before').milkChecker({
    container: $('#teeth-map-before').find('.dental-card'),
  })

  $('#select_card_teeth_kind_teeth-map-after').milkChecker({
    container: $('#teeth-map-after').find('.dental-card'),
  })
})

$document.on('rez/treatment_plans/new rez/treatment_plans/edit', function () {
  adjustHeight()
  $(window).on('resize', function () {
    adjustHeight()
  })

  function adjustHeight () {
    $('#catalog_list ul.fancytree-container').height($('.app-content').height() - 120)
  }
  applicationFactory.generate('backend')

  snilsAddMask('.client-snils')
  subscribeToSnilsValidationError()
  registerEntryTypesBreadcrumbsSub()

  createPerformerApp(undefined, gon.page.action === 'new')
  createTreatmentPlanClinicsApp()
  createResponsibleApp()

  createEntryTypeSearchAdd(undefined, {
    entryTypeLegacyCallback: tpAddEntryTypeCallback,
    entryPackLegacyCallback: tpAddEntryPackCallback,
  })

  // Для того, чтобы при нажатии клавиши Enter форма не отправлялась
  document
    .querySelector('#treatment_plan_form .entry-types-search-add__input input')
    ?.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') { event.preventDefault() }
    })

  createEntryTypesTreeApp(undefined, 'page.form.treatment_plan.treatmentPlanList.askAddEntry')
  createPersonalDataEditorPositionAppRoot('#form_client_modal')
  createPersonalDataEditorSendDataToEgiszApp('#form_client_modal')
  setTimeout(subscribeToLaboratoryField, 0)
})

$document.on('rez/treatment_plans/show', function () {
  if (!gon.application.show_performer_in_treatment_plans) { return }
  const { shortName } = gon.specific.treatment_plan_user || {}
  $('.performer-short-name-crutch').text(shortName || '-')
})
