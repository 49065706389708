import { getReport } from '@/specific/reports/procedures/getReport'
import { createFiltersProfilesAppForLegacy } from '@/vue_apps/FiltersProfilesApp'
import { FILTERS_PROFILES_TYPES } from '@/vue_apps/FiltersProfilesApp/const/consts'
import { saveProceduresReportInitialFilters as saveInitialFilters } from './procedures/initialFilters'
import { proceduresReportFiltersAccessors as filtersAccessor } from './procedures/filtersAccessors'
import {
  removeFilterValidationWrapper,
  showFiltersErrors,
} from '@/vue_apps/FiltersProfilesApp/helpers/legacyErrorsRenderingHelpers'
import { proceduresReportFiltersSelectors as filtersSelectors } from './procedures/filtersSelectors'
import { createVueApp } from '@/vue_components/create_vue_app'
import ProceduresReportApp from '@/vue_components/reports/ProceduresReport/ProceduresReportApp.vue'

$document.on('rez/reports/procedures', function () {
  Report.init()
  saveInitialFilters()

  $('#report_form').on('change', removeFilterValidationWrapper)

  createFiltersProfilesAppForLegacy({
    filtersSetter ({ filters, errors }) {
      filtersAccessor.set(filters)
      showFiltersErrors(errors, filtersSelectors)
    },
    filtersGetter: filtersAccessor.get,
    profileType: FILTERS_PROFILES_TYPES.PROCEDURES,
  })

  const reportTable = createVueApp({
    el: '#report_right_container',
    name: 'ProceduresReportAppRoot',
    render: (h) => h(ProceduresReportApp),
    useChildren: true,
  })

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(filtersAccessor.get())
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
  })

  $('.export').on('click', getReport)
})
