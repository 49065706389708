import { defineDateFormat, fetchExportFile } from './export.js'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'
import { createFiltersProfilesAppForLegacy } from '@/vue_apps/FiltersProfilesApp'
import { FILTERS_PROFILES_TYPES } from '@/vue_apps/FiltersProfilesApp/const/consts'
import { MAX_LIMIT } from './const.js'
import { insuranceCompaniesReportFiltersAccessors as filtersAccessor } from './insuranceCompanies/filtersAccessors'
import { insuranceCompaniesReportFiltersSelectors as filtersSelectors } from './insuranceCompanies/filtersSelectors'
import {
  removeFilterValidationWrapper,
  showFiltersErrors,
} from '@/vue_apps/FiltersProfilesApp/helpers/legacyErrorsRenderingHelpers'
import { saveInsuranceCompaniesReportInitialFilters as saveInitialFilters } from './insuranceCompanies/initialFilters'
import { createVueApp } from '@/vue_components/create_vue_app'
import InsuranceCompaniesApp from '@/vue_components/reports/InsuranceCompaniesReport/InsuranceCompaniesApp.vue'

$document.on('rez/reports/insurance_companies', function () {
  Report.init()
  saveInitialFilters()

  $('#report_form').on('change', removeFilterValidationWrapper)

  createFiltersProfilesAppForLegacy({
    filtersSetter ({ filters, errors }) {
      filtersAccessor.set(filters)
      showFiltersErrors(errors, filtersSelectors)
    },
    filtersGetter: filtersAccessor.get,
    profileType: FILTERS_PROFILES_TYPES.INSURANCE_COMPANIES,
  })

  const stomatologyEnabled = gon.specific.stomatologyEnabled

  const tableHeader = {
    number: '№',
    fullName: t('full_name'),
    surname: t('surname'),
    firstName: t('name'),
    secondName: t('second_name'),
    birthday: t('birthday'),
    idCard: t('id_card'),
    address: t('reports.address'),
    card: t('card'),
    police: t('police'),
    date: t('custom_date'),
    orderId: t('order'),
    code: t('code'),
    service: t('service'),
    price: t('price'),
    discountedPrice: t('discount_price'),
    amount: t('amount'),
    sum: t('sum'),
    mkb: t('mkb'),
    diagnoses: t('diagnoses'),
    conclusions: t('conclusions'),
    guaranteeLetterNumber: t('finance.guarantee_letter_number_shortly'),
    guaranteeLetterDate: t('finance.guarantee_letter_issue_date_shortly'),
    doctor: t('doctor'),
    specialty: t('specialty'),
    doctorCode: t('doctor_code'),
    insuranceCompany: t('insurance_company'),
    insured: t('insurant'),
    teethNumbers: t('teeth_numbers'),
  }

  const reportTable = createVueApp({
    el: '#report_right_container',
    name: 'InsuranceCompaniesAppRoot',
    render: (h) => h(InsuranceCompaniesApp),
    useChildren: true,
  })

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(filtersAccessor.get())
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
  })

  $('.export').on('click', function () {
    const filters = filtersAccessor.get()

    const data = {
      buildSettings: {
        usePatientCardNumber: false,
      },
      dateFormat: defineDateFormat(),
      csvComma: gon.application.csv_character,
      floatingComma: gon.localization.currency.decimal_character,
      headerRow: {
        number: tableHeader.number,
        clientFullName: tableHeader.fullName,
        clientSurname: tableHeader.surname,
        clientName: tableHeader.firstName,
        clientSecondName: tableHeader.secondName,
        clientBirthDate: tableHeader.birthday,
        idCard: tableHeader.idCard,
        address: tableHeader.address,
        patientCardNumber: tableHeader.card,
        medicalPolicyNumber: tableHeader.police,
        entryDate: tableHeader.date,
        orderId: tableHeader.orderId,
        entryTypeNumber: tableHeader.code,
        entryTitle: tableHeader.service,
        entrySum: tableHeader.price,
        entryFinalSum: tableHeader.discountedPrice,
        entryAmount: tableHeader.amount,
        entryPaidSum: tableHeader.sum,
        diagnosesCodes: tableHeader.mkb,
        diagnoses: tableHeader.diagnoses,
        conclusions: tableHeader.conclusions,
        financeGuaranteeLetterNumber: tableHeader.guaranteeLetterNumber,
        financeGuaranteeLetterIssueDate: tableHeader.guaranteeLetterDate,
        doctorInitials: tableHeader.doctor,
        doctorSpecialties: tableHeader.specialty,
        doctorCode: tableHeader.doctorCode,
        insuranceCompany: tableHeader.insuranceCompany,
        company: tableHeader.insured,
      },
      totalRow: {
        total: t('total'),
      },
      clientTotalRow: {
        total: t('total'),
      },
      offset: 0,
      limit: MAX_LIMIT,
      report: {
        period: filters.period,
      },
      ...ReportFiltersFactory.defineFor('insuranceCompanies', filters),
    }
    if (stomatologyEnabled) {
      data.headerRow.teeth = tableHeader.teethNumbers
    }

    fetchExportFile(data, Routes.report_insurance_companies_path(), t('insurance_companies'))
  })

  $(filtersSelectors.insuranceCompany).select2()
})
