<!--TODO: удалить когда будет реализовываться https://app.clickup.com/t/djz9et 4 / 4-->
<template>
  <discount-master
    ref="dm"
    :initial-rounded-prices="initialRoundedPrices"
    @ready="resolveReady"
  >
    <template #discount-history>
      <span />
    </template>
  </discount-master>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { AsyncFlag } from '@/lib/async_flag/async_flag'
import DiscountMaster from '@/vue_components/order/discount/discount_master.vue'

export default defineComponent({
  name: 'DiscountMasterApp',

  components: { DiscountMaster },

  data () {
    return {
      discountMasterReady: new AsyncFlag(),
      initialRoundedPrices: gon.specific.order.rounded_prices,
    }
  },

  methods: {
    setField (fieldName, value) {
      this.invokeDiscountMasterMethod('setField', fieldName, value)
    },

    setData (payload = null) {
      this.invokeDiscountMasterMethod('setData', payload)
    },

    getData () {
      return this.invokeDiscountMasterMethod('getData')
    },

    onClose () {
      this.invokeDiscountMasterMethod('onClose')
    },

    invokeDiscountMasterMethod (name, ...args) {
      return this.$refs.dm && this.$refs.dm[name](...args)
    },

    saveMassDiscount () {
      this.$refs.dm.saveMassDiscount()
    },

    waitForDiscountMaster () {
      return this.discountMasterReady.waitAndReset()
    },

    resolveReady () {
      this.discountMasterReady.setReady()
    },
  },
})
</script>
