<template>
  <payments
    ref="report"
    :is-hidden="isHidden"
    v-bind="filters"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getFilters } from '@/specific/reports/payments/getFilters'
import Payments from '@/vue_components/reports/PaymentsReport/PaymentsReport.vue'
import ReportWrapper from '@/vue_components/mixins/reportWrapper'

export default defineComponent({
  name: 'PaymentsReportApp',

  components: { Payments },

  mixins: [ReportWrapper],

  data () {
    return {
      filters: getFilters(),
    }
  },
})
</script>
