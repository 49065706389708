<template>
  <div class="discount-history">
    <div
      v-if="isAllEntries"
      class="discount-history__filters"
    >
      <span>{{ t('history_entries_filter') }}</span>
      <el-select
        class="filters__entry-filter"
        :value="entryId"
        @change="$emit('update:entryId', $event)"
      >
        <el-option
          :label="t('select_all')"
          :value="0"
        />
        <el-option
          v-for="(item, index) in entriesArray"
          :key="index"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>

    <el-timeline
      v-if="filteredHistory.length"
      class="discount-history__timeline"
      :reverse="true"
    >
      <el-timeline-item
        v-for="(item, index) in filteredHistory"
        :key="index"
        :timestamp="item.discount_created_at"
        placement="top"
        :color="getNodeColor(item)"
        :icon="getNodeIcon(item)"
      >
        <el-card class="tl-item">
          <table class="tl-item__tl-info">
            <tr>
              <td class="tl-info__label">
                {{ t('discount_entry_span') }}
              </td>
              <td class="tl-info__value">
                {{ item.entry_title }}
              </td>
            </tr>

            <tr>
              <td class="tl-info__label">
                {{ t('discount_reason_span') }}
              </td>
              <td class="tl-info__value">
                {{ item.discount_reason_title || '-' }}
              </td>
            </tr>

            <tr>
              <td class="tl-info__label">
                {{ t('discount_value_span') }}
              </td>
              <td class="tl-info__value">
                {{ item.discount_value + discountTypeObj[item.discount_type] }}
                <span
                  class="tl-info__value-result"
                  :style="{color: getNodeColor(item)}"
                >
                  {{ item.discount_sum_before }}{{ discountTypeObj[DISCOUNT_TYPES.CURRENCY] }}
                  <i class="fad fa-fw fa-arrow-right" />
                  {{ item.discount_sum_after }}{{ discountTypeObj[DISCOUNT_TYPES.CURRENCY] }}
                </span>
              </td>
            </tr>
          </table>

          <div class="tl-item__tl-user">
            <a
              :href="getUserRoute(item.discount_issuer_id)"
              class="tl-user__link"
            >
              <span class="tl-user__user-name">{{ item.discount_issuer_name }}</span>
              <user-avatar
                class="tl-user__user-avatar"
                :user-id="item.discount_issuer_id"
                :params="{version: 'thumb25'}"
              />
            </a>
          </div>
        </el-card>
      </el-timeline-item>
    </el-timeline>
    <span
      v-else
      class="prompt-notice"
    >{{ t('nothing_has_found') }}</span>
  </div>
</template>

<script>
import props from './discount_history_props'
import UserAvatar from '@/vue_components/user_avatar'
import { DISCOUNT_TYPES } from '@/vue_components/order/discount/const'

export default {
  name: 'DiscountHistory',
  components: { UserAvatar },
  props,

  data () {
    return {
      DISCOUNT_TYPES,
    }
  },

  computed: {
    filteredHistory () {
      if (!this.entryTitle && !this.entryId) { return this.historyArray }

      if (this.entryTitle && !this.entryId) { return [] }

      return this.historyArray.filter((item) => item.entry_id === this.entryId)
    },

    entriesArray () {
      const entriesObj = {}
      this.historyArray.forEach((item) => {
        entriesObj[`${item.entry_id}: ${item.entry_title}`] = item.entry_id
      })

      return Object.keys(entriesObj)
        .map((key) => ({ label: key, value: entriesObj[key] }))
        .sort((a, b) => a.value - b.value)
    },
  },

  methods: {
    getNodeColor (historyItem) {
      if (+historyItem.discount_sum_before > +historyItem.discount_sum_after) {
        return '#8BC34A'
      }

      return '#ff5722'
    },

    getNodeIcon (historyItem) {
      if (+historyItem.discount_sum_before > +historyItem.discount_sum_after) {
        return 'fad fa-arrow-alt-circle-up'
      }

      return 'fad fa-arrow-alt-circle-down'
    },

    getUserRoute (id) {
      return Routes.user_path(id)
    },
  },
}
</script>
