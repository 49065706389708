import DoctorSchedules from '@/vue_components/doctor_schedules/doctor_schedules.vue'
import { createVueApp } from '@/vue_components/create_vue_app'

$document.on('rez/doctor_schedules/index', function () {
  createVueApp({
    el: '#app',
    name: 'DoctorSchedules',
    beforeCreate () {
      this.$store.dispatch('initialScheduleStore')
      this.$store.dispatch('loadInitialDataSchedule', this.$store.getters.GET_SCHEDULE_CURRENT_CLINIC_ID)
      this.$store.dispatch('loadAttractionSources')
    },
    render: (h) => h(DoctorSchedules),
  })
})
