import { defineDateFormat, fetchExportFile } from './export.js'
import { ReportParamFactory } from '@/specific/reports/shared'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'
import { createVueApp } from '@/vue_components/create_vue_app'
import CallsReport from '@/vue_components/reports/CallsReport/CallsReport.vue'

$document.on('rez/reports/calls', function () {
  Report.init()

  const tableHeader = [
    { title: '№' },
    { title: t('date_translate') },
    { title: t('telephony.type') },
    { title: t('status') },
    { title: t('responsible_users') },
    { title: t('client') },
    { title: t('phone') },
    { title: t('duration') },
    { title: t('result') },
    { title: t('telephony.refuse_result') },
    { title: t('comment') },
    { title: t('attraction_source') },
  ]

  const reportTable = createVueApp({
    el: '#report_right_container',
    name: 'CallsReportApp',
    render: (h) => h(CallsReport),
    useChildren: true,
  })

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(getFilters())
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
  })

  $('.export').on('click', function () {
    const filters = getFilters()

    const data = {
      dateTimeFormat: `${defineDateFormat()}, 15:04`,
      csvComma: gon.application.csv_character,
      headerRow: {
        callId: tableHeader[0].title,
        callDateTime: tableHeader[1].title,
        callType: tableHeader[2].title,
        callStatus: tableHeader[3].title,
        responsibleSurnameInitials: tableHeader[4].title,
        clientFullName: tableHeader[5].title,
        callPhone: tableHeader[6].title,
        callDuration: tableHeader[7].title,
        callResult: tableHeader[8].title,
        refuseResultTitle: tableHeader[9].title,
        callNote: tableHeader[10].title,
        attractionSourceTitle: tableHeader[11].title,
      },
      callType: {
        incoming: t('enums.call.call_type.incoming'),
        outcoming: t('enums.call.call_type.outcoming'),
      },
      callStatus: {
        accepted: t('enums.call.status.accepted'),
        lost: t('enums.call.status.lost'),
      },
      callResult: {
        inProgress: t('enums.call.result.in_progress'),
        appointmentAdded: t('enums.call.result.appointment_added'),
        refused: t('enums.call.result.refused'),
        finished: t('enums.call.result.finished'),
        appointmentConfirmed: t('enums.call.result.appointment_confirmed'),
        appointmentCanceled: t('enums.call.result.appointment_canceled'),
        onHold: t('enums.call.result.on_hold'),
      },
      offset: 0,
      limit: -(1 << 32 - 1),
      report: {
        period: filters.period,
      },
      ...ReportFiltersFactory.defineFor('calls', filters),
    }

    fetchExportFile(data, Routes.report_calls_path(), T.telephony.calls)
  })
})

export const getFilters = () => {
  return {
    clinic: ReportParamFactory.getSelectParam('#report_clinic_id'),
    period: ReportParamFactory.getRawParam('.period'),
    user: ReportParamFactory.getSelectParam('#report_user_id'),
    attractionSources: ReportParamFactory.getSelect2Params('#report_attraction_source_ids'),
    callType: ReportParamFactory.getButtonsParam('#report_call_type_id'),
    callStatus: ReportParamFactory.getButtonsParam('#report_call_status_id'),
    callResult: ReportParamFactory.getSelectParam('#report_call_result_id'),
    refuseReason: ReportParamFactory.getSelectParam('#report_refuse_result_id'),
  }
}
