<template>
  <div
    id="entry_type_tree"
    class="panel panel-primary panel-inner"
  >
    <panel-heading
      :title="panelTitle"
      icon="fad fa-list-alt"
    >
      <slot name="mode-select" />
      <div
        id="tree_container"
        class="panel-body"
      >
        <el-tree
          ref="entryTypeTree"
          :load="loadNode"
          :props="defaultProps"
          :empty-text="emptyText"
          :highlight-current="true"
          :accordion="true"
          lazy
          @node-expand="updateTooltips"
          @node-click="handleNodeClick"
        >
          <span
            slot-scope="{ node, data }"
            class="custom-tree-node"
          >
            <span :class="[ data.leaf ? 'fad fa-flask' : 'fad fa-folder' ]" />
            <span
              :title="node.label"
              class="cut tooltip-bottom"
            >{{ node.label }}</span>
          </span>
        </el-tree>
      </div>
    </panel-heading>
  </div>
</template>
<script>
import PanelHeading from '../../common/panel_heading'

export default {
  name: 'EntryTypeTree',
  components: {
    PanelHeading,
  },

  props: {
    loadNode: {
      default: undefined,
      type: Function,
    },
    emptyText: {
      default: undefined,
      type: String,
    },
    panelTitle: {
      default: undefined,
      type: String,
    },
  },

  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'title',
        isLeaf: 'leaf',
      },
    }
  },

  mounted () {
    this.$emit('mounted')
  },

  methods: {
    handleNodeClick (node) {
      if (node.leaf) {
        this.$emit('entry-type-selected', node)
      }

      this.updateTooltips()
    },

    updateTooltips () {
      if (!this.$refs.entryTypeTree?.$el) { return }

      Utils.updateTooltips(this.$refs.entryTypeTree.$el)
    },
  },
}
</script>
