import { defineDateFormat, fetchExportFile } from './export.js'
import { ReportParamFactory } from '@/specific/reports/shared'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'
import { createVueApp } from '@/vue_components/create_vue_app'
import EntriesReportApp from '@/vue_components/reports/EntriesReport/EntriesReportApp.vue'

$document.on('rez/reports/entries', function () {
  Report.init()

  const reportTable = createVueApp({
    el: '#report_right_container',
    render: (h) => h(EntriesReportApp),
    useChildren: true,
  })

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(getFilters())
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
  })

  const header = {
    entryTitle: t('title'),
    entryAmount: t('amount'),
    entrySum: `${t('sum')} (${Utils.currencySign()})`,
    entryFinalSum: `${t('to_pay')} (${Utils.currencySign()})`,
    entrySumPercent: t('to_pay_procent'),
  }
  $('.export').on('click', function () {
    const filters = getFilters()

    const data = {
      dateFormat: defineDateFormat(),
      csvComma: gon.application.csv_character,
      floatingComma: gon.localization.currency.decimal_character,
      headerRow: header,
      totalRow: {
        total: t('total'),
      },
      offset: 0,
      limit: -(1 << 32 - 1),
      report: {
        period: filters.period,
      },
      ...ReportFiltersFactory.defineFor('entries', filters),
    }

    if (filters.assistant) {
      data.assistantIds = ReportParamFactory.buildArrayParam(filters.assistant)
    }

    fetchExportFile(data, Routes.report_entries_path(), t('reports.short_titles.entries'))
  })
})

export const getFilters = () => {
  const filters = {
    period: ReportParamFactory.getRawParam('.period'),
    clinic: ReportParamFactory.getSelectParam('#report_clinic_id'),
    reportKind: ReportParamFactory.getButtonsParam('#report_kind'),
    machines: ReportParamFactory.getSelect2Params('#report_machine_ids'),
    user: ReportParamFactory.getSelectParam('#report_user_id'),
    reportCategory: ReportParamFactory.getSelectParam('#report_category_id'),
  }

  if ($('#report_analysis_laboratory_id').length) {
    filters.laboratory = ReportParamFactory.getSelectParam('#report_analysis_laboratory_id')
  }
  // check if assistants are enabled
  if ($('#report_assistant_id').length) {
    filters.assistant = ReportParamFactory.getSelectParam('#report_assistant_id')
  }

  return filters
}
