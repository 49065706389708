<template>
  <div
    ref="entryList"
    class="entry-types__list"
  >
    <template v-if="entryTypesArray.length > 0">
      <entry-types-list-item
        v-for="(item, index) in entryTypesArray"
        :key="index"
        :entry-type="item"
        :readonly="readonly"
        @entryRemove="$emit('entryRemove', $event)"
      />
    </template>
    <span
      v-else
      class="prompt-notice"
    >
      {{ t('empty_selected_entry_services') }}
    </span>

    <div
      v-if="showTotal"
      class="entry-types__list-total"
    >
      {{ t('total') }}: {{ $filters.currency(totalSum) }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm'

import EntryTypesListItem from '@/vue_components/common/entry_types/entry_types_list_item.vue'

export default {
  name: 'AppointmentEntryTypes',

  components: {
    EntryTypesListItem,
  },

  props: {
    entryTypesArray: {
      type: Array,
      default: () => [],
    },

    currencySymbol: {
      type: String,
      required: true,
    },

    showTotal: {
      type: Boolean,
      default: true,
    },

    currencyFormat: {
      type: Object,
      required: true,
    },

    readonly: Boolean,
  },

  computed: {
    totalSum () {
      if (this.entryTypesArray && this.entryTypesArray.length) {
        return this.entryTypesArray
          .reduce((acc, curr) => acc + +curr.price, 0)
          .toFixed(2)
      }

      return 0
    },
  },

  watch: {
    entryTypesArray () {
      Vue.nextTick()
        .then(() => { Utils.updateTooltipsInContainer(this.$refs.entryList) })
    },
  },
}
</script>
