<template>
  <tr class="similar-clients-list-entry">
    <td class="similar-clients-list-fullname">
      {{ client.fullname }}
    </td>
    <td
      class="similar-clients-list-phone"
    >
      {{ $filters.clientPhone(client) }}
    </td>
    <td
      class="similar-clients-list-birthdate"
    >
      {{ $filters.date(client.birthdate) }}
    </td>
  </tr>
</template>
<script>

/**
 * @typedef {{
 *   id: number,
 *   fullname: string,
 *   birthdate: string|null,
 *   formatted_phone: string|null,
 *   masked_phone: string|null
 * }} SimilarClient
 */
export default {
  name: 'SimilarClientsEntry',

  props: {
    /**
     * @type {import('vue').PropOptions<SimilarClient>}
     */
    client: {
      type: Object,
      required: true,
    },
  },
}
</script>
