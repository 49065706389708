<template>
  <calls
    ref="report"
    :is-hidden="isHidden"
    :clinic="filters.clinic"
    :period="filters.period"
    :user="filters.user"
    :attraction-sources="filters.attractionSources"
    :call-type="filters.callType"
    :call-status="filters.callStatus"
    :call-result="filters.callResult"
    :refuse-reason="filters.refuseReason"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Calls from '@/vue_components/reports/CallsReport/Calls.vue'
import ReportWrapper from '@/vue_components/mixins/reportWrapper'
import { getFilters } from '@/specific/reports/calls'

export default defineComponent({
  name: 'CallsReport',

  components: { Calls },

  mixins: [ReportWrapper],

  data () {
    return {
      filters: getFilters(),
    }
  },
})
</script>
