<template>
  <div
    v-if="visibility"
    class="doctor-areas__waiting-list-container"
  >
    <waiting-list
      :preset="preset"
      :current-user-id="currentUserId"
      :visibility="visibility"
      :waiting-list-count="waitingListCount"
      @count-change="loadWaitingListCount"
      @filters-change="loadWaitingListCount"
      @create-waiting-item="openModal(MODAL_TYPES.CREATE_WAITING_ITEM)"
      @edit-waiting-item="openEditWaitingItemForm"
    />

    <waiting-create-modal
      v-if="modal.createWaitingItem.visibility"
      :visibility="modal.createWaitingItem.visibility"
      :date="GET_SCHEDULE_DATE"
      :client-groups="clientGroups"
      @close="closeModal(MODAL_TYPES.CREATE_WAITING_ITEM)"
      @add-legal-representative="openModal(MODAL_TYPES.LEGAL_REPRESENTATIVE)"
    />

    <waiting-edit-modal
      v-if="modal.editWaitingItem.visibility"
      ref="editWaitingItem"
      :visibility="modal.editWaitingItem.visibility"
      :client-groups="clientGroups"
      @close="closeModal(MODAL_TYPES.EDIT_WAITING_ITEM)"
      @add-legal-representative="openModal(MODAL_TYPES.LEGAL_REPRESENTATIVE)"
    />

    <create-legal-representative-modal
      v-if="modal.legal.visibility"
      :client-groups="clientGroups"
      :document-types="documentTypes"
      @add-company="openModal(MODAL_TYPES.COMPANY)"
      @close="closeModal(MODAL_TYPES.LEGAL_REPRESENTATIVE)"
      @create="addLegalRepresentative"
    />

    <company-create-modal
      v-if="modal.company.visibility"
      :company-types="modal.company.companyTypes"
      @close="closeModal(MODAL_TYPES.COMPANY)"
      @create="setCreatedCompanyData"
    />

    <alert-modal
      v-if="modal.alert.visibility"
      v-bind="alertProps"
      modal-class="doctor-schedules-alert-modal"
      @ok="hideAlert"
      @close="hideAlert"
    />

    <confirmation-modal
      v-if="modal.confirmation.visibility"
      modal-class="doctor-schedules-confirmation-modal"
      v-bind="confirmationProps"
      @yes="confirmationYesHandler"
      @no="confirmationNoHandler"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import WaitingList from '@/vue_components/doctor_schedules/waiting_list.vue'
import WaitingCreateModal from '@/vue_components/doctor_schedules/components/waiting_create_modal.vue'
import WaitingEditModal from '@/vue_components/doctor_schedules/components/waiting_edit_modal.vue'
import CreateLegalRepresentativeModal from '@/vue_components/common/client/create_legal_representative_modal.vue'
import CompanyCreateModal from '@/vue_components/companies/components/company_create_modal.vue'
import { ModalHolder } from '@/vue_components/mixins/modals/modal_holder'
import { AlertHolder } from '@/vue_components/mixins/modals/alert_holder'
import { ConfirmationHolder } from '@/vue_components/mixins/modals/confirmation_holder'
import { MODAL_TYPES } from '@/vue_components/doctor_schedules/consts'
import { mapGetters } from 'vuex'
import { doctorAreasEndpoint } from '@/specific/doctor_areas/doctorAreasEndpoint'
import { fetchWaitingListCount } from '@/vue_components/doctor_schedules/rest'
import { companiesEndpoint } from '@/vue_components/companies/rest'

export default defineComponent({
  name: 'DoctorAreasWaitingList',

  components: {
    WaitingList,
    WaitingCreateModal,
    WaitingEditModal,
    CreateLegalRepresentativeModal,
    CompanyCreateModal,
  },

  mixins: [
    ModalHolder,
    AlertHolder,
    ConfirmationHolder,
  ],

  data () {
    return {
      clientGroups: [],
      documentTypes: [],
      companyTypes: [],
      preset: 'doctor',
      currentClinicId: gon.application.current_clinic.id,
      currentUserId: gon.application.current_user.id,
      waitingListCount: 0,
      visibility: false,
      modal: {
        [MODAL_TYPES.CREATE_WAITING_ITEM]: {
          visibility: false,
          disabled: false,
          loading: false,
          clientEdited: false,
          clientTouched: false,
        },

        [MODAL_TYPES.EDIT_WAITING_ITEM]: {
          visibility: false,
          disabled: false,
          loading: false,
          clientEdited: false,
          clientTouched: false,
        },

        [MODAL_TYPES.LEGAL_REPRESENTATIVE]: {
          visibility: false,
        },

        [MODAL_TYPES.COMPANY]: {
          visibility: false,
        },
      },
    }
  },

  computed: {
    ...mapGetters([
      'GET_CURRENT_USER_CLINICS_LIST',
      'GET_SCHEDULE_DATE',
    ]),
  },

  watch: {
    waitingListCount (newValue) {
      const el: HTMLSpanElement = document.querySelector('#btn-trigger-group #waiting-list-counter')
      el.innerText = newValue
    },
  },

  created () {
    this.init()
    this.loadWaitingListCount({
      user_id: this.currentUserId,

    })
    this.fetchCompanyTypes()
    // добавляем текущего юзера в кэш, потому что из него берутся юзеры для расписания
    AppCache.store('user', this.$store.state.appConf.current_user)
  },

  methods: {
    init () {
      this.$store.dispatch('initialScheduleStore')
      doctorAreasEndpoint.getInitialData()
        .then(({
          client_groups: clientGroups,
          id_card_titles: documentTypes,
        }) => {
          this.clientGroups = clientGroups
          this.documentTypes = documentTypes
        })
        .catch(Utils.reportError('doctorAreasEndpoint.getInitialData'))
    },

    loadWaitingListCount (filters) {
      fetchWaitingListCount(filters)
        .then(this.updateWaitingListCounter)
        .catch(Utils.reportError('fetchWaitingListCount'))
    },

    fetchCompanyTypes () {
      const companyModal = this.modal[MODAL_TYPES.COMPANY]
      companyModal.loadingTypes = true
      companiesEndpoint.getCompanyTypes()
        .then((companyTypes) => {
          companyModal.companyTypes = companyTypes
        })
        .catch(Utils.reportError('companiesEndpoint.getCompanyTypes'))
        .finally(() => {
          companyModal.loadingTypes = false
        })
    },

    updateWaitingListCounter (count) {
      this.waitingListCount = count
    },

    openEditWaitingItemForm (item) {
      this.openModal(MODAL_TYPES.EDIT_WAITING_ITEM)
      this.$nextTick(() => {
        this.$refs.editWaitingItem.openEditWaitingItemForm(item)
      })
    },

    addLegalRepresentative (legalRep) {
      const newLegalRep = {
        id: legalRep.id,
        name: legalRep.name,
        surname: legalRep.surname,
        secondName: legalRep.second_name || legalRep.secondName,
        label: legalRep.short_info,
      }
      this.$store.commit('waitingList/ADD_LEGAL_REP', newLegalRep)
    },

    setCreatedCompanyData (company) {
      this.$store.commit('legalRepresentatives/SET_COMPANY', company)
    },
  },
})
</script>
