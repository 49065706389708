<template>
  <si-field>
    <div class="tbl-icon no-clip">
      {{ footer.total }}
      <footnote :text="footnotes.total" />
    </div>

    <div class="short_name" />

    <div class="tbl-icon" />

    <div class="date" />

    <div>
      <span :data-tooltip="$filters.currency(footer.byCash)">
        {{ $filters.currency(footer.byCash) }}
      </span>
    </div>

    <div>
      <span :data-tooltip="$filters.currency(footer.byCashless)">
        {{ $filters.currency(footer.byCashless) }}
      </span>
    </div>

    <div>
      <span :data-tooltip="$filters.currency(footer.byCard)">
        {{ $filters.currency(footer.byCard) }}
      </span>
    </div>

    <div>
      <span :data-tooltip="$filters.currency(footer.byBalance)">
        {{ $filters.currency(footer.byBalance) }}
      </span>
    </div>

    <div>
      <span :data-tooltip="$filters.currency(footer.byCredit)">
        {{ $filters.currency(footer.byCredit) }}
      </span>
    </div>

    <div>
      <span :data-tooltip="$filters.currency(footer.totalIncome)">
        {{ $filters.currency(footer.totalIncome) }}
      </span>
    </div>

    <div>
      <span :data-tooltip="$filters.currency(footer.totalPaid)">
        {{ $filters.currency(footer.totalPaid) }}
      </span>
    </div>

    <div>
      <span>
        {{ '‒' }}
        <footnote :text="footnotes.finalSum" />
      </span>
    </div>

    <div />
  </si-field>
</template>

<script>
import Footnote from '@/vue_components/common/footnote'
import SiField from '@/vue_components/sort_items/si_table/si_field'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'PaymentsReportTableFooter',

  components: { SiField, Footnote },

  props: {
    footer: PropsTypes.Object(undefined, true),
    footnotes: PropsTypes.Object(undefined, true),
  },
}
</script>
