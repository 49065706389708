import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { Api } from '@/_api/decorators/api/Api'
import { ICatalog } from '@/_declarations/ICatalog'
import { IList } from '@/_declarations/IList'

export class FiltersApi {
  @Api.notify('fetchMessage', 'clientGroups', { success: false })
  @Api.get(Routes.get_all_client_groups_path)
  fetchClientGroups () {
    return {
    } as ICRUDReturn as Promise<Array<ICatalog<number>>>
  }

  @Api.notify('fetchMessage', 'appointmentTypes', { success: false })
  @Api.get(Routes.list_reception_appointment_types_path)
  fetchAppointmentTypes () {
    return {
    } as ICRUDReturn as Promise<Array<ICatalog<number>>>
  }

  @Api.notify('fetchMessage', 'attractionSources', { success: false })
  @Api.list(Routes.catalogs_attraction_sources_list_path)
  fetchAttractionSources ({ searchQuery = undefined, ...payload } = {}) {
    return {
      payload: {
        ...payload,
        title: searchQuery,
      },
    } as ICRUDReturn as Promise<IList<Array<ICatalog<number>>>>
  }

  @Api.notify('fetchMessage', 'appointmentSources', { success: false })
  @Api.list(Routes.appointment_sources_list_api_internal_doctor_schedules_path)
  fetchAppointmentSources (payload) {
    return { payload } as ICRUDReturn as Promise<IList<Array<ICatalog<number>>>>
  }
}
