<template>
  <div
    id="employees-schedule-container"
    ref="employeesScheduleContainer"
    class="employees-schedule-grid"
  >
    <schedule-template-modal
      ref="scheduleTemplateModal"
      :show-schedule-template-modal="showScheduleTemplateModal"
      :users="users"
      :users-map="usersMap"
      :cabinets="cabinets"
      :cabinets-map="cabinetsMap"
      :online-recording-enabled="onlineRecordingEnabled"
      :current-clinic="currentClinic"
      @close="showScheduleTemplateModal = false"
      @removeScheduleTemplate="data => $refs.removeScheduleTemplateModal.loadData(data)"
      @updateScheduleTemplate="data => $refs.updateScheduleTemplateModal.loadData(data)"
      @hideRemoveScheduleTemplateModal="showRemoveScheduleTemplateModal = false"
    />

    <remove-schedule-template-modal
      ref="removeScheduleTemplateModal"
      :show-remove-schedule-template-modal="showRemoveScheduleTemplateModal"
      @showMe="showRemoveScheduleTemplateModal = true"
      @removeNone="showRemoveScheduleTemplateModal = false"
      @remove="data => $refs.scheduleTemplateModal.removeScheduleTemplate(data)"
      @setConfirmationModalData="data => confirmationModalData = data"
      @showConfirmationModal="showConfirmationModalHandler"
    />

    <update-schedule-template-modal
      ref="updateScheduleTemplateModal"
      :show-update-schedule-template-modal="showUpdateScheduleTemplateModal"
      @selfVisibility="visibility => showUpdateScheduleTemplateModal = visibility"
    />

    <extend-schedule-template-modal
      :show-extend-schedule-template-modal="showExtendScheduleTemplateModal"
      :extend-schedule-template-modal-data="extendScheduleTemplateModalData"
      @close="showExtendScheduleTemplateModal = false"
      @extend="_extendScheduleTemplatesRequest"
    />

    <work-time-modal
      v-if="showWorkTimeModal"
      ref="workTimeModal"
      :show-work-time-modal="showWorkTimeModal"
      :cabinets="cabinets.filter(cabinet => cabinet.id !== WITHOUT_CABINET_ID)"
      :schedule-templates-map="scheduleTemplatesMap"
      :work-time-modal-data="workTimeModalData"
      :online-recording-enabled="onlineRecordingEnabled"
      @close="showWorkTimeModal = false"
      @showUpdateWorkTimeModal="showUpdateWorkTimeModalHandler"
      @showRemoveWorkTimeModal="showRemoveWorkTimeModalHandler"
      @showAddVacationModal="showAddVacationModalHandler"
      @activeRequestState="_setActiveRequestState"
    />

    <remove-work-time-modal
      v-bind="removeWorkTimeModalData"
      :show-remove-work-time-modal="showRemoveWorkTimeModal"
      @removeNone="$refs.workTimeModal.getCore().removeTemplate(REMOVE_NONE)"
      @removeOneWorkTime="$refs.workTimeModal.getCore().removeTemplate(REMOVE_ONE_WORK_TIME)"
      @removeAllFromCurrentDate="showConfirmationModalHandler(true, REMOVE_ALL_FROM_CURRENT_DATE)"
      @removeAll="showConfirmationModalHandler(true, REMOVE_ALL)"
    />

    <update-work-time-modal
      v-bind="updateWorkTimeModalData"
      :show-update-work-time-modal="showUpdateWorkTimeModal"
      @updateNone="$refs.workTimeModal.getCore().updateTemplate(UPDATE_NONE, updateWorkTimeModalData.id)"
      @updateAllFromCurrentDate="showConfirmationModalHandler(true, UPDATE_ALL_FROM_CURRENT_DATE)"
      @updateAll="showConfirmationModalHandler(true, UPDATE_ALL)"
    />

    <add-vacation-modal
      v-if="showAddVacationModal"
      :show-add-vacation-modal="showAddVacationModal"
      :add-vacation-modal-data="addVacationModalData"
    />

    <select-vacation-modal
      v-if="showSelectVacationModal"
      v-bind="selectVacationModalData"
      :show-select-vacation-modal="showSelectVacationModal"
    />

    <remove-vacation-modal
      v-if="showRemoveVacationModal"
      v-bind="removeVacationModalData"
      :show-remove-vacation-modal="showRemoveVacationModal"
    />

    <confirmation-modal
      v-if="showConfirmationModal"
      :modal-visibility="showConfirmationModal"
      :header-message="confirmationModalData[confirmationModalData.field].headerMessage"
      :message="confirmationModalData[confirmationModalData.field].message"
      @yes="confirmationModalData[confirmationModalData.field].yesEvent()"
      @no="confirmationModalData[confirmationModalData.field].noEvent()"
    />

    <table id="employees-schedule-table">
      <tbody v-if="date !== null">
        <tr
          v-if="dateRange"
          class="date-row"
        >
          <th
            :id="getCellKey()"
            class="checkbox-cell"
            @click="checkboxOnClick"
          >
            <input
              class="checkbox"
              type="checkbox"
            >
          </th>
          <th class="user-name-cell_th">
            <div>{{ dateRange[date].year }}</div>
            <div>{{ dateRange[date].month }}</div>
          </th>
          <th
            v-for="(day, date, index) in dateRange"
            :key="index"
            :class="[(currentDate === date) && 'current-date-cell', (index === 3) && 'not-clickable']"
            :data-date="date"
            class="week-day"
            @click="dateCellOnClick"
          >
            <div>{{ day.weekDay }}</div>
            <div>{{ day.dayOfMonth }}</div>
          </th>
        </tr>
        <template v-for="(cabinetsGraph, specialtyId, sIndex) in scheduleGridGraph.schedule">
          <template v-for="(usersGraph, cabinetId, cIndex) in cabinetsGraph">
            <template v-if="selectedCabinetIds.includes(parseInt(cabinetId, 10))">
              <tr>
                <td
                  :id="getCellKey(specialtyId, cabinetId)"
                  :class="(sIndex > 0 && cIndex === 0) && 'with-separator'"
                  class="checkbox-cell cabinet-row-checkbox"
                  @click="checkboxOnClick"
                >
                  <input
                    class="checkbox"
                    type="checkbox"
                  >
                </td>
                <td
                  :class="(sIndex > 0 && cIndex === 0) && 'with-separator'"
                  class="cabinet-cell"
                  colspan="8"
                >
                  <span>
                    <span
                      class="specialty-select"
                      @click="$pubSub.emitAsync('EMPLOYEES_SCHEDULE.SELECT_ONE_SPECIALTY', specialtyId)"
                    >
                      {{ specialtiesMap[specialtyId].title }}
                    </span>
                    <span class="fad fa-angle-right" />
                    <span
                      class="cabinet-select"
                      @click="$pubSub.emitAsync('EMPLOYEES_SCHEDULE.SELECT_ONE_CABINET', cabinetId)"
                    >
                      {{ cabinetsMap[cabinetId].title }}
                    </span>
                    <template v-if="resetAllFilters">
                      <span class="fad fa-angle-right" />
                      <span
                        :title="t('reset_all_filters')"
                        class="fad fa-times reset-all-filters tooltip-bottom"
                        data-hintPosition="top-right"
                        @click="$pubSub.emitAsync('EMPLOYEES_SCHEDULE.RESET_ALL_FILTERS')"
                      />
                    </template>
                  </span>
                </td>
              </tr>
              <tr v-for="(workTimesGraph, userId) in usersGraph">
                <td
                  :id="getCellKey(specialtyId, cabinetId, userId)"
                  class="checkbox-cell"
                  @click="checkboxOnClick"
                >
                  <input
                    class="checkbox"
                    type="checkbox"
                  >
                </td>
                <td
                  :data-user-id="userId"
                  class="user-name-cell"
                  @click="$pubSub.emitAsync('EMPLOYEES_SCHEDULE.SELECT_ONE_USER', userId)"
                >
                  <span class="user-avatar-container">
                    <a
                      :title="t('employee_profile')"
                      :href="_getUserProfileLink(userId)"
                      class="tooltip-bottom"
                      target="_blank"
                      @click.stop=""
                    >
                      <img :src="getUserAvatar(usersMap[userId])">
                    </a>
                    <span
                      v-if="usersActiveScheduleTemplatesMap[userId] > 0"
                      :title="t('extended_schedule_templates')"
                      class="templates-counter tooltip-bottom"
                      data-hintPosition="top-right"
                      @click.stop="showScheduleTemplateModalHandler(userId)"
                    >
                      {{ usersActiveScheduleTemplatesMap[userId] }}
                    </span>
                  </span>

                  <span
                    :title="usersMap[userId].short_name"
                    class="user-sell_user-surname tooltip-bottom"
                    data-hintPosition="top-right"
                  >
                    {{ usersMap[userId].surname }}
                  </span>
                </td>

                <template v-for="(workTimes, date) in workTimesGraph">
                  <td
                    v-if="Array.isArray(workTimes.vacation_ids)"
                    :class="(currentDate === date) ? 'current-date-cell' : 'vacation-cell'"
                    :data-user-id="userId"
                    :data-date="date"
                    :data-vacation-ids="JSON.stringify(workTimes.vacation_ids)"
                    @click="_vacationCellOnClick"
                  >
                    <div>
                      {{ t('vacation') }}
                    </div>
                  </td>
                  <td
                    v-else
                    :class="(currentDate === date) && 'current-date-cell'"
                    :data-date="date"
                    :data-sid-cid-uid="getCellKey(specialtyId, cabinetId, userId)"
                    class="user-cell"
                    @click="_userCellOnClick"
                  >
                    <div
                      v-for="workTime in workTimes"
                      :key="workTime.id"
                      :data-work-time-id="workTime.id"
                      :data-cabinet-id="workTime.cabinet_id"
                    >
                      <span
                        v-if="workTime.schedule_template_id"
                        :class="_getIntervalStatus(workTime.schedule_template_id)"
                        class="fad fa-sync interval-work-time"
                      />
                      <span>{{ workTime.work_time.replace('-', '—') }}</span>
                    </div>
                  </td>
                </template>
              </tr>
            </template>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import ConfirmationModal from '../../confirmation_modal.vue'
import ScheduleTemplateModal
  from '../modal_content/schedule_template_modal/schedule_template/schedule_template_modal.vue'
import UpdateScheduleTemplateModal
  from '../modal_content/update_schedule_template_modal/update_schedule_template_modal.vue'
import RemoveScheduleTemplateModal
  from '../modal_content/remove_schedule_template_modal/remove_schedule_template_modal.vue'
import ExtendScheduleTemplateModal
  from '../modal_content/extend_schedule_template_modal/extend_schedule_template_modal.vue'
import WorkTimeModal from '../modal_content/schedule_template_modal/work_time/work_time_modal.vue'
import UpdateWorkTimeModal from '../modal_content/update_work_time_modal/update_work_time_modal.vue'
import RemoveWorkTimeModal from '../modal_content/remove_work_time_modal/remove_work_time_modal.vue'
import AddVacationModal from '../modal_content/add_vacation_modal/add_vacation_modal.vue'
import SelectVacationModal from '../modal_content/select_vacation_modal/select_vacation_modal.vue'
import RemoveVacationModal from '../modal_content/remove_vacation_modal/remove_vacation_modal.vue'

import globalObjectsAccessHelpers from '../../global_objects_access_helpers.js'
import checkboxes from './checkboxes.js'
import events from './events.js'
import requests from './requests.js'
import core from './core.js'
import modalHandlers from './modal_handlers.js'
import { formatDate } from '../utils.js'
import {
  REMOVE_ALL,
  REMOVE_ALL_FROM_CURRENT_DATE,
  REMOVE_NONE,
  REMOVE_ONE_WORK_TIME,
  UPDATE_ALL,
  UPDATE_ALL_FROM_CURRENT_DATE,
  UPDATE_NONE,
  WITHOUT_CABINET_ID,
} from '../const.js'
import tippy from 'tippy.js'

export default {
  name: 'EmployeesScheduleTable',

  components: {
    ConfirmationModal,
    ScheduleTemplateModal,
    UpdateScheduleTemplateModal,
    RemoveScheduleTemplateModal,
    ExtendScheduleTemplateModal,
    WorkTimeModal,
    UpdateWorkTimeModal,
    RemoveWorkTimeModal,
    AddVacationModal,
    SelectVacationModal,
    RemoveVacationModal,
  },

  props: {
    users: { type: Array, required: true },
    specialties: { type: Array, required: true },
    cabinets: { type: Array, required: true },
    onlineRecordingEnabled: { type: Boolean, default: false },
    currentClinic: { type: Object, required: true },
    extendScheduleButton: { type: Object, required: true },
    calendar: { type: Object, required: true },
  },

  data () {
    return {
      WITHOUT_CABINET_ID,

      REMOVE_NONE,
      REMOVE_ONE_WORK_TIME,
      REMOVE_ALL_FROM_CURRENT_DATE,
      REMOVE_ALL,

      UPDATE_NONE,
      UPDATE_ALL_FROM_CURRENT_DATE,
      UPDATE_ALL,

      showConfirmationModal: false,
      showScheduleTemplateModal: false,
      showUpdateScheduleTemplateModal: false,
      showRemoveScheduleTemplateModal: false,
      showExtendScheduleTemplateModal: false,
      showWorkTimeModal: false,
      showUpdateWorkTimeModal: false,
      showRemoveWorkTimeModal: false,
      showAddVacationModal: false,
      showSelectVacationModal: false,
      showRemoveVacationModal: false,

      confirmationModalData: null,
      extendScheduleTemplateModalData: null,
      workTimeModalData: null,
      updateWorkTimeModalData: null,
      removeWorkTimeModalData: null,
      addVacationModalData: null,
      selectVacationModalData: null,
      removeVacationModalData: null,

      currentDate: Utils.dateJStoRuby(new Date()),
      date: null,
      dateRange: null,
      scheduleGridGraph: Object.create(null),
      selectedCabinetIds: [],
      resetAllFilters: false,
      activeRequestState: false,
      checkboxesState: Object.create(null),
      nativeTableElement: null,

      specialtiesMap: Object.create(null),
      usersMap: Object.create(null),
      cabinetsMap: Object.create(null),
      checkboxesMap: Object.create(null),
      scheduleTemplatesMap: null,
      usersActiveScheduleTemplatesMap: null,
      extendScheduleTippy: tippy('#extend-employees-schedule')[0],
    }
  },

  watch: {
    scheduleGridGraph () {
      this._setScheduleTemplatesMaps()
    },
    selectedCabinetIds () {
      this.setCheckboxesState(this.scheduleGridGraph.schedule, this.selectedCabinetIds)
    },
  },

  created () {
    this.$pubSub.subscribe('EMPLOYEES_SCHEDULE.CHECKBOXES_STATE_CHANGED', this.showExtendScheduleButton)

    this.cabinets.forEach((cabinet) => {
      this.cabinetsMap[cabinet.id] = cabinet
    })

    this.users.forEach((user) => {
      this.usersMap[user.id] = user
    })

    this.specialties.forEach((specialty) => {
      this.specialtiesMap[specialty.id] = specialty
    })

    const ps = this.$pubSub

    ps.subscribe(
      'EMPLOYEES_SCHEDULE.UPDATE_SCHEDULE_GRID_DATE',
      this.buildDateRow
    )
    ps.subscribe(
      'EMPLOYEES_SCHEDULE.UPDATE_SCHEDULE_GRID_GRAPH',
      ({ scheduleGridGraph, selectedCabinetIds, resetAllFilters }) => {
        this.scheduleGridGraph = scheduleGridGraph
        this.selectedCabinetIds = selectedCabinetIds
        this.resetAllFilters = resetAllFilters
      }
    )
    ps.subscribe(
      'EMPLOYEES_SCHEDULE.SHOW_EXTEND_SCHEDULE_TEMPLATES_MODAL',
      this.showExtendScheduleTemplatesModalHandler
    )
    ps.subscribe(
      'EMPLOYEES_SCHEDULE.SHOW_SCHEDULE_TEMPLATE_MODAL',
      this.showScheduleTemplateModalHandler
    )
    ps.subscribe(
      'EMPLOYEES_SCHEDULE.ADD_VACATION',
      this._addVacation
    )
    ps.subscribe(
      'EMPLOYEES_SCHEDULE.HIDE_ADD_VACATION_MODAL',
      () => { this.showAddVacationModal = false }
    )
    ps.subscribe(
      'EMPLOYEES_SCHEDULE.HIDE_SELECT_VACATION_MODAL',
      () => { this.showSelectVacationModal = false }
    )
    ps.subscribe(
      'EMPLOYEES_SCHEDULE.SHOW_REMOVE_VACATION_MODAL',
      this.showRemoveVacationModalHandler
    )
    ps.subscribe(
      'EMPLOYEES_SCHEDULE.SPLIT_VACATION',
      this._splitVacation
    )
    ps.subscribe(
      'EMPLOYEES_SCHEDULE.SLICE_VACATION',
      this._sliceVacation
    )
    ps.subscribe(
      'EMPLOYEES_SCHEDULE.REMOVE_VACATION',
      this._removeVacation
    )
    ps.subscribe(
      'EMPLOYEES_SCHEDULE.HIDE_REMOVE_VACATION_MODAL',
      () => { this.showRemoveVacationModal = false }
    )
  },

  mounted () {
    this.nativeTableElement = document.getElementById('employees-schedule-table')
    Services.wsSubscriptions.workTime.connect(this._workTimeUpdatesHandler)
  },

  updated () {
    this.synchronizeCheckboxesMap()
    this._updateTooltips()
  },

  methods: {
    formatDate,
    ...globalObjectsAccessHelpers,
    ...core,
    ...checkboxes,
    ...events,
    ...requests,
    ...modalHandlers,

    showExtendScheduleButton (flag) {
      this.extendScheduleTippy.state.enabled = !flag

      if (flag) {
        this.extendScheduleButton.removeClass('disabled')
        this.extendScheduleTippy.disable()

        return
      }

      this.extendScheduleButton.addClass('disabled')
      this.extendScheduleTippy.enable()
    },
  },
}
</script>
