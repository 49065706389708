import SupportModule from '@/vue_components/support/SupportModule'
import { createVueApp } from '@/vue_components/create_vue_app'

export const createSupportModule = (el) => {
  if (!document.querySelector(el)) {
    return
  }

  createVueApp({
    el,
    name: 'SupportModuleRoot',
    render: (h) => h(SupportModule),
  })
}
