import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import {
  ITaxCertificatesReportTotal,
} from '@/vue_apps/ReportsModule/TaxSertificatesReport/interfaces/ITaxCertificatesReportResponse'

export class TaxCertificateTotalItemCells extends MListServiceItem {
  certificateId: MTableCell

  sumOne: MTableCell

  sumTwo: MTableCell

  constructor (props: ITaxCertificatesReportTotal) {
    super()

    this.certificateId = new MTableCell(t('taxCertificatesReport.tableHeaders.total'))

    this.sumOne = new MTableCell(vueFilters.currency(props.totalSumOne))
      .addTooltip(true)

    this.sumTwo = new MTableCell(vueFilters.currency(props.totalSumTwo))
      .addTooltip(true)
  }
}
