<template>
  <si-field>
    <div class="tbl-icon">
      <span
        :data-tooltip="row.id"
        class="fad fa-fw fa-coin tooltip-bottom"
      />
    </div>
    <div class="date cut">
      {{ $filters.date(row.date) }}
    </div>
    <div class="title cut">
      {{ row.title }}
    </div>
    <div class="sum cut">
      {{ $filters.price(row.price) }}
    </div>
    <div>
      {{ row.additional }}
    </div>
    <div>
      {{ expenseGroup }}
    </div>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'

export default {
  name: 'ExpensesRowDefault',

  components: {
    SiField,
  },

  props: {
    row: {
      type: Object,
      required: true,
    },
  },

  computed: {
    expenseGroup () {
      return this.row.expenseGroup?.title || ''
    },
  },
}
</script>
