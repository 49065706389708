<template>
  <si-field>
    <div class="tbl-icon no-clip">
      {{ staticFooter.total }}
    </div>
    <div class="date cut" />
    <div class="title cut" />
    <div
      :data-tooltip="$filters.currency(footer.price)"
      class="sum cut"
    >
      {{ $filters.currency(footer.price) }}
    </div>
    <div />
    <div />
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'

export default {
  name: 'ExpensesFooterDefault',

  components: {
    SiField,
  },

  props: {
    footer: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      staticFooter: {
        total: t('total'),
      },
    }
  },
}
</script>
