<template>
  <search-select
    class="si-control-item"
    :custom-class="payerIsDeleted ? 'payer-deleted' : ''"
    :search-query="searchQuery"
    :allow-search="allowSearch"
    :not-results="notResults"
    :loading="loading"
    :disabled="disabled"
    :placeholder="t('client_or_company')"
    v-bind="$attrs"
    @update:searchQuery="$emit('update:searchQuery', $event)"
    v-on="$listeners"
  >
    <el-option-group
      v-show="clientsPayers.length"
      :label="t('clients')"
    >
      <el-option
        v-for="client in clientsPayers"
        :key="client.id"
        :label="clientInfo(client)"
        :value="`${client.id}${SEPARATOR}${PAYER_TYPE.CLIENT}`"
      />
    </el-option-group>
    <el-option-group
      v-show="companiesPayers.length"
      ref="optionGroupCompany"
      :label="t('companies')"
    >
      <el-option
        v-for="company in companiesPayers"
        :key="company.id"
        :label="companyInfo(company)"
        :value="`${company.id}${SEPARATOR}${PAYER_TYPE.COMPANY}`"
      >
        <span
          class="fad fa-info-circle more-info-company tooltip-bottom"
          :title="companyInfo(company)"
        />
        {{ companyInfo(company) }}
      </el-option>
    </el-option-group>
  </search-select>
</template>

<script>
import SearchSelect from '@/vue_components/common/select/search_select/search_select.vue'
import payerSelectorMixin from '@/vue_components/mixins/payer_selector/payer_selector_mixin.js'
import { PAYER_TYPE, SEPARATOR } from '@/vue_components/order/consts.js'

export default {
  name: 'PayerFinder',

  components: {
    SearchSelect,
  },

  mixins: [payerSelectorMixin],

  props: {
    searchQuery: {
      type: String,
      required: true,
    },

    clientsPayers: {
      type: Array,
      required: true,
    },

    companiesPayers: {
      type: Array,
      required: true,
    },

    payerType: {
      type: String,
      required: true,
    },
    payerIsDeleted: Boolean,
    allowSearch: Boolean,
    notResults: Boolean,
    disabled: Boolean,
    loading: Boolean,

  },

  data () {
    return {
      SEPARATOR,
      PAYER_TYPE,
    }
  },

  watch: {
    companiesPayers () {
      Utils.updateTooltipsInContainer(this.$refs.optionGroupCompany.$el)
    },
  },
}
</script>
