import DiscountReasonsCatalog from './discount_reasons'
import { createVueApp } from '@/vue_components/create_vue_app'

export const DiscountReasonsEntryPoint = () => {
  const el = '#vue_discount_reasons'

  if (!document.querySelector(el)) return
  createVueApp({
    el,
    render: (h) => h(DiscountReasonsCatalog),
  })
}
