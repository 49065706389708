<template>
  <div
    id="entries_container"
    class="panel panel-primary panel-inner"
    :class=" {
      'hidden-print': isKdl
    }"
  >
    <panel-heading
      :title="t('laboratories.entries_list')"
      class="tooltip-bottom"
      icon="fad fa-clipboard"
      :panel-heading-class="{
        'hidden-print-fixed': true,
      }"
    >
      <h4 class="showed-print">
        {{ t('researches') }}
      </h4>

      <div id="entries_table">
        <div
          id="entries_table_header"
          class="entry_row"
        >
          <div
            class="row_column number tooltip-bottom"
          >
            {{ t('code') }}
          </div>

          <div
            class="row_column title tooltip-bottom"
          >
            <span
              class="tooltip-bottom"
              :title="t('title')"
            >
              {{ t('title') }}
            </span>
          </div>

          <div
            class="row_column biomaterial tooltip-bottom hidden-print"
            :title="t('material')"
          >
            {{ t('material') }}
          </div>

          <div
            v-if="isKdl && !resetContainersBtnEnabled"
            v-tooltip="biomaterialTypeTitleTooltip"
            class="row_column biomaterial-type cut tooltip-bottom hidden-print"
          >
            {{ biomaterialTypeTitle }}
          </div>

          <div
            v-if="isForm"
            class="row_column sampling_date cut tooltip-bottom"
            :title="t('laboratories.sampling_date')"
          >
            {{ t('laboratories.sampling_date') }}
          </div>

          <div
            class="row_column due_date cut tooltip-bottom"
            :title="t('due_date')"
          >
            {{ t('due_date') }}
          </div>

          <div
            class="row_column price tooltip-bottom"
            :title="t('price')"
          >
            {{ t('price') }}
          </div>

          <div
            class="row_column options hidden-print"
          />
        </div>
        <div
          id="entries_table_body"
        >
          <div
            v-for="n in 3"
            v-if="!entries && !isForm"
            :key="n"
            class="entry_row skeleton"
          >
            <div
              class="row_column number cut tooltip-bottom"
            >
              <skeleton />
            </div>

            <div

              class="row_column title cut tooltip-bottom"
            >
              <skeleton />
            </div>

            <div
              class="row_column biomaterial cut tooltip-bottom"
            >
              <skeleton />
            </div>

            <div
              v-if="isKdl && !resetContainersBtnEnabled"
              class="row_column biomaterial-type cut tooltip-bottom"
            >
              <skeleton />
            </div>

            <div
              class="row_column due_date"
            >
              <skeleton />
            </div>

            <div
              class="row_column price"
            >
              <skeleton />
            </div>
          </div>

          <template v-if="entries">
            <div
              v-for="(entry, index) in entries"
              :key="entry.entryId || `${entry.id}_${index}`"
              :class="['entry_row', {'red': isErrorEntry(entry)}]"
            >
              <div
                class="row_column number cut tooltip-bottom"
                :title="entry.number"
                @click="visitEntry(entry.id)"
              >
                {{ entry.number }}
              </div>

              <div
                class="row_column title"
                @click="visitEntry(entry.id)"
              >
                <div
                  class="cut tooltip-bottom"
                  :title="entry.title"
                >
                  {{ entry.title }}
                </div>
              </div>

              <div
                v-if="isForm"
                class="row_column biomaterial"
              >
                <biomaterial-selector
                  :entry="entry"
                  @registerValidator="$registerValidator($event)"
                />
              </div>

              <div
                v-if="isKdl && isForm && !resetContainersBtnEnabled"
                class="row_column biomaterial-type"
                :title="selectedBiomaterialKinds(entry.selectedBiomaterials)"
              >
                {{ selectedBiomaterialKinds(entry.selectedBiomaterials) }}
              </div>

              <div
                v-if="isForm"
                class="row_column sampling_date"
              >
                <el-date-picker
                  v-model="entry.samplingDate"
                  type="datetime"
                  :clearable="false"
                  :placeholder="t('laboratories.sampling_date')"
                  :format="dateFormat"
                  :picker-options="datePickerOptions"
                />
              </div>

              <div
                v-else
                class="row_column biomaterial cut tooltip-bottom hidden-print"
                @click="visitEntry(entry.id)"
              >
                <template v-if="isKdl">
                  <div
                    v-for="biomaterial in entry.biomaterials"
                    :key="biomaterial.id"
                    class="biomaterial-title-item"
                    :title="biomaterial.title"
                  >
                    {{ biomaterial.title }}
                  </div>
                </template>

                <div
                  v-else
                  class="biomaterial-title-item"
                  :title="biomaterialTitles(entry, index)"
                >
                  {{ biomaterialTitles(entry, index) }}
                </div>
              </div>

              <div
                v-if="isKdl && !isForm && !resetContainersBtnEnabled"
                class="row_column biomaterial-type cut tooltip-bottom hidden-print"
              >
                <template
                  v-for="biomaterial in entry.biomaterials"
                >
                  <entry-select-containers
                    :key="biomaterial.id"
                    :disabled="resetContainersBtnEnabled"
                    :containers="biomaterial.biomaterialContainersTypes"
                    :biomaterial-id="biomaterial.id"
                    :entry-id="entry.id"
                    :saved-containers="getSavedContainer(entry.id, biomaterial.id)"
                    @registerValidator="$registerValidator($event)"
                    @setSelectedContainerType="setSelectedContainersType(entry,biomaterial, $event)"
                  />
                </template>
              </div>

              <div
                class="row_column due_date"
                @click="visitEntry(entry.id)"
              >
                {{ entry.dueDate }}
              </div>

              <div
                class="row_column price"
                @click="visitEntry(entry.id)"
              >
                {{ $filters.currency(entry.price) }}
              </div>
              <div
                v-if="isForm"
                class="row_column options hidden-print "
              >
                <a
                  class="fad fa-trash-alt"
                  href="#"
                  @click.prevent="removeItem(index)"
                />
              </div>

              <div
                v-else
                class="row_column options hidden-print"
              >
                <button
                  class="btn btn-grey-dark btn-xs tooltip-bottom"
                  :title="t('laboratories.show_preparation')"
                  @click.capture.prevent="showPreparationInfo(entry)"
                >
                  <span class="fad fa-info-circle" />
                </button>
              </div>
            </div>
          </template>
        </div>
        <div
          id="entries_table_footer"
          class="entry_row"
        >
          <div
            class="row_column number"
          >
            {{ t('total') }}
          </div>

          <div
            class="row_column title"
          />

          <div
            class="row_column biomaterial hidden-print"
          />

          <div
            v-if="isKdl && !resetContainersBtnEnabled"
            class="row_column biomaterial-type hidden-print"
          />

          <div
            v-if="isForm"
            class="row_column sampling_date"
          />

          <div
            class="row_column due_date hidden-print"
          >
            {{ maximumDueDate }}
          </div>
          <div class="row_column due_date showed-print" />

          <div
            class="row_column price"
          >
            {{ $filters.currency(entriesSum) }}
          </div>

          <div
            class="row_column options hidden-print"
          />
        </div>
      </div>
      <modal
        :modal-visibility="modalVisible"
        @close="closeModal"
      >
        <template #header>
          {{ t('laboratories.preparation') }}
        </template>

        <template #body>
          <div id="preparation_info">
            {{ modalContent }}
          </div>
        </template>

        <template #footer-right>
          <el-button
            :class="['btn', 'btn-primary', 'btn-sm']"
            @click="closeModal"
          >
            {{ t('close') }}
          </el-button>
        </template>
      </modal>
    </panel-heading>
  </div>
</template>
<script>
import BiomaterialSelector from './biomaterial_selector'
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import Skeleton from '@/vue_components/skeleton'
import Modal from '../../modal.vue'
import EntrySelectContainers
  from '@/vue_components/analysis_orders/components/BiomaterialContainers/EntrySelectContainers.vue'

export default {
  name: 'EntriesContainer',

  components: {
    BiomaterialSelector,
    PanelHeading,
    Skeleton,
    Modal,
    EntrySelectContainers,
  },

  props: {
    entries: {
      default: undefined,
      type: Array,
    },

    isForm: {
      default: false,
      type: Boolean,
    },

    isKdl: Boolean,
    resetContainersBtnEnabled: Boolean,

    savedContainers: {
      type: Array,
      default: () => [],
    },

    errorEntriesNumbers: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {
      modalVisible: false,
      modalContent: '',
      datePickerOptions: {
        firstDayOfWeek: gon.localization.day_week_start,
        disabledDate (date) {
          return date > new Date()
        },
      },

      selectedContainers: [],
    }
  },

  computed: {
    dateFormat () {
      if (gon.localization.date_format) {
        return `${gon.localization.date_format.replace(/mm/, 'MM').replace(/\//g, '.')} HH:mm:ss`
      }

      return 'yyyy.MM.dd HH:mm:ss'
    },

    entriesSum () {
      if (this.entries === undefined) return

      return this.entries.reduce((sum, entry) => sum + parseFloat(entry.price), 0)
    },

    maximumDueDate () {
      if (this.entries === undefined || this.entries.length === 0) return

      return Math.max(
        ...this.entries.map((entry) => {
          const dueDate = parseInt(entry.dueDate)
          if (Number.isNaN(dueDate)) return 0

          return parseInt(entry.dueDate)
        })
      )
    },

    biomaterialTypeTitle () {
      return this.isForm
        ? t('laboratories.biomaterialContainerType')
        : t('laboratories.biomaterialContainerTypeKDL')
    },

    biomaterialTypeTitleTooltip () {
      return this.isForm
        ? t('laboratories.biomaterialTypeKDL')
        : t('laboratories.biomaterialContainerTypeKDL')
    },
  },

  methods: {
    removeItem (index) {
      this.entries.splice(index, 1)
    },

    showPreparationInfo (entry) {
      this.modalContent = this.isKdl
        ? entry.preparation
        : this.biomaterialArray(entry)
          .map(
            (biomaterial) => biomaterial.preparation ? `${biomaterial.title}\n${biomaterial.preparation}\n` : ''
          )
          .join('\n')
          .trim()

      if (this.modalContent) {
        this.modalVisible = true
      } else {
        Notificator.info(t('laboratories.no_preparation_info'))
      }
    },

    closeModal () {
      this.modalVisible = false
      this.modalContent = ''
    },

    biomaterialArray (entry) {
      if (entry.biomaterialSets === undefined) return entry.biomaterials

      const arrayFromSets = []
      entry.biomaterialSets.forEach((set) => {
        set.biomaterials.forEach((biomaterial) => arrayFromSets.push(biomaterial))
      })

      return arrayFromSets
    },

    biomaterialTitles (entry, index = 0) {
      return this.biomaterialArray(entry).map((biomaterial) => biomaterial.title).join(', ')
    },

    selectedBiomaterialKinds (biomaterials) {
      if (!this.isKdl || !this.isForm) return
      if (biomaterials.length === 0) return

      const uniqueTypes = {}
      const res = []

      biomaterials.forEach((item) => {
        if (item.kind === 0) return

        if (!uniqueTypes[item.kind]) {
          uniqueTypes[item.kind] = true
          res.push(item.kind)
        }
      })

      return res.join(', ')
    },

    setSelectedContainersType (entry, biomaterial, container) {
      const existingContainerIndex = this.selectedContainers.findIndex(
        (item) => item.entry_id === entry.id && item.biomaterial_code === biomaterial.code
      )

      const newItem = {
        biomaterialId: biomaterial.id,
        biomaterial_code: biomaterial.code,
        entry_id: entry.id,
        ...container,
      }

      if (existingContainerIndex !== -1) {
        this.selectedContainers[existingContainerIndex] = newItem
        this.$emit('setSelectedContainers', this.selectedContainers)

        return
      }

      this.selectedContainers.push(newItem)

      this.$emit('setSelectedContainers', this.selectedContainers)
    },

    getSavedContainer (entryId, biomaterialId) {
      if (!this.savedContainers.length) {
        return null
      }

      const val = this.savedContainers.filter((item) =>
        item.entryIds.includes(entryId) &&
        item.biomaterialIds.includes(biomaterialId)
      )[0]

      return val?.code
        ? { code: val.code }
        : null
    },

    visitEntry (entryId) {
      if (this.isForm) { return }

      Turbolinks.visit(Routes.entry_path(entryId))
    },

    isErrorEntry (entry) {
      return this.errorEntriesNumbers.includes(entry.number)
    },
  },
}
</script>
