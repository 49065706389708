import AnalysisOrderForm from './analysis_order_form.vue'
import AnalysisOrderShow from './AnalysisOrderShow'
import AnalysisOrderIndex from './analysis_order_index.vue'
import ConfigurationControl from './configuration_control.vue'
import WebSocketsHandler from '@/vue_components/analysis_orders/web_sockets_handler.js'
import { createVueApp } from '@/vue_components/create_vue_app'

const wsHandler = new WebSocketsHandler()

export default {
  newForm () {
    wsHandler.subscribe()

    return createVueApp({
      el: '#analysis_order_form',
      render: (h) => h(AnalysisOrderForm, {
        props: {
          clientId: gon.specific.client_id,
          orderId: gon.specific.order_id,
          wsHandler,
        },
      }),
      useChildren: true,
    })
  },

  editForm () {
    wsHandler.subscribe()

    return createVueApp({
      el: '#analysis_order_form',
      render: (h) => h(AnalysisOrderForm, {
        props: {
          analysisOrderId: gon.specific.analysis_order_id,
          wsHandler,
        },
      }),
      useChildren: true,
    })
  },

  index () {
    // 7jt1ev
    if (gon.specific.laboratories_integration && gon.specific.laboratories_integration.moduleIsEnabled) {
      wsHandler.subscribe()

      return createVueApp({
        el: '#analysis_order_index',
        render: (h) => h(AnalysisOrderIndex, {
          props: { wsHandler },
        }),
        useChildren: true,
      })
    }

    return null
  },

  show () {
    wsHandler.subscribe()

    return createVueApp({
      el: '#analysis_order_show',
      name: 'AnalysisOrderShowApp',
      render: (h) => h(AnalysisOrderShow, {
        props: {
          analysisOrderId: gon.specific.analysis_order_id,
          currentClinicTitle: gon.application.current_clinic.title,
          wsHandler,
        },
      }),
      useChildren: true,
    })
  },

  configuration () {
    if (gon.specific.laboratoriesIntegration.moduleIsEnabled) {
      wsHandler.subscribe()

      return createVueApp({
        el: '#configuration_control',
        render: (h) => h(ConfigurationControl, {
          props: { wsHandler },
        }),
        useChildren: true,
      })
    }
  },
}
