<template>
  <m-si-generator
    class="report-container"
    :items="report.data"
    :si-generator-schema="schema"
    :total="report.total"
    :page-count="report.totalPages"
    :current-page="report.currentPage"
    :search-query="report.searchQuery"
    :loading="report.loading"
    @update:currentPage="report.fetchPage($event)"
    @update:searchQuery="report.search($event)"
    @onSortChange="onSortChange"
  >
    <template
      v-for="(slotContent, slotName) in $scopedSlots"
      #[slotName]="slotProps"
    >
      <slot
        :name="slotName"
        v-bind="slotProps"
      />
    </template>

    <template #tableCaption>
      {{ report.filtersString }}
    </template>
  </m-si-generator>
</template>

<script>
import { MReportListService } from '@/_api/_requests/MReportListService'
import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'

export default {
  name: 'ReportContainerBase',

  components: { MSiGenerator },

  props: {
    report: MReportListService,
    schema: MSiSchema,
  },

  created () {
    if (!this.report || !this.schema) {
      throw new Error('Не указан отчёт или схема таблицы')
    }
  },

  methods: {
    onSortChange (sorting) {
      this.report.setSorting(sorting)
      this.report.fetchAll()
    },
  },
}
</script>
