<template>
  <div
    v-show="show"
    class="panel panel-primary panel-edit"
  >
    <api-actions-modal
      v-bind="apiActionsModal"
      @close="apiActionsModal.visibility = false"
      @deleteApiRole="deleteApiRole"
      @fullResourceAccess="fullResourceAccess"
      @forbidResourceAccess="forbidResourceAccess"
    />
    <epic-spinner
      :visibility="activeRequestState"
    />
    <div class="panel-heading panel-heading-flex">
      <div class="panel-heading-title">
        <span class="panel-heading-icon fad fa-fw fa-user-lock" />
        <h3 class="panel-title">
          {{ T.api_page.roles.editing_api_role }}
        </h3>
      </div>
      <div class="panel-heading-buttons">
        <span
          class="panel_close_button fad fa-fw fa-times hover-white-icon"
          @click="$emit('close')"
        />
      </div>
    </div>
    <div class="panel-body edit-role-api-body">
      <form class="simple_form flex-form">
        <div class="form-label">
          <label
            class="string required"
            for="api_role_name"
          >

            <span>
              {{ T.activerecord.attributes.api_client.title }}
            </span>
            <abbr :title="T.field_required">
              *
            </abbr>
          </label>
        </div>
        <div class="form-value">
          <div
            :class="titleError ? 'has-error' : ''"
            class="form-group string required api_role_title"
          >
            <input
              id="api_role_title"
              v-model="role_name"
              class="form-control string required tooltip-bottom"
              type="text"
              autocomplete="off"
            >
            <div
              v-if="titleError"
              class="under_field_error"
            >
              {{ titleError }}
            </div>
          </div>
        </div>
      </form>
      <div class="form-btns-role">
        <button
          :disabled="!formValid || activeRequestState"
          class="btn btn-primary btn-sm btn-with-icon"
          @click="updateApiRole"
        >
          <span class="btn-with-icon_icon fad fa-save" />
          <span class="btn-with-icon_text">{{ t('save') }}</span>
        </button>
        <span
          class="fad fa-fw fa-trash btn-show-delete delete-record-button"
          :title="T.delete"
          @click="showDeleteApiRoleModal"
        />
      </div>
      <div class="collapse-expand">
        <button
          class="btn btn-default btn-sm"
          @click="collapseLists"
        >
          <span class="collapse-button">{{ elCollapseName }}</span>
          <span :class="elCollapseArrow" />
        </button>
      </div>
      <el-collapse
        v-for="(apiResource, apiResourceId) in apiResources"
        :key="apiResource.id"
        v-model="activeNames"
      >
        <el-collapse-item :name=" apiResourceId ">
          <template slot="title">
            <div class="el-collapse-item-header-wrapper">
              <div>
                <span :class="`${iconsMap[apiResource.name]} apiResourceIcon`" />
                <!-- EL-COLLAPSE TEXT -->
                <span class="el-collapse-item__text">{{ `${T.api_page.roles.resources[apiResource.name]}` }}</span>
              </div>

              <div>
                <span
                  class="tippy-span"
                  :class="iconType(apiResource.name)"
                  :data-tippy-content="iconDescription(apiResource.name)"
                />
              </div>
            </div>
          </template>

          <table class="table table-striped table-hover">
            <tbody>
              <tr class="vue-items-list">
                <td class="endpoint">
                  {{ T.api_page.roles.api_full_access }}
                </td>
                <td class="vue-list">
                  <button
                    :class="allYes(apiResource.name)"
                    class="vue-list-item"
                    @click="showFullResourceAccessModal(apiResource.name)"
                  >
                    {{ T.yes }}
                  </button>
                  <button
                    :class="allNo(apiResource.name)"
                    class="vue-list-item"
                    @click="showForbidResourceAccessModal(apiResourceId, apiResource.name)"
                  >
                    {{ T.no }}
                  </button>
                </td>
              </tr>
              <tr
                v-for="(apiMethod, apiMethodId) in apiResource.methods"
                :key="apiMethod.id"
                class="vue-items-list"
              >
                <td class="endpoint">
                  {{ `${T.api_page.roles.methods[apiMethod.endpoint]}` }}
                </td>
                <td class="vue-list">
                  <button
                    :class="mappedValues[apiResource.name][apiMethodId] && 'active'"
                    class="vue-list-item"
                    @click="addRoleMethod(apiMethodId, apiResource.name)"
                  >
                    {{ T.yes }}
                  </button>
                  <button
                    :class="!mappedValues[apiResource.name][apiMethodId] && 'active'"
                    class="vue-list-item"
                    @click="deleteRoleMethod(apiMethodId, apiResource.name)"
                  >
                    {{ T.no }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import ApiActionsModal from '@/vue_components/api/api_actions_modal.vue'
import tippy from 'tippy.js' // 7emv53

export default {
  name: 'ApiRoleEdit',

  components: {
    EpicSpinner,
    ApiActionsModal,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      activeRequestState: false,
      apiActionsModal: {
        visibility: false,
        actionButtonDisabled: false,
        expectedEvent: null,
        headerMessage: '',
        message: '',
        permission: '',
        actionButtonMessage: '',
      },

      role_id: null,
      role_name: '',
      titleError: null,
      authenticationToken: '',
      apiRole: Object.create(null),
      apiResources: Object.create(null),
      mappedValues: Object.create(null),
      apiMethodIds: [],
      activeNames: [],
      apiMethodIdsToAdd: [],
      apiMethodIdsToDelete: [],
      buttonText: '',
      iconsMap: {
        appointments: 'fad fa-fw fa-clipboard-user',
        clients: 'fad fa-fw fa-user-friends',
        entry_types: 'fad fa-fw fa-list',
        payments: 'fad fa-fw fa-money-bill',
        orders: 'fad fa-fw fa-shopping-cart',
        clinics: 'fad fa-fw fa-hospitals',
        users: 'fad fa-fw fa-user-md',
        entries: 'fad fa-fw fa-usd-circle',
        stores: 'fad fa-fw fa-warehouse',
        companies: 'fad fa-fw fa-building',
        schedule: 'fad fa-fw fa-calendar-alt',
      reminders: 'fad fa-tasks',},
      resourceName: '',
      resourceId: null,

      tippy_instance: null, // 7emv53
    }
  },

  computed: {
    formValid () {
      return this.role_name && this.role_name.trim().length > 0
    },

    allYes () {
      return (resourceName) => {
        const classes = Object.create(null)

        if (Object.values(this.mappedValues[resourceName]).every((item) => item === true)) {
          classes.active = true
        }

        return classes
      }
    },

    allNo () {
      return (resourceName) => {
        const classes = Object.create(null)

        if (!(Object.values(this.mappedValues[resourceName]).every((item) => item === true))) {
          classes.active = true
        }

        return classes
      }
    },

    iconType () {
      return (resourceName) => {
        const classes = Object.create(null)

        if (Object.values(this.mappedValues[resourceName]).every((item) => item === true)) {
          classes['fad fa-fw fa-lock-open icon'] = true
        } else if (Object.values(this.mappedValues[resourceName]).every((item) => item === false)) {
          classes['fad fa-fw fa-lock icon'] = true
        } else {
          classes['fad fa-fw fa-unlock icon'] = true
        }

        return classes
      }
    },

    iconDescription () {
      return (resourceName) => {
        let tooltip = ''
        const iconType = Object.keys(this.iconType(resourceName))[0]

        if (iconType === 'fad fa-fw fa-lock-open icon') {
          tooltip = `${T.api_page.roles.api_full_access}`
        } else if (iconType === 'fad fa-fw fa-lock icon') {
          tooltip = `${T.api_page.roles.api_no_access}`
        } else {
          tooltip = `${T.api_page.roles.api_partly_access}`
        }

        return tooltip
      }
    },

    elCollapseName () {
      let name = ''

      if (this.activeNames.length > 0) {
        name = `${T.api_page.roles.collapse_all}`
      } else {
        name = `${T.api_page.roles.expand_all}`
      }

      return name
    },

    elCollapseArrow () {
      const classes = Object.create(null)

      if (this.activeNames.length > 0) {
        classes['el-collapse-item__arrow el-icon-arrow-right is-active'] = true
      } else {
        classes['el-collapse-item__arrow el-icon-arrow-right'] = true
      }

      return classes
    },
  },

  watch: {
    show () {
      if (!this.show) this.resetForm()
    },
  },

  updated () {
    this.updateTippies() // 7emv53
  },

  methods: {
    edit (mappedValues, apiRole, apiResources) {
      this.activeNames = []
      this.role_id = apiRole.id
      this.role_name = apiRole.name
      this.titleError = null
      this.authenticationToken = apiRole.authentication_token
      this.apiResources = apiResources
      this.mappedValues = mappedValues
      this.getApiMethodIds()
    },

    getApiMethodIds () {
      const god = this

      if (god.activeRequestState) return

      god.activeRequestState = true

      $.ajax({
        type: 'GET',
        url: Routes.api_methods_ids_api_role_path(god.role_id),
        success (apiMethodIds) {
          god.apiMethodIds = apiMethodIds

          Object.keys(god.mappedValues).forEach((resourceName) => {
            Object.keys(god.mappedValues[resourceName]).forEach((key) => {
              if (apiMethodIds.indexOf(parseInt(key)) !== -1) {
                god.mappedValues[resourceName][key] = true
              }
            })
          })

          god.activeRequestState = false
        },
      })
    },

    updateApiRole () {
      const god = this

      if (god.activeRequestState) return

      god.activeRequestState = true

      $.ajax({
        type: 'PUT',
        url: Routes.api_role_path(god.role_id),
        data: {
          api_role: {
            name: god.role_name,
          },
        },
        success (apiRole) {
          god.$emit('updated')
          god.edit(god.mappedValues, apiRole, god.apiResources)
        },
        error ({ status, responseJSON: errors }) {
          if (status !== 422) return

          if (Array.isArray(errors.title) && errors.title.length) {
            god.titleError = errors.title[0]
          }
        },
        complete () {
          Services
            .ui
            .notificator()
            .success(T.changes_updated)
          god.activeRequestState = false
        },
      })
    },

    deleteApiRole () {
      const god = this

      if (god.activeRequestState) return

      god.activeRequestState = true

      $.ajax({
        type: 'DELETE',
        url: Routes.api_role_path(god.role_id),
        success () {
          god.$emit('updated')
          god.apiActionsModal.visibility = false
          god.$emit('close')
          Services
            .ui
            .notificator()
            .success(T.record_successfully_deleted)
        },
        error () {
          Services
            .ui
            .notificator()
            .error(god.T.abstract_error_message, god.T.error, 0)
        },
        complete () {
          god.activeRequestState = false
        },
      })
    },

    showDeleteApiRoleModal () {
      this.apiActionsModal.visibility = true
      this.apiActionsModal.expectedEvent = 'deleteApiRole'
      this.apiActionsModal.headerMessage = T.api_page.roles.api_role_removal
      this.apiActionsModal.message = `${T.api_page.roles.remove_api_role}?`
      this.apiActionsModal.permission = ''
      this.apiActionsModal.actionButtonMessage = T.delete
    },

    showFullResourceAccessModal (resourceName) {
      if (Object.values(this.mappedValues[resourceName]).every((item) => item === true)) return

      this.apiActionsModal.visibility = true
      this.apiActionsModal.expectedEvent = 'fullResourceAccess'
      this.apiActionsModal.headerMessage = T.api_page.roles.api_full_access
      this.apiActionsModal.message = T.api_page.roles.api_method_giving_access_warning
      this.apiActionsModal.permission = T.api_page.roles.allow
      this.apiActionsModal.actionButtonMessage = T.api_page.roles.allow
      this.resourceName = resourceName
    },

    showForbidResourceAccessModal (resourceId, resourceName) {
      if (this.apiMethodIds.length === 0) return

      this.apiActionsModal.visibility = true
      this.apiActionsModal.expectedEvent = 'forbidResourceAccess'
      this.apiActionsModal.headerMessage = T.api_page.roles.api_full_access
      this.apiActionsModal.message = T.api_page.roles.api_method_giving_access_warning
      this.apiActionsModal.permission = T.api_page.roles.forbid
      this.apiActionsModal.actionButtonMessage = T.api_page.roles.forbid
      this.apiMethodIdsToDelete = Object.keys(this.apiResources[resourceId].methods).map((id) => parseInt(id))
      this.resourceId = resourceId
      this.resourceName = resourceName
    },

    addRoleMethod (apiMethodId, apiResourceName) {
      const god = this

      if (god.apiMethodIds.indexOf(parseInt(apiMethodId)) >= 0) return

      god.activeRequestState = true

      $.ajax({
        type: 'PUT',
        url: Routes.api_role_method_path(god.role_id, apiMethodId),
        success () {
          god.apiMethodIds.push(parseInt(apiMethodId))
          god.mappedValues[apiResourceName][apiMethodId] = true
          god.activeRequestState = false
        },
      })
    },

    fullResourceAccess () {
      const god = this
      this.fullResourceAccessHelper()

      god.activeRequestState = true

      $.ajax({
        type: 'PUT',
        url: Routes.update_list_api_role_methods_path(god.role_id),
        data: {
          api_method_ids: god.apiMethodIdsToAdd,
        },
        success () {
          god.apiMethodIdsToAdd.forEach((methodId) => {
            god.mappedValues[god.resourceName][methodId] = true
          })
          god.apiActionsModal.visibility = false
          god.activeRequestState = false
          god.resourceName = ''
          god.apiMethodIdsToAdd = []
        },
      })
    },

    fullResourceAccessHelper () {
      this.apiMethodIdsToAdd = []
      const existedApiMethods = this.apiMethodIds

      Object.keys(this.mappedValues[this.resourceName]).forEach((apiMethodId) => {
        if (existedApiMethods.indexOf(parseInt(apiMethodId)) < 0) {
          this.apiMethodIdsToAdd.push(parseInt(apiMethodId))
          this.apiMethodIds.push(parseInt(apiMethodId))
        }
      })
    },

    forbidResourceAccess () {
      const god = this

      god.activeRequestState = true

      $.ajax({
        type: 'DELETE',
        url: Routes.destroy_list_api_role_methods_path(god.role_id),
        data: {
          api_method_ids: god.apiMethodIdsToDelete,
        },
        success () {
          god.apiMethodIds = god.apiMethodIds.filter((id) => !god.apiMethodIdsToDelete.includes(id))
          Object.keys(god.mappedValues[god.resourceName]).forEach((key) => {
            god.mappedValues[god.resourceName][key] = false
          })
          god.apiActionsModal.visibility = false
          god.activeRequestState = false
          god.resourceId = null
          god.resourceName = ''
          god.apiMethodIdsToDelete = []
        },
      })
    },

    deleteRoleMethod (apiMethodId, apiResourceName) {
      const god = this
      apiMethodId = parseInt(apiMethodId)
      const index = god.apiMethodIds.indexOf(apiMethodId)

      if (index < 0) return

      god.activeRequestState = true

      $.ajax({
        type: 'DELETE',
        url: Routes.api_role_method_path(god.role_id, apiMethodId),
        success () {
          god.apiMethodIds.splice(index, 1)
          god.mappedValues[apiResourceName][apiMethodId] = false
          god.activeRequestState = false
        },
      })
    },

    collapseLists () {
      const collapseList = this.activeNames

      if (collapseList.length > 0) {
        this.activeNames = []
      } else {
        Object.keys(this.apiResources).forEach((apiResourceId) => {
          this.activeNames.push(apiResourceId)
        })
      }
    },

    resetForm () {
      this.role_id = null
      this.role_name = ''
    },

    // 7emv53
    updateTippies () {
      // удаление инстанса, если есть
      const tippyInst = this.tippy_instance
      if (tippyInst !== null) {
        tippyInst.forEach((inst) => {
          inst.destroy()
        })
      }

      // монтирование tippy
      this.tippy_instance = tippy('[data-tippy-content]', { placement: 'left' })
    },
  },
}
</script>
