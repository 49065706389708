<template>
  <modal
    v-if="visibility"
    :modal-visibility="visibility"
    modal-class="api-key-modal"
    @close="$emit('close'), setApiKeyName('')"
  >
    <span
      slot="header"
    >
      {{ headerMessage }}
    </span>
    <span
      slot="body"
      class="flex-form"
    >
      <div
        v-if="!deleteButtonVisibility"
        class="prompt-notice"
      >{{ T.api_page.clients.modal_notice }}</div>
      <div
        class="form-label"
      >
        <label
          class="string required"
          for="api_key_name"
        >
          <span>
            {{ T.activerecord.attributes.api_client.title }}
          </span>
          <abbr
            :title="T.field_required"
          >
            *
          </abbr>
        </label>
      </div>
      <div
        class="key-add-form"
      >
        <input
          id="api-key-title"
          v-model="apiKeyName"
          :placeholder="T.api_page.clients.write_api_key"
          class="form-control string required tooltip-bottom"
          type="text"
          autocomplete="off"
        >
      </div>
    </span>
    <template
      slot="footer-left"
    >
      <button
        v-if="deleteButtonVisibility"
        class="btn btn-danger btn-sm btn-show-delete modal-delete"
        @click.prevent.stop="$emit('deleteApiClientAccessKey', keyId)"
      >
        <span
          class="btn-with-icon_text"
        >
          {{ T.delete }}
        </span>
      </button>
    </template>
    <template
      slot="footer-right"
    >
      <button
        :disabled="!formValid"
        class="btn btn-sm btn-success btn-with-icon modal-save"
        @click.prevent.stop="$emit(expectedEvent, apiKeyName, keyId)"
      >
        <span
          :class="deleteButtonVisibility ? 'btn-with-icon_icon fad fa-fw fa-save': 'btn-with-icon_icon fad fa-fw  fa-plus'"
        />
        <span
          class="btn-with-icon_text"
        >
          {{ actionButtonMessage }}
        </span>
      </button>
      <button
        class="btn btn-sm btn-primary btn-with-icon modal-close"
        @click="$emit('close'), setApiKeyName('')"
      >
        <span
          class="btn-with-icon_icon fad fa-fw  fa-times"
        />
        <span
          class="btn-with-icon_text"
        >
          {{ T.api_page.roles.cancel }}
        </span>
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/vue_components/modal.vue'

export default {
  name: 'ApiKeyModal',

  components: { Modal },

  props: {
    visibility: Boolean,
    actionButtonDisabled: Boolean,
    expectedEvent: { type: String, default: null },
    keyId: { type: Number, default: null },
    deleteButtonVisibility: Boolean,
    headerMessage: { type: String, default: '' },
    actionButtonMessage: { type: String, default: '' },
  },

  data () {
    return {
      apiKeyName: '',
    }
  },

  computed: {
    formValid () {
      return this.apiKeyName && this.apiKeyName.trim().length > 0
    },
  },

  methods: {
    setApiKeyName (apiKeyName) {
      this.apiKeyName = apiKeyName
    },
  },

}
</script>
