<template>
  <report-base
    class="doctor-appointments-report"
    :report="report"
    :schema="schema"
    :profile-type="FILTERS_PROFILES_TYPES.DOCTOR_APPOINTMENTS_REPORT"
    @onResetValidations="resetValidations"
  >
    <div class="report-filters__filter">
      <!-- Клиника -->
      <clinic-filter
        :value="report.filtersData.clinic"
        full-width
        validator-name="clinic"
        @registerValidator="onRegisterValidator"
        @change="report.setFilterValue('clinic', $event)"
      />

      <!-- Дата приема -->
      <m-date-picker
        class="report-filters__filter_periodFilter_clearable"
        :value="report.filtersData.period"
        :label="t('appointment_date')"
        type="daterange"
        value-format="yyyy-MM-dd"
        validator-name="period"
        @registerValidator="onRegisterValidator"
        @change="report.setFilterValue('period', $event)"
      />

      <!-- Дата создания записи -->
      <m-date-picker
        class="report-filters__filter_periodFilter_clearable"
        :value="report.filtersData.createdDate"
        :label="t('created_date')"
        type="daterange"
        value-format="yyyy-MM-dd"
        validator-name="createdDate"
        @registerValidator="onRegisterValidator"
        @change="report.setFilterValue('createdDate', $event)"
      />

      <!-- Новые пациенты -->
      <switch-filter
        :value="report.filtersData.newPatients"
        :active-text="t('new_patients')"
        validator-name="newPatients"
        @registerValidator="onRegisterValidator"
        @change="report.setFilterValue('newPatients', $event)"
      />

      <!-- Услуги -->
      <reusable-entry-types-list
        :value="report.filtersData.entryTypes"
        m-fixed-height
        full-width
        use-new-results-only
        validator-name="entryTypes"
        @registerValidator="onRegisterValidator"
        @changeEntryTypes="report.setFilterValue('entryTypes', $event)"
      />

      <!-- Группы клиентов -->
      <client-groups-filter
        :value="report.filtersData.clientGroups"
        multiple
        validator-name="clientGroups"
        @registerValidator="onRegisterValidator"
        @change="report.setFilterValue('clientGroups', $event)"
      />

      <!-- Тип платежа -->
      <m-select
        :value="report.filtersData.paymentType"
        :items="PAYMENT_TYPE_ITEMS"
        :label="t('payment_kind')"
        :placeholder="t('all_payment_kinds')"
        full-width
        validator-name="paymentType"
        @registerValidator="onRegisterValidator"
        @change="report.setFilterValue('paymentType', $event)"
      />

      <!-- Тип приема -->
      <appointment-types-filter
        :value="report.filtersData.appointmentTypes"
        multiple
        validator-name="appointmentTypes"
        @registerValidator="onRegisterValidator"
        @change="report.setFilterValue('appointmentTypes', $event)"
      />

      <!-- Статус -->
      <m-select
        :value="report.filtersData.appointmentStatuses"
        :items="APPOINTMENT_STATUSES_ITEMS"
        :label="t('status')"
        :placeholder="t('all_statuses')"
        multiple
        full-width
        filterable
        :custom-filter="defaultSearchFilter"
        validator-name="appointmentStatuses"
        @registerValidator="onRegisterValidator"
        @change="report.setFilterValue('appointmentStatuses', $event)"
      />

      <!-- Источник привлечения -->
      <attraction-sources-filter
        :value="report.filtersData.attractionSources"
        multiple
        validator-name="attractionSources"
        @registerValidator="onRegisterValidator"
        @change="report.setFilterValue('attractionSources', $event)"
      />

      <!-- Тип источника -->
      <appointment-sources-filter
        :value="report.filtersData.appointmentSources"
        multiple
        validator-name="appointmentSources"
        @registerValidator="onRegisterValidator"
        @change="report.setFilterValue('appointmentSources', $event)"
      />

      <!-- Администратор -->
      <reusable-doctors-list
        :value="report.filtersData.administrator"
        :label="t('administrator')"
        :user-group="USER_GROUP_IDS.STAFF"
        :placeholder="t('reception.all_administrators')"
        full-width
        m-fixed-height
        validator-name="administrator"
        @registerValidator="onRegisterValidator"
        @onDoctorChange="report.setFilterValue('administrator', $event)"
      />

      <!-- Доктор -->
      <reusable-doctors-list
        :value="report.filtersData.doctor"
        :placeholder="t('all_doctors')"
        full-width
        m-fixed-height
        validator-name="doctor"
        @registerValidator="onRegisterValidator"
        @onDoctorChange="report.setFilterValue('doctor', $event)"
      />

      <!-- Реферал -->
      <referral-list-add
        :value="report.filtersData.referral"
        :placeholder="t('reports.all_referrals')"
        :use-add="false"
        full-width
        m-fixed-height
        validator-name="referral"
        @registerValidator="onRegisterValidator"
        @change="report.setFilterValue('referral', $event)"
      />
    </div>
  </report-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ReportBase from '@/vue_apps/ReportsModule/_ReportBase/ReportBase.vue'
import { DoctorAppointmentsReport } from '@/vue_apps/ReportsModule/DoctorAppointments/classes/DoctorAppointmentsReport'
import {
  getDoctorAppointmentsReportSchema,
} from '@/vue_apps/ReportsModule/DoctorAppointments/consts/getDoctorAppointmentsReportSchema'
import ReferralListAdd from '@/vue_present/Reuse/ReferralListAdd/ReferralListAdd.vue'
import AttractionSourcesFilter from '@/vue_apps/ReportsModule/_filters/components/AttractionSourcesFilter.vue'
import ClinicFilter from '@/vue_apps/ReportsModule/_filters/components/ClinicFilter.vue'
import ClientGroupsFilter from '@/vue_apps/ReportsModule/_filters/components/ClientGroupsFilter.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import ReusableEntryTypesList from '@/vue_present/Reuse/Lists/EntryTypes/ReusableEntryTypesList.vue'
import AppointmentTypesFilter from '@/vue_apps/ReportsModule/_filters/components/AppointmentTypesFilter.vue'
import AppointmentSourcesFilter from '@/vue_apps/ReportsModule/_filters/components/AppointmentSourcesFilter.vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { FILTERS_PROFILES_TYPES } from '@/vue_apps/FiltersProfilesApp/const/consts'
import { USER_GROUP_IDS } from '@/_global_scripts/GLOBAL_CONSTS'
import { APPOINTMENT_STATUSES_ITEMS } from '@/vue_apps/ReportsModule/DoctorAppointments/consts/appointmentStatuses'
import { PAYMENT_TYPE_ITEMS } from '@/vue_apps/ReportsModule/DoctorAppointments/consts/paymentTypeItems'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import SwitchFilter from '@/vue_apps/ReportsModule/_filters/components/share/SwitchFilter.vue'
import { defaultSearchFilter } from '@/helpers/lambda'

export default defineComponent({
  name: 'DoctorAppointmentsReportApp',

  components: {
    SwitchFilter,
    MDatePicker,
    ReusableDoctorsList,
    AppointmentSourcesFilter,
    AppointmentTypesFilter,
    ReusableEntryTypesList,
    MSelect,
    ClientGroupsFilter,
    ClinicFilter,
    AttractionSourcesFilter,
    ReferralListAdd,
    ReportBase,
  },

  mixins: [ValidationParentMixin],

  data () {
    return {
      FILTERS_PROFILES_TYPES,
      USER_GROUP_IDS,
      APPOINTMENT_STATUSES_ITEMS,
      PAYMENT_TYPE_ITEMS,
      report: new DoctorAppointmentsReport(),
      schema: getDoctorAppointmentsReportSchema(),
    }
  },

  methods: { defaultSearchFilter },
})
</script>
