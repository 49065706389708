import SiGeneratorExample from '@/vue_components/sort_items/SiGenerator/SiGeneratorExample'
import { createVueApp } from '@/vue_components/create_vue_app'

export const createSiGeneratedVue = (el) => {
  if (!document.querySelector(el)) { return }

  createVueApp({
    el,
    name: 'SiGeneratorExample',
    render: (h) => h(SiGeneratorExample),
  })
}
