import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import {
  IDoctorAppointmentsReportDataToClientDTO,
} from '@/vue_apps/ReportsModule/DoctorAppointments/interfaces/IDoctorAppointmentsReportToClientDTO'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { IClient } from '@/_declarations/IClient'
import {
  DOCTOR_APPOINTMENTS_STATUS_ICON_MAP,
} from '@/vue_apps/ReportsModule/DoctorAppointments/consts/doctorAppointmentsStatusIconMap'

export class DoctorAppointmentsReportItemCell extends MListServiceItem {
  dateCell: MTableCell

  timeCell: MTableCell

  statusCell: MTableCell

  attractionSourceCell: MTableCell

  client: IClient

  clientCell: MTableCell

  phoneCell: MTableCell

  entryTypesCell: MTableCell

  administratorCell: MTableCell

  createdAtCell: MTableCell

  doctorCell: MTableCell

  noteCell: MTableCell

  constructor (props: IDoctorAppointmentsReportDataToClientDTO) {
    super()

    this.id = props.id

    this.dateCell = new MTableCell(Utils.getFormattedDate(props.date))

    this.timeCell = new MTableCell(props.time)

    this.statusCell = this.getStatusCell(props.status)

    this.attractionSourceCell = new MTableCell(props.attractionSource.title)
      .addTooltip(true)
      .addCut()

    this.client = props.client

    this.clientCell = new MTableCell(vueFilters.fullName(props.client))
      .addTypeUrl(Routes.client_path(props.client.id))
      .addTooltip(true)
      .addCut()

    this.phoneCell = new MTableCell()
      .addTypePhone(props.client.id, `+${Services.phoneMask.setMask(props.client.phone)}`)

    this.entryTypesCell = new MTableCell(props.entryTypes.map(({ title }) => title).join(', ') || '-')
      .addTooltip(true)
      .addCut()

    this.administratorCell = new MTableCell(vueFilters.shortName(props.administrator))
      .addTooltip(true)
      .addCut()

    this.createdAtCell = new MTableCell(vueFilters.date(props.createdAt))

    this.doctorCell = new MTableCell(vueFilters.shortName(props.doctor))
      .addTooltip(true)
      .addCut()

    this.noteCell = new MTableCell(props.note || '-')
      .addTooltip(true)
      .addCut()
  }

  private getStatusCell (statusCode: number) {
    const statusIcon = DOCTOR_APPOINTMENTS_STATUS_ICON_MAP.at(statusCode - 1)

    return new MTableCell()
      .addTooltip(statusIcon.title)
      .addTypeIcon(statusIcon.icon)
      .addClasses(statusIcon.classes)
  }
}
