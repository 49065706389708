import { MReportListService } from '@/_api/_requests/MReportListService'
import { getDefaultFilters } from '@/vue_apps/ReportsModule/DoctorAppointments/consts/getDefaultFilters'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import {
  DoctorAppointmentsReportPresenter,
} from '@/vue_apps/ReportsModule/DoctorAppointments/api/DoctorAppointmentsReportPresenter'
import {
  doctorAppointmentsReportAdapter,
} from '@/vue_apps/ReportsModule/DoctorAppointments/api/doctorAppointmentsReportAdapter'
import {
  doctorAppointmentsReportSortingMap,
} from '@/vue_apps/ReportsModule/DoctorAppointments/consts/doctorAppointmentsReportSortingMap'

export class DoctorAppointmentsReport extends MReportListService {
  constructor () {
    super(
      getDefaultFilters(),
      new DoctorAppointmentsReportPresenter(),
      {
        sortingMap: doctorAppointmentsReportSortingMap,
        limit: DEFAULT_LIMIT,
        useGeneralFiltersString: true,
      }
    )
  }

  async export () {
    return new DoctorAppointmentsReportPresenter()
      .export(doctorAppointmentsReportAdapter.toExport(this.filtersData))
  }
}
