import WidgetCategoriesPage from '@/vue_components/online_recording_categories/online_recording_categories.vue'
import { createVueApp } from '@/vue_components/create_vue_app'

$document.on('rez/online_recording_categories/index', () => {
  const el = '#online_recording_categories_container'

  if (!document.querySelector(el)) { return }

  return createVueApp({
    el,
    name: 'WidgetCategoriesPageApp',
    render: (h) => h(WidgetCategoriesPage),
  })
})
