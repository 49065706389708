import { createVueApp } from '@/vue_components/create_vue_app'
import TreatmentPlanClinicsApp from '@/vue_apps/TreatmentPlans/TreatmentPlanClinicsApp/TreatmentPlanClinicsApp.vue'

let vueApp = null
export const createTreatmentPlanClinicsApp = (el = '#treatment_plan_clinic_id') => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'VueTreatmentPlanClinicsAppRoot',
    render: (h) => h(TreatmentPlanClinicsApp, {
      props: {
        elementId: el.slice(1),
      },
    }),
  })
}
