<template>
  <div
    class="avatar-edit"
    tabindex="0"
    @mouseenter="showAvatarControl = true"
    @mouseleave="showAvatarControl = false"
    @blur="showAvatarControl = false"
  >
    <epic-spinner
      :visibility="epicSpinnerVisibility"
    />
    <div
      v-show="showAvatarControl"
      class="control"
    >
      <div class="buttons">
        <label
          class="button upload tooltip-bottom "
          for="imageInput"
          :title="T.avatar.upload_avatar"
        >
          <i class="fad fa-upload up" />
        </label>
        <div
          v-if="logoIs"
          class="button remove tooltip-bottom"
          :title="T.avatar.remove_avatar"
          @click="showConfirmingAvatarRemovalModal = true"
        >
          <div class="fad fa-times" />
        </div>
      </div>
    </div>

    <img
      class="image"
      :src="avatarSource"
    >

    <input
      id="imageInput"
      ref="imageInput"
      type="file"
      accept="image/jpeg, image/png, image/gif"
      @input="showModalImageCropper = true; moveToCropSelectedAvatar($event)"
    >
    <modal-image-cropper
      :preview="true"
      :modal-class="{ class: 'avatar' }"
      :targets="TARGETS_FOR_IMAGE_CROPPER"
      :visible="showModalImageCropper"
      @close="showModalImageCropper = false"
    >
      <span slot="header">
        {{ T.avatar.profile_photo_update }}
      </span>
      <span slot="footer-right">
        <button
          class="btn btn-success btn-with-icon moda"
          type="button"
          @click="cropAvatar(); showModalImageCropper = false"
        >
          <div class="btn-with-icon_icon fad fa-cut" />
          <div class="btn-with-icon_text"> {{ T.save_changes }} </div>
        </button>
      </span>
    </modal-image-cropper>

    <confirmation-modal
      :header-message="T.warning"
      :message="T.avatar.photo_removal_warning"
      :modal-visibility="showConfirmingAvatarRemovalModal"
      :modal-class="'confirming-avatar-removal-modal'"
      @yes="$emit('remove-avatar'); showConfirmingAvatarRemovalModal = false"
      @no="showConfirmingAvatarRemovalModal = false"
    />
    <slot />
  </div>
</template>

<script>
import EpicSpinner from '../epic_spinner/epic_spinner.vue'
import ModalImageCropper from '../modal_image_cropper.vue'
import ConfirmationModal from '../confirmation_modal.vue'

export default {
  name: 'Avatar',
  components: {
    ModalImageCropper,
    ConfirmationModal,
    EpicSpinner,
  },
  props: {
    epicSpinnerVisibility: Boolean,
    logoIs: Boolean,
    avatarSource: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      showModalImageCropper: false,
      showConfirmingAvatarRemovalModal: false,
      showAvatarControl: false,
      file: null,
      TARGETS_FOR_IMAGE_CROPPER: Object.freeze({
        SET_IMAGE: 'IMAGE_CROPPER.SET_AVATAR',
        CROP_IMAGE: 'IMAGE_CROPPER.CROP_AVATAR',
        CROPPED_IMAGE: 'IMAGE_CROPPER.CROPPED_AVATAR',
      }),
    }
  },
  mounted () {
    this.$pubSub.subscribe(this.TARGETS_FOR_IMAGE_CROPPER.CROPPED_IMAGE, (canvas) => {
      const { type, name } = this.file

      canvas.toBlob((blob) => {
        const file = new File([blob], name, { type })
        this.$emit('cropped-avatar', file)
      })

      this.$emit('cropped-avatar-base64', canvas.toDataURL())
    })
  },
  methods: {
    moveToCropSelectedAvatar (event) {
      this.$nextTick(() => {
        this.file = event.target.files[0]
        this.$pubSub.emit(this.TARGETS_FOR_IMAGE_CROPPER.SET_IMAGE, this.file)
        this.$refs.imageInput.value = ''
      })
    },
    cropAvatar () {
      this.$pubSub.emit(this.TARGETS_FOR_IMAGE_CROPPER.CROP_IMAGE)
    },
  },
}
</script>
