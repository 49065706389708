<template>
  <div class="schedule-template-body-right">
    <div class="folding-buttons">
      <button
        class="btn btn-sm btn-primary btn-with-icon show-templates"
        @click="_changeTemplateGroupsVisibility(true)"
      >
        <span class="btn-with-icon_icon fad fa-angle-down" />
        <span class="btn-with-icon_text">{{ T.show_all }}</span>
      </button>
      <button
        class="btn btn-sm btn-primary btn-with-icon hide-templates"
        @click="_changeTemplateGroupsVisibility(false)"
      >
        <span class="btn-with-icon_icon fad fa-angle-up" />
        <span class="btn-with-icon_text">{{ T.hide_all }}</span>
      </button>
    </div>
    <div class="schedule-templates">
      <div
        v-for="userTemplateGroup in userTemplateGroups"
        :key="userTemplateGroup.user.id"
        class="user-template-group"
      >
        <div
          class="template-group-header"
          @click="_switchGroupVisibility(userTemplateGroup)"
        >
          <div class="user-name-container">
            {{ userTemplateGroup.user.full_name }}
          </div>
          <i
            :class="['fad', userTemplateGroup.visible ? 'fa-angle-up' : 'fa-angle-down']"
            class="template-group-visibility-button"
          />
        </div>
        <div class="schedule-templates-container">
          <div
            v-for="template in userTemplateGroup.templates"
            :key="template.id"
            class="schedule-template"
          >
            <div
              class="schedule-template-header"
              @click="_switchGroupVisibility(userTemplateGroup)"
            >
              <div class="interval">
                <span class="interval-time">{{ template.startTime }}</span>
                <span>—</span>
                <span class="interval-time">{{ template.endTime }}</span>
                <span
                  v-show="!userTemplateGroup.visible"
                  class="cabinet-title"
                >
                  {{ _getCabinetTitle(template.template) }}
                </span>
              </div>
              <div
                v-show="userTemplateGroup.visible"
                class="remove-work-time"
                @click="_removeScheduleTemplateHandler(userTemplateGroup, template)"
              >
                <i class="fad fa-trash" />
              </div>
            </div>
            <div
              v-show="userTemplateGroup.visible"
              class="schedule-template-form-body"
            >
              <div class="schedule-template-form-item">
                <div class="schedule-template-form-left">
                  <label>
                    <span>{{ T.cabinet }}</span>
                  </label>
                </div>
                <div class="schedule-template-form-right">
                  <el-select
                    v-model="template.cabinetId"
                    :placeholder="T.without_cabinet"
                    clearable
                  >
                    <el-option
                      v-for="cabinet in selfCabinets"
                      :key="cabinet.id"
                      :label="cabinet.title"
                      :value="cabinet.id"
                    />
                  </el-select>
                </div>
              </div>
              <div class="schedule-template-form-item">
                <div class="schedule-template-form-left">
                  <label>
                    <span>{{ T.period }}</span>
                  </label>
                </div>
                <div class="schedule-template-form-right">
                  <el-time-select
                    v-model="template.startTime"
                    :picker-options="{ start: start, end: end, step: step, maxTime: template.endTime }"
                    :clearable="false"
                  />
                  <div class="time-picker-divider">
                    —
                  </div>
                  <el-time-select
                    v-model="template.endTime"
                    :picker-options="{ start: start, end: end, step: step, minTime: template.startTime }"
                    :clearable="false"
                  />
                </div>
              </div>
              <div class="schedule-template-form-item">
                <div class="schedule-template-form-left">
                  <label>
                    <span>{{ T.repeat }}</span>
                  </label>
                </div>
                <div class="schedule-template-form-right">
                  <items-list
                    v-model="template.templateTypeId"
                    v-bind="templateTypeProps"
                  />
                </div>
              </div>
              <div
                v-if="template.templateTypeId.includes(DAY_INTERVAL_TEMPLATE)"
                class="schedule-template-form-item"
              >
                <div class="schedule-template-form-left">
                  <label>
                    <span>{{ T.each }}</span>
                  </label>
                </div>
                <div class="schedule-template-form-right">
                  <items-list
                    v-model="template.templateDayIntervalId"
                    v-bind="templateDayIntervalProps"
                  />
                </div>
              </div>
              <template v-if="template.templateTypeId.includes(WEEK_INTERVAL_TEMPLATE)">
                <div class="schedule-template-form-item">
                  <div class="schedule-template-form-left">
                    <label>
                      <span>{{ T.every }}</span>
                    </label>
                  </div>
                  <div class="schedule-template-form-right">
                    <items-list
                      v-model="template.templateWeekIntervalId"
                      v-bind="templateWeekIntervalProps"
                    />
                  </div>
                </div>
                <div class="schedule-template-form-item">
                  <div class="schedule-template-form-left">
                    <label>
                      <span>{{ T.in_days }}</span>
                    </label>
                  </div>
                  <div class="schedule-template-form-right">
                    <items-list
                      v-model="template.templateSelectedDaysIds"
                      v-bind="templateDaySelectProps"
                    />
                  </div>
                </div>
              </template>
              <div
                v-if="onlineRecordingEnabled"
                class="schedule-template-form-item"
              >
                <div class="schedule-template-form-left">
                  <label>
                    <span>{{ T.client_widget.online_recording }}</span>
                  </label>
                </div>
                <div class="schedule-template-form-right">
                  <items-list
                    v-model="template.availabilityForOnlineRecording"
                    v-bind="templateAvailabilityForOnlineRecordingProps"
                  />
                </div>
              </div>
              <div class="schedule-template-form-item">
                <div class="schedule-template-form-left">
                  <label>
                    <span>{{ T.extendable }}</span>
                  </label>
                </div>
                <div class="schedule-template-form-right">
                  <items-list
                    v-model="template.templateActive"
                    v-bind="templateActiveProps"
                  />
                </div>
              </div>
              <div
                v-show="template.templateActive.includes('true')"
                class="schedule-template-form-item"
              >
                <div class="schedule-template-form-left">
                  <label>
                    <span>{{ T.extend_to }}</span>
                  </label>
                </div>
                <div
                  :class="[_templateExpired(template.template) && 'template-expired']"
                  class="schedule-template-form-right"
                >
                  <el-date-picker
                    v-model="template.propagationDate"
                    :clearable="false"
                    :picker-options="_getDatePickerOptions(template)"
                    :format="'dd.MM.yyyy'"
                    type="date"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm'
import ItemsList from '../../../../items_list.vue'
import TemplateFactory from '../isomorphic_templates/factory/template_factory.js'
import { scheduleTemplateFormConfiguration } from '../modal_form_config.js'
import {
  DAY_INTERVAL_TEMPLATE,
  SCHEDULE_TEMPLATE_FACTORY,
  WEEK_INTERVAL_TEMPLATE,
  WITHOUT_CABINET_ID,
} from '../../../const.js'

export default {
  name: 'ScheduleTemplateModalBodyRight',
  components: { ItemsList },
  props: {
    users: { type: Array, required: true },
    usersMap: { type: Object, required: true },
    cabinets: { type: Array, required: true },
    cabinetsMap: { type: Object, required: true },
    onlineRecordingEnabled: { type: Boolean, required: true },
    allCIDs: { type: Array, required: true },
  },
  data () {
    return {
      ...scheduleTemplateFormConfiguration,
      DAY_INTERVAL_TEMPLATE,
      WEEK_INTERVAL_TEMPLATE,
      selfCabinets: null,
      currentDate: new Date().setHours(0, 0, 0, 0),
      userTemplateGroups: [],
      templatesWithChanges: new Map(),
      userTemplateGroupsVisibility: false,
      templateGroupsVisibilityMap: new Map(),
      targetTemplateGroup: null,
    }
  },
  created () {
    const factoryOptions = {
      availableCabinetsIds: this.allCIDs,
    }

    this.templateFactory = new TemplateFactory(SCHEDULE_TEMPLATE_FACTORY, factoryOptions)
    this.selfCabinets = this.cabinets.filter((cabinet) => cabinet.id !== WITHOUT_CABINET_ID)
  },
  methods: {
    setScheduleTemplates (scheduleTemplates) {
      this._updateTemplatesWithChanges()

      const newUserTemplateGroups = []
      let user
      let templates

      Object.keys(scheduleTemplates).forEach((userId) => {
        if (!(user = this.usersMap[userId])) return

        templates = scheduleTemplates[userId].map((template) => {
          if (this.templatesWithChanges.has(template.id)) {
            return this.templatesWithChanges.get(template.id)
          }

          return this.templateFactory.getPersistentTemplate(template)
        })

        templates.sort((template1, template2) => {
          return template1.startTime.localeCompare(template2.startTime)
        })

        newUserTemplateGroups.push({
          user,
          templates,
          visible: this._getGroupVisibility(user),
        })
      })

      newUserTemplateGroups.sort((group1, group2) => {
        return group1.user.full_name.localeCompare(group2.user.full_name)
      })

      this.userTemplateGroups = newUserTemplateGroups
    },

    updateTargetTemplateGroup (data) {
      const target = this.targetTemplateGroup
      const newLastPropagationDate = window.moment(data.start_date_of_deletion).subtract(1, 'days').toDate()
      const template = target.templates.find((template) => template.id === data.template_id).template
      template.last_propagation_end_date = Utils.dateJStoRuby(newLastPropagationDate)
      const newIsomorphicTemplate = this.templateFactory.getPersistentTemplate(template)

      for (let i = 0; i < target.templates.length; i++) {
        if (target.templates[i].id === template.id) {
          Vue.set(target.templates, i, newIsomorphicTemplate)
          break
        }
      }
    },

    removeIsomorphicTemplateById (templateId) {
      const target = this.targetTemplateGroup

      if (target.templates.length <= 1) {
        this.userTemplateGroups = this.userTemplateGroups.filter((group) => group !== target)
      } else {
        target.templates = target.templates.filter((template) => template.id !== templateId)
      }
    },

    getTemplatesWithChanges () {
      this._updateTemplatesWithChanges()

      return Array.from(this.templatesWithChanges.values())
    },

    _updateTemplatesWithChanges () {
      this.userTemplateGroups.forEach((group) => {
        group.templates.forEach((template) => {
          if (template.dependentWorkTimesChanges() || template.scheduleTemplateChanges()) {
            this.templatesWithChanges.set(template.id, template)
          } else {
            this.templatesWithChanges.delete(template.id)
          }
        })
      })
    },

    _getDatePickerOptions (template) {
      const closureStartPropagationDate =
        window.moment(template.template.last_propagation_end_date).subtract(1, 'days').toDate()

      return {
        firstDayOfWeek: 1,
        disabledDate (time) { return time.getTime() <= closureStartPropagationDate },
      }
    },

    _getCabinetTitle ({ cabinet_id }) {
      return cabinet_id ? this.cabinetsMap[cabinet_id].title : T.without_cabinet.toLowerCase()
    },

    _changeTemplateGroupsVisibility (visibility) {
      this.userTemplateGroupsVisibility = visibility
      for (const uid of this.templateGroupsVisibilityMap.keys()) {
        this.templateGroupsVisibilityMap.set(uid, visibility)
      }
      this.userTemplateGroups.forEach((group) => { group.visible = visibility })
    },

    _switchGroupVisibility (userTemplateGroup) {
      const userId = userTemplateGroup.user.id
      const current = this.templateGroupsVisibilityMap.get(userId)
      userTemplateGroup.visible = !current
      this.templateGroupsVisibilityMap.set(userId, !current)
    },

    _getGroupVisibility ({ id }) {
      if (this.templateGroupsVisibilityMap.has(id)) {
        return this.templateGroupsVisibilityMap.get(id)
      }
      this.templateGroupsVisibilityMap.set(id, this.userTemplateGroupsVisibility)

      return this.userTemplateGroupsVisibility
    },

    _templateExpired (template) {
      return template.active && moment(template.last_propagation_end_date).valueOf() <= this.currentDate
    },

    _removeScheduleTemplateHandler (targetTemplateGroup, template) {
      this.targetTemplateGroup = targetTemplateGroup
      const doctorFullName = this.usersMap[template.template.user_id].full_name

      const data = {
        template,
        doctorFullName,
      }

      this.$emit('removeScheduleTemplate', data)
    },
  },
}
</script>
