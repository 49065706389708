import clientWidgetConfiguration from '@/vue_components/client_widget/configuration.vue'
import { createVueApp } from '@/vue_components/create_vue_app'

$document.on('rez/client_widget/configurations/edit', () => {
  if (gon.specific.moduleIsEnabled) {
    createVueApp({
      el: '#client_widget_settings_container',
      name: 'ClientWidgetConfigurationApp',
      render: (h) => h(clientWidgetConfiguration),
    })
  }
})
