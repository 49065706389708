<template>
  <modal
    v-if="visible"
    :modal-visibility="visible"
    :modal-class="'merge_client_modal'"
    @close="visible = false"
  >
    <div slot="header">
      <span class="fad fa-user-friends" />
      {{ T.duplicates_merging }}
    </div>
    <div
      slot="body"
    >
      <epic-spinner :visibility="showEpicSpinner" />
      <items-list
        v-model="currentFilter"
        v-bind="filterProps"
      />
      <div>
        <table class="table table-striped merge-client-table">
          <thead>
            <tr>
              <th class="checked">
                <input
                  v-model="massCheck"
                  type="checkbox"
                  class="checkbox"
                >
              </th>
              <th class="number">
                №
              </th>
              <th class="fullname">
                {{ T.full_name }}
              </th>
              <th class="phone">
                {{ T.phone }}
              </th>
              <th class="birthdate">
                {{ T.birthdate }}
              </th>
              <th class="created_at">
                {{ T.created_at }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in duplicates"
              :key="item.id"
            >
              <td class="checked">
                <input
                  v-model="item.checked"
                  type="checkbox"
                  class="checkbox"
                >
              </td>
              <td class="number">
                {{ item.id }}
              </td>
              <td class="fullname">
                <a
                  :href="$routes.client_path(item.id)"
                  target="_blank"
                >
                  {{ item.full_name }}
                </a>
              </td>
              <td class="phone">
                <span
                  v-if="item.formatted_phone"
                  class="phone_number callable"
                  @click="triggerPhonePopover($event, item.id, item.formatted_phone)"
                >
                  {{ item.formatted_phone }}
                </span>
                <span
                  v-else
                  class="phone_number callable"
                  @click="triggerPhonePopover($event, item.id, item.phone)"
                >
                  {{ item.masked_phone }}
                </span>
              </td>
              <td class="birthdate">
                {{ item.formatted_birthdate }}
              </td>
              <td class="created_at">
                {{ item.formatted_created_at }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div slot="footer-right">
      <button
        class="btn btn-sm btn-success modal-save btn-with-icon"
        :disabled="isSubmitDisabled"
        @click="submit"
      >
        <span class="btn-with-icon_icon fad fa-object-group" />
        <span class="btn-with-icon_text">{{ t('merge') }}</span>
      </button>
      <button
        class="btn btn-sm btn-primary modal-close btn-with-icon"
        @click="visible = false"
      >
        <span class="btn-with-icon_icon fad fa-times" />
        <span class="btn-with-icon_text">{{ t('close') }}</span>
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from '@/vue_components/modal.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import ItemsList from '@/vue_components/items_list.vue'

const FULLNAME_AND_BIRHTDATE = 'fullname_birthdate'
const PHONE = 'phone'
const ALL = 'all'

export default {
  name: 'MergeClientModal',
  components: {
    Modal,
    EpicSpinner,
    ItemsList,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      visible: false,
      showEpicSpinner: true,
      duplicates: [],
      lockSubmit: false,
      currentFilter: [ALL],
      filterProps: Object.freeze({
        items: [
          { selected: ALL, title: T.all },
          { selected: FULLNAME_AND_BIRHTDATE, title: T.reports.by_fullname_and_birthdate },
          { selected: PHONE, title: T.reports.by_phone },
        ],
        minSelectedItems: 1,
        multiSelect: false,
        idItemField: 'selected',
        displayedItemField: 'title',
      }),
    }
  },
  computed: {
    isSubmitDisabled () {
      return !this.duplicates.some((item) => item.checked) || this.lockSubmit
    },
    massCheck: {
      get () {
        return this.duplicates.length && this.duplicates.every((item) => item.checked)
      },
      set (bool) {
        this.setChecked(bool)
      },
    },
  },
  watch: {
    currentFilter (value) {
      this.fetchDuplicates(value[0])
    },
  },
  created () {
    this.$pubSub.subscribe('MERGE_CLIENT_MODAL.SHOW', () => {
      this.visible = true
      this.fetchDuplicates(this.currentFilter[0])
    })
  },
  methods: {
    setChecked (val) {
      this.duplicates.forEach((item) => (item.checked = val))
    },
    fetchDuplicates (filter) {
      this.duplicates = []
      this.showEpicSpinner = true
      $.ajax({
        url: Routes.duplicates_client_path(this.client.id),
        method: 'GET',
        data: {
          query_type: filter,
        },
        success: (duplicates) => {
          this.duplicates = duplicates.map((item) => {
            item.checked = false

            return item
          })
        },
        error (err) {
          console.log(err.responseJSON)
        },
        complete: () => {
          this.showEpicSpinner = false
        },
      })
    },
    triggerPhonePopover (event, clientId, phone) {
      const data = {
        phoneNumber: phone,
        clientId,
        element: event.target,
      }
      this.$pubSub.emitAsync('PHONE_POPOVER.SHOW', data)
    },
    submit () {
      bootbox.confirmYN(T.do_you_want_to_merge_duplicates, (res) => {
        if (!res) return

        const checkedItemIds = this
          .duplicates
          .filter((item) => item.checked)
          .map((item) => item.id)
        this.lockSubmit = true
        $.ajax({
          url: Routes.merge_duplicates_client_path(this.client.id),
          method: 'POST',
          data: {
            duplicate_ids: checkedItemIds,
          },
          success: (duplicateIds) => {
            this.duplicates = this.duplicates.filter((item) => !duplicateIds.includes(item.id))
            if (this.duplicates.length === 0) this.visible = false
            Notificator.success(T.successful_merge)
          },
          error (ex) {
            console.log(ex)
            Notificator.error(T.error_occurred)
          },
          complete: () => {
            this.lockSubmit = false
          },
        })
      })
    },
  },
}
</script>
