import { createVueApp } from '@/vue_components/create_vue_app'
import AnalysisLaboratories from '@/vue_apps/catalogs_root/analysis_laboratories/AnalysisLaboratories.vue'

export const createAnalysesLaboratoriesVue = (el) => {
  createVueApp({
    el,
    name: 'AnalysisLaboratoriesRoot',
    render: (h) => h(AnalysisLaboratories),
  })
}
