import { createVueApp } from '@/vue_components/create_vue_app'
import ServiceRate from '@/vue_present/ServiceRate/ServiceRate.vue'
import { SERVICES_PERFORMERS } from 'src/vue_present/ServiceRate/consts/const'

export const createUserServicesRateApp = (el = '#vue_user_services_app') => {
  if (!el || typeof el !== 'string') { return }
  if (!document.querySelector(el)) { return }

  return createVueApp({
    el,
    name: 'UserServicesApp',
    render: (h) => h(ServiceRate, {
      props: {
        performerId: parseInt(gon.page.params.id),
        performerType: SERVICES_PERFORMERS.USER,
      },
    }),
  })
}
