<template>
  <div
    v-show="show"
    class="panel panel-primary panel-edit"
  >
    <epic-spinner
      :visibility="activeRequestState"
    />
    <api-actions-modal
      v-bind="WidgetActionsModal"
      @close="WidgetActionsModal.visibility = false"
      @deleteCategory="deleteCategory"
    />
    <div
      class="panel-heading panel-heading-flex"
    >
      <div
        class="panel-heading-title"
      >
        <span
          class="panel-heading-icon fad fa-globe"
        />
        <h3 class="panel-title">
          {{ T.client_widget.category_editing }}
        </h3>
      </div>
      <div
        class="panel-heading-buttons"
      >
        <span
          class="panel_close_button fad fa-times hover-white-icon"
          @click="$emit('close')"
        />
      </div>
    </div>
    <div
      class="panel-body"
    >
      <form
        class="simple_form flex-form"
      >
        <div
          class="form-label"
        >
          <label
            class="string required"
            for="widget_category_title"
          >
            <span>
              {{ T.client_widget.category_title }}
            </span>
            <abbr
              :title="T.field_required"
            >
              *
            </abbr>
          </label>
        </div>
        <div
          class="form-value"
        >
          <div
            :class="titleError ? 'has-error' : ''"
            class="form-group string required widget_category_title"
          >
            <input
              id="widget_category_title"
              v-model="title"
              class="form-control string required tooltip-bottom"
              type="text"
              autocomplete="off"
            >
            <div
              v-if="titleError"
              class="under_field_error"
            >
              {{ titleError }}
            </div>
          </div>
        </div>
        <div
          class="form-label"
        >
          <label
            for="widget_category_description"
          >
            <span>
              {{ T.client_widget.category_description }}
            </span>
          </label>
        </div>
        <div
          class="form-value"
        >
          <div
            class="form-group string widget_category_description"
          >
            <el-input
              v-model="description"
              type="textarea"
              :autosize="{ minRows: 4}"
            />
          </div>
        </div>
      </form>
      <div
        class="form-btns"
      >
        <div>
          <button
            :disabled="!formValid || activeRequestState"
            class="btn btn-success btn-sm btn-with-icon"
            @click="update"
          >
            <span class="btn-with-icon_icon fad fa-save" />
            <span class="btn-with-icon_text">{{ t('save') }}</span>
          </button>
        </div>
        <span
          class="fad fa-fw fa-trash btn-show-delete delete-record-button"
          @click="showDeleteApiRoleModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import ApiActionsModal from '@/vue_components/api/api_actions_modal.vue'

export default {
  name: 'WidgetCategoriesEdit',

  components: {
    EpicSpinner,
    ApiActionsModal,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      activeRequestState: false,
      WidgetActionsModal: {
        visibility: false,
        actionButtonDisabled: false,
        expectedEvent: null,
        headerMessage: '',
        message: '',
        permission: '',
        actionButtonMessage: '',
      },

      widget_category_id: null,
      title: '',
      description: '',
      titleError: null,
    }
  },

  computed: {
    formValid () {
      return this.title && this.title.trim().length > 0
    },
  },

  watch: {
    show () {
      if (!this.show) this.resetForm()
    },
  },

  methods: {
    edit (category) {
      this.widget_category_id = category.id
      this.title = category.title
      this.description = category.description
      this.titleError = null
    },

    deleteCategory () {
      const self = this

      self.activeRequestState = true

      $.ajax({
        type: 'DELETE',
        url: Routes.online_recording_category_path(self.widget_category_id),
        success () {
          self.$emit('updated')
          self.$emit('close')
          self.WidgetActionsModal.visibility = false
          Services
            .ui
            .notificator()
            .success(T.record_successfully_deleted)
        },
        error () {
          Services
            .ui
            .notificator()
            .error(self.T.abstract_error_message, self.T.error, 0)
        },
        complete () {
          self.activeRequestState = false
        },
      })
    },

    update () {
      const self = this

      self.activeRequestState = true

      $.ajax({
        type: 'PUT',
        url: Routes.online_recording_category_path(self.widget_category_id),
        data: {
          online_recording_category: {
            title: self.title,
            description: self.description,
          },
        },
        success (updatedCategory) {
          self.$emit('updated')
          self.edit(updatedCategory)
        },
        error ({ status, responseJSON: errors }) {
          if (status !== 422) return

          if (Array.isArray(errors.title) && errors.title.length) {
            self.titleError = errors.title[0]
          }
        },
        complete () {
          Services
            .ui
            .notificator()
            .success(T.changes_updated)
          self.activeRequestState = false
        },
      })
    },

    showDeleteApiRoleModal () {
      this.WidgetActionsModal.visibility = true
      this.WidgetActionsModal.expectedEvent = 'deleteCategory'
      this.WidgetActionsModal.headerMessage = T.confirm_delete
      this.WidgetActionsModal.message = T.client_widget.category_deleting_confirm
      this.WidgetActionsModal.permission = ''
      this.WidgetActionsModal.actionButtonMessage = T.delete
    },

    resetForm () {
      this.title = ''
      this.description = ''
      this.widget_category_id = null
    },
  },
}
</script>
