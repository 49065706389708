import DentalCompaniesCatalog from './dental_companies'
import { createVueApp } from '@/vue_components/create_vue_app'

export const VueDentalCompaniesEntryPoint = () => {
  const el = '#vue_dental_companies_catalog'

  if (!document.querySelector(el)) { return }
  createVueApp({
    el,
    name: 'DentalCompaniesCatalogApp',
    render: (h) => h(DentalCompaniesCatalog),
  })
}
