/**
 * Решает задачу ручного разрешения промиса.
 * Использование:
 * ```
 * const flag = new AsyncFlag()
 * flag.wait().then(() => {
 *   // флаг сработал
 * })
 *
 * // при каком-то действии, расчёте, событии
 * flag.setReady()
 * ```
 */
// TODO: удалить когда будет реализовываться https://app.clickup.com/t/djz9et 3 / 4
export class AsyncFlag {
  constructor () {
    this._createFlag()
  }

  setReady () {
    this._resolve()
  }

  waitAndReset () {
    const promise = this._promise
    this._createFlag()

    return promise
  }

  /**
   * @returns {Promise<*>}
   */
  wait () {
    return this._promise
  }

  /**
   * @private
   */
  _createFlag () {
    this._promise = new Promise((resolve) => {
      this._resolve = resolve
    })
  }
}
