<template>
  <wrapper
    :epic-spinner-visibility="showEpicSpinner"
    :avatar-source="avatarSource"
    :logo-is="customAvatar"
    @cropped-avatar="uploadAvatar"
    @remove-avatar="removeAvatar"
  />
</template>

<script>
import AvatarUploader from './avatar_uploader.js'
import Wrapper from './wrapper.vue'

export default {
  name: 'AvatarEdit',
  components: { Wrapper },
  data () {
    return {
      showEpicSpinner: false,
      selectedUserAvatarSource: Routes.user_avatar_path(gon.specific.user.id),
      defaultAvatarsURL: gon.specific.default_avatars_paths,
      customAvatar: gon.specific.user_custom_avatar,
      userSex: gon.specific.user.sex,
      avatarUploader: new AvatarUploader({ patch: Routes.user_path(pageInfo) }),
      maxFileSize: gon.application.files.max_size,
    }
  },
  computed: {
    avatarSource () {
      if (this.customAvatar) return this.selectedUserAvatarSource
      if (this.userSex === true) return this.defaultAvatarsURL.man
      if (this.userSex === false) return this.defaultAvatarsURL.woman

      return this.defaultAvatarsURL.unisex
    },
    maxFileSizeToMb () {
      return Math.floor(this.maxFileSize / (1024 * 1024))
    },
    maxFileSizeMessage () {
      return `${T.max_file_size} ${this.maxFileSizeToMb}  ${T.mbyte}`
    },
  },
  mounted () {
    this.$pubSub.subscribe('USER_FORM.SEX', (userSex) => {
      this.userSex = userSex
    })
  },
  methods: {
    uploadAvatar (file) {
      if (file.size > this.maxFileSize) {
        Notificator.warning(Utils.maxFileSizeMessage())

        return
      }

      this.showEpicSpinner = true

      this.avatarUploader.upload(file)
        .then(
          (data) => {
            Notificator.success(T.avatar.success_upload)
            this.customAvatar = true
            this.selectedUserAvatarSource = URL.createObjectURL(file)
          },
          (err) => {
            let fileErorrs = T.unknown_error

            if (err.responseJSON && err.responseJSON.file) {
              fileErorrs = err.responseJSON.file.join('.')
            }

            Notificator.error(`${T.avatar.error_upload}: ${fileErorrs}`)
          }
        )
        .finally(() => {
          this.showEpicSpinner = false
        })
    },
    removeAvatar () {
      this.showEpicSpinner = true
      this.avatarUploader.remove()
        .then(
          () => {
            Notificator.success(T.avatar.avatar_removed)
            this.customAvatar = false
            this.selectedUserAvatarSource = Routes.user_avatar_path(pageInfo.id, { uniqueLink: this._randomNumber() })
          },
          (err) => {
            Notificator.error(T.avatar.failed_to_delete_avatar)
            console.warn(err)
          }
        )
        .finally(() => {
          this.showEpicSpinner = false
        })
    },
    _randomNumber () {
      return Math.round(Math.random() * 100)
    },
  },
}
</script>
