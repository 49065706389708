import { DateHelpers } from '@/helpers/DateHelpers'

export const getDefaultFilters = () => ({
  period: DateHelpers.getCurrentMonthPeriod(),
  createdDate: [],
  clinic: null,
  newPatients: false,
  entryTypes: null,
  clientGroups: [],
  appointmentTypes: [],
  appointmentStatuses: [],
  attractionSources: [],
  appointmentSources: [],
  paymentType: null,
  administrator: null,
  doctor: null,
  referral: null,
})
