<template>
  <m-select
    :value="value"
    :items="items"
    :label="t('client_groups')"
    :placeholder="t('reports.all_client_groups')"
    :multiple="multiple"
    full-width
    filterable
    :custom-filter="defaultSearchFilter"
    @change="$emit('change', $event)"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { FiltersApi } from '@/vue_apps/ReportsModule/_filters/api/FiltersApi'
import { defaultSearchFilter } from '@/helpers/lambda'

export default defineComponent({
  name: 'ClientGroupsFilter',

  components: { MSelect },

  props: {
    value: { type: [Array, Object], default: null },
    multiple: Boolean,
  },

  emits: ['change'],

  data () {
    return {
      api: new FiltersApi(),
      items: [],
    }
  },

  async created () {
    const data = await this.api.fetchClientGroups()
    if ('errors' in data) { return }
    this.items = data
  },

  methods: { defaultSearchFilter },
})
</script>
