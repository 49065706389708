<template>
  <div class="extend-schedule-modal-body">
    <div
      v-if="hasAvailableTemplatesForExtend"
      class="extend-schedule-form-item"
    >
      <div class="extend-schedule-form-left">
        <label>
          <span>{{ T.extend_to }}</span>
        </label>
      </div>
      <div class="extend-schedule-form-right">
        <el-date-picker
          v-model="date"
          type="date"
          :clearable="false"
          :picker-options="datePickerOptions"
          :format="'dd.MM.yyyy'"
        />
      </div>
    </div>
    <div v-else>
      {{ T.no_extension_templates_available }}
    </div>
  </div>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'ExtendScheduleTemplateModalBody',
  props: {
    hasAvailableTemplatesForExtend: PropsTypes.Boolean(),
    extendScheduleTemplateModalData: PropsTypes.Object(),
  },

  data () {
    return {
      date: null,
    }
  },

  computed: {
    datePickerOptions () {
      return {
        ...this.extendScheduleTemplateModalData.datePickerOptions,
        firstDayOfWeek: gon.localization.day_week_start,
      }
    },
  },

  watch: {
    date (newDate, oldDate) {
      if (!newDate) this.date = oldDate
    },
  },

  created () {
    this.date = this.extendScheduleTemplateModalData.defaultDate
  },
}
</script>
