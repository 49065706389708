<template>
  <m-checkbox
    v-bind="$attrs"
    v-on="$listeners"
    @input="$emit('change', $event)"
  />
</template>

<script>
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'

export default {
  name: 'CheckboxFilter',

  components: { MCheckbox },
}
</script>
