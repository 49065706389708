import PrintRawReceiptModal from '@/vue_components/fiscal/print_raw_receipt_modal.vue'
import { createVueApp } from '@/vue_components/create_vue_app'

export default () => {
  const workplaceId = gon.specific.workplace.id

  Services.pubSub.subscribe('workplace.handshake', (msg) => {
    Services.workplace.sendMessage('get_info', { workplace_id: workplaceId })
  })

  Services.pubSub.subscribe('workplace.get_info', (msg) => {
    $('#fiscal-printer-title').text(msg.data.info)
  })

  Services.pubSub.subscribe('workplace.error', (msg) => {
    bootbox.alert(msg.data.human_message)
  })

  $('#beep').on('click', () => {
    Services.workplace.sendMessage('beep', { workplace_id: workplaceId })
  })

  $('#open-day').on('click', () => {
    Services.workplace.sendMessage('open_day', { workplace_id: workplaceId })
  })

  $('#cancel_receipt').on('click', () => {
    Services.workplace.sendMessage('cancel_receipt', { workplace_id: workplaceId })
  })

  $('#print_raw_receipt').on('click', () => {
    const container = document.createElement('div')
    container.id = 'print_raw_receipt_modal'
    window.document.body.appendChild(container)

    return createVueApp({
      el: '#print_raw_receipt_modal',
      render: (h) => h(PrintRawReceiptModal),
    })
  })

  $('#doc_by_number').on('click', (e) => {
    const type = $(e.currentTarget).data('type')
    bootbox.prompt({
      title: t('fiscal.doc_by_number'),
      message: t('enter_number_fiscal_document'),
      inputType: 'number',
      callback (documentNumber) {
        if (documentNumber === null) return

        Services.workplace.printReport(type, workplaceId, { doc_number: documentNumber })
      },
    })
  })

  $('.fiscal-report-btn').on('click', (e) => {
    const type = $(e.currentTarget).data('type')
    const confirmation = $(e.currentTarget).data('confirm_text')
    if (!confirmation) return Services.workplace.printReport(type, workplaceId)

    bootbox.confirmYN(confirmation, (confirmed) => {
      if (!confirmed) return
      Services.workplace.printReport(type, workplaceId)
    })
  })
}
