import {
  TreatmentPlansReportItemBase,
} from '@/vue_apps/ReportsModule/TreatmentPlansReports/classes/TreatmentPlansReportItemBase'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { statusIcons, typeIcons } from '@/vue_apps/TreatmentPlans/TreatmentPlansList/const/treatmentPlansIcons'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class TreatmentPlansReportItemCells extends TreatmentPlansReportItemBase {
  /**
   * @param {ITreatmentPlansReportResponseTreatmentPlan} props
   */
  constructor (props) {
    super(props)

    this.numberCell = new MTableCell()
      .addTooltip(this.id)
      .addTypeIcon(typeIcons[this.kind]?.icon)

    this.statusCell = new MTableCell()
      .addTypeIcon(statusIcons[this.status]?.icon)
      .addClasses(statusIcons[this.status]?.css)
      .addTooltip(t(this.status, { scope: 'treatmentPlanStatuses' }))

    this.dateCell = new MTableCell(vueFilters.date(this.date))

    this.clientCell = (this.client?.id && new MTableCell(vueFilters.shortName(this.client))
      .addTooltip(vueFilters.fullName(this.client))
      .addTypeUrl(Routes.client_path(this.client.id))
      .addClasses(this.client.deletedAt ? 'deleted' : '')) || null

    this.titleCell = new MTableCell(this.title)
      .addTooltip(true)
      .addCut()
      .addTypeUrl(Routes.treatment_plan_path(this.id))

    this.doctorCell = (
      this.doctor && new MTableCell(vueFilters.shortName(this.doctor))
        .addTooltip(vueFilters.fullName(this.doctor))
    ) || new MTableCell('-')

    this.responsibleCell = (
      this.responsible && new MTableCell(vueFilters.shortName(this.responsible))
        .addTooltip(vueFilters.fullName(this.responsible))
    ) || new MTableCell('-')

    this.treatmentEntriesAmountCell =
      new MTableCell(this.treatmentEntriesAmount?.toString())

    this.finalSumCell =
      new MTableCell(vueFilters.price(this.finalSum))

    this.treatmentEntriesCreatedAmountCell =
      new MTableCell(this.treatmentEntriesCreatedAmount?.toString())

    this.treatmentEntriesCreatedTotalSumCell =
      new MTableCell(vueFilters.price(this.treatmentEntriesCreatedTotalSum))

    this.paidSumCell =
      new MTableCell(vueFilters.price(this.paidSum))

    this.unpaidSumCell =
      new MTableCell(vueFilters.price(this.unpaidSum))

    this.balanceCell =
      new MTableCell(vueFilters.price(this.client?.finance))

    this.totalCell =
      new MTableCell(vueFilters.price(this.total))

    if (this.total < 0) {
      this.totalCell.addClasses('danger')
    }
  }
}
