import Vue from 'vue/dist/vue.esm'
import AppointmentTypeColorField from '@/vue_components/appointment/appointment_type/appointment_type_color_field'
import AppointmentTypeColorPreview
  from '@/vue_components/appointment/appointment_type/appointment_type_preview_container'
import { createDefaultAppointmentType } from '@/specific/reception/appointment_durations/const'

import { VueAppointmentTypesEntryPoint } from '@/vue_components/catalogs/appointment_types'
import { createVueApp } from '@/vue_components/create_vue_app'

$document.on('rez/reception/appointment_types/new rez/reception/appointment_types/edit', function () {
  const form = FormFactory.build('appointment_type')

  switch (pageInfo.action) {
    case 'new':
      form.newItem()
      break
    case 'edit':
      form.editItem()
      break
  }

  if (gon.application.show_appointment_type_color_indicator) {
    addColorField()
  }

  function addColorField () {
    const appointmentType = gon.specific.appointment_type || createDefaultAppointmentType()

    const colorState = Vue.observable({
      color: appointmentType.color,
    })

    createVueApp({
      el: '#color-field',

      turbolinksDestroyEvent: 'turbolinks:before-render',

      render: (h) => h(AppointmentTypeColorField, {
        props: {
          color: colorState.color,
        },
        on: {
          'update:color' (newColor) {
            colorState.color = newColor
          },
        },
      }),
    })

    createVueApp({
      el: '#color-preview',

      turbolinksDestroyEvent: 'turbolinks:before-render',

      render: (h) => h(AppointmentTypeColorPreview, {
        props: {
          color: colorState.color,
        },
      }),
    })
  }
})

$document.on('rez/reception/appointment_types', VueAppointmentTypesEntryPoint)
