import ReportClient from '@/specific/reports/ReportClient'
import { defineDateFormat, fetchExportFile } from '@/specific/reports/export'
import { MAX_LIMIT } from '@/specific/reports/const'
import { extractRequestData } from '@/_api/_requests/helpers'
import { omit } from 'lodash'

export class MReportPresenterBase {
  constructor (routeFn) {
    this.url = routeFn()
    this.reportClient = new ReportClient(this.url)
  }

  /**
   * Позволяет использовать с MListService и производными
   * @param data
   * @param config
   * @return {Promise<any>}
   */
  list (data, config) {
    return this.generate(data, config)
  }

  generate (data, config) {
    return this.reportClient.fetch(extractRequestData(data, config))
      .then((response) => {
        if (config?.toClientAdapter) {
          return Promise.resolve(config.toClientAdapter(response))
        }

        return Promise.resolve(response)
      })
  }

  export (data = {}, fileName) {
    const patchedData = {
      limit: MAX_LIMIT,
      offset: 0,
      csvComma: gon.application.csv_character,
      dateFormat: defineDateFormat(),
      floatingComma: gon.localization.currency.decimal_character,
      ...omit(data, ['sorting', 'offset', 'limit']),
    }

    fetchExportFile(patchedData, this.url, fileName)
  }
}
