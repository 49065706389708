<template>
  <insurance-companies
    ref="report"
    :is-hidden="isHidden"
    v-bind="filters"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import InsuranceCompanies from '@/vue_components/reports/InsuranceCompaniesReport/InsuranceCompanies.vue'
import ReportWrapper from '@/vue_components/mixins/reportWrapper'
import { insuranceCompaniesReportFiltersAccessors } from '@/specific/reports/insuranceCompanies/filtersAccessors'

export default defineComponent({
  name: 'InsuranceCompaniesApp',

  components: { InsuranceCompanies },

  mixins: [ReportWrapper],

  data () {
    return {
      filters: insuranceCompaniesReportFiltersAccessors.get(),
    }
  },
})
</script>
