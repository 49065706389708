<template>
  <div class="entity-info">
    <div class="entity-info__created">
      <span class="prompt-notice">{{ t('created_by') }}: </span>
      <span
        v-if="createdBy"
        class="prompt-notice"
      >
        {{ createdBy }}
      </span>
      <span v-else> - </span>
    </div>

    <div class="entity-info__updated">
      <span class="prompt-notice">{{ t('updated_by') }}: </span>
      <span
        v-if="updatedBy"
        class="prompt-notice"
      >
        {{ updatedBy }}
      </span>
      <span v-else> - </span>
    </div>

    <div
      v-if="deletedBy"
      class="entity-info__deleted"
    >
      <span class="prompt-notice">{{ t('deleted_by') }}: </span>
      <span class="prompt-notice">
        {{ deletedBy }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EntityInfo',

  props: {
    createdBy: {
      type: String,
      default: '',
    },

    updatedBy: {
      type: String,
      default: '',
    },

    deletedBy: {
      type: String,
      default: '',
    },
  },
}
</script>
