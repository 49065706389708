<template>
  <div
    v-tooltip="client.fullName"
    class="col col__clientData"
  >
    <a
      :href="$routes.client_path(client.id)"
      :class="{ 'deleted': client.deletedAt }"
      target="_blank"
    >
      {{ client.surname }} {{ client.name }} {{ client.secondName }}
    </a>
  </div>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'AppointmentCallLogClientData',
  props: {
    client: PropsTypes.Object(),
  },
}
</script>
