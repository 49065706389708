import EgiszUserTab from '@/vue_components/egisz/egisz_user_tab/EgiszUserTab'
import { createVueApp } from '@/vue_components/create_vue_app'

export const createEgiszUserTab = (el, user, clinics) => {
  if (!document.querySelector(el)) { return }

  const frmoClinics = (clinics || [])
    .filter((clinic) => clinic.lpu_id && clinic.netrika_guid)

  // eslint-disable-next-line no-new
  createVueApp({
    el,
    name: 'EgiszUserTabCreator',
    render: (h) => h(EgiszUserTab, {
      props: {
        user,
        clinics: frmoClinics,
        canManage: Utils.can(Utils.ACTIONS.MANAGE_SELF, 'User') ||
          Utils.can(Utils.ACTIONS.MANAGE, 'Egisz'),
      },
    }),
  })
}
