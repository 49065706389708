<template>
  <referrals
    ref="report"
    :is-hidden="isHidden"
    :period="filters.period"
    :clinic="filters.clinic"
    :group-by-entry="filters.groupByEntry"
    :entry="filters.entry"
    :category="filters.category"
    :medical-user="filters.medicalUser"
    :city="filters.city"
    :referral="filters.referral"
    :report-type-filter="filters.reportTypeFilter"
    :including-discounts="filters.includingDiscounts"
    :entry-with-order="filters.entryWithOrder"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ReportWrapper from '@/vue_components/mixins/reportWrapper'
import Referrals from '@/vue_components/reports/referrals/referrals.vue'
import { referralsReportFiltersAccessors } from '@/specific/reports/referrals/filtersAccessors'

export default defineComponent({
  name: 'ReferralsApp',

  components: { Referrals },

  mixins: [ReportWrapper],

  data () {
    return {
      filters: referralsReportFiltersAccessors.get(),
    }
  },
})
</script>
