<template>
  <div class="add-vacation-modal-body">
    <div class="add-vacation-form-item">
      <div class="add-vacation-form-left">
        <label>
          <span>{{ T.period }}</span>
        </label>
      </div>
      <div class="add-vacation-form-right">
        <el-date-picker
          v-model="selfDateRange"
          :default-value="defaultDate"
          :clearable="false"
          :format="'dd.MM.yyyy'"
          :picker-options="pickerOptions"
          type="daterange"
          align="right"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AddVacationModalBody',

  props: {
    defaultDate: { type: String, required: true },
    dateRange: { type: Array, required: true },
    userId: { type: Number, required: true },
  },

  data () {
    return {
      selfDateRange: null,
    }
  },

  computed: {
    pickerOptions () {
      return {
        firstDayOfWeek: gon.localization.day_week_start,
      }
    },
  },

  created () {
    this.selfDateRange = this.dateRange.slice()
  },

  methods: {
    addVacation () {
      this.$pubSub.emit('EMPLOYEES_SCHEDULE.ADD_VACATION', {
        start: Utils.dateJStoRuby(this.selfDateRange[0]),
        end: Utils.dateJStoRuby(this.selfDateRange[1]),
        user_id: this.userId,
      })
    },
  },
}
</script>
