<template>
  <div class="filters-profiles">
    <m-select-lazy
      :label="t$('title')"
      :placeholder="t('no_letter_order')"
      :permanent-displayed-filter="onlySelfProfilesFilter"
      :value="activeProfile"
      :fetch-method="(params) => FiltersProfileApi.list({ ...params, profileType })"
      :m-fixed-height="false"
      use-first-time-fetch
      filterable
      full-width
      @changeByLazy="onFetch"
      @registerApi="listApi = $event"
    />

    <m-checkbox
      :value="useSelfProfiles"
      class="filters-profiles__self-checkbox"
      :label="t$('myProfiles')"
      @input="onUseSelfProfilesChange"
    />

    <div class="flex align-center mt-10">
      <m-popover
        :cancel-text="t$('cancel')"
        :confirm-text="t$('confirm')"
        :yes-type="submitBtnType"
        cancel-type="primary"
        popover-width="320"
        reverse-buttons-order
        inherited-visibility
        :visibility="isEditorVisible"
        @update:visibility="isEditorVisible = $event"
        @yes="onSubmit"
        @no="isEditorVisible = false"
      >
        <template #message>
          <span />
        </template>
        <template #body>
          <m-input
            class="filters-profiles__name-input"
            :value="newProfileName"
            :placeholder="t$('nameInput.placeholder')"
            :label="t$('nameInput.label')"
            required
            validator-name="title"
            @registerValidator="onRegisterValidator"
            @input="newProfileName = $event"
          />
        </template>

        <template #reference>
          <m-button
            :template="openProfileEditorBtnParams.template"
            :tooltip="openProfileEditorBtnParams.tooltip"
            @click="onOpenEditor"
          />
        </template>
      </m-popover>

      <m-button-delete
        v-show="activeProfile"
        class="filters-profiles__delete-btn"
        :popover-message="t$('deleteQuestion')"
        use-button
        :use-text="false"
        @yes="onDestroy"
      />
    </div>
  </div>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MPopover from '@/vue_present/_base/MPopover/MPopover.vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import { FiltersProfileApi } from './entities/FiltersProfileApi'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'

export default {
  name: 'FiltersProfilesApp',

  components: {
    MSelectLazy,
    MButton,
    MButtonDelete,
    MInput,
    MPopover,
    MCheckbox,
  },

  mixins: [
    i18nScopeMixin,
    ValidationParentMixin,
  ],

  props: {
    filtersMap: { type: Object, default: () => ({}) },
    profileType: { type: Number, required: true },
    useSubmitDelegating: { type: Boolean },
  },

  emits: [
    'onProfileSelect',
    'onSubmit',
  ],

  data () {
    return {
      activeProfile: null,
      newProfileName: '',
      useSelfProfiles: false,
      isEditorVisible: false,
      FiltersProfileApi,
      /** @type {MSelectLazyApi} */
      listApi: null,
    }
  },

  computed: {
    openProfileEditorBtnParams () {
      return {
        tooltip: this.activeProfile
          ? this.t$('change')
          : this.t$('create'),
        template: this.activeProfile
          ? 'short_edit'
          : 'short_save',
      }
    },

    submitBtnType () {
      return this.activeProfile
        ? 'warning'
        : 'success'
    },
  },

  created () {
    this.setI18nScope('filtersProfiles')
  },

  methods: {
    resetActiveProfile (newValue = null) {
      this.activeProfile = newValue
    },

    onUseSelfProfilesChange (value) {
      this.useSelfProfiles = value
      this.listApi.filterItems()
    },

    onlySelfProfilesFilter (searchQuery, profileItem) {
      return this.useSelfProfiles
        ? profileItem.userId === gon.application.current_user.id
        : true
    },

    onOpenEditor () {
      this.newProfileName = this.activeProfile?.title ?? ''
      this.resetValidations()
    },

    onSubmit () {
      if (this.hasErrors()) { return }
      if (this.useSubmitDelegating) { return this.$emit('onSubmit') }

      this.submit()
    },

    async onFetch (profileToFetch) {
      if (!profileToFetch) { return this.resetActiveProfile() }

      const errors = await profileToFetch.fetch()
      if (errors) { return }

      this.onSuccessFetched(profileToFetch)
    },

    async onDestroy () {
      const errors = await this.activeProfile.destroy()
      if (errors) { return }

      this.onSuccessDestroyed()
    },

    async submit () {
      const profileToSubmit = new FiltersProfileApi({
        id: this.activeProfile?.id ?? null,
        data: this.filtersMap,
        profileType: this.profileType,
        title: this.newProfileName,
      })

      // При обновлении шаблона userId отправляем старый,
      // чтобы id его владельца не изменилось
      if (this.activeProfile) {
        profileToSubmit.userId = this.activeProfile.userId
      }

      const errors = await profileToSubmit.submit()
      if (errors) { return this.resetValidations(errors) }

      this.onSuccessSubmitted(profileToSubmit)
    },

    /* Обработчики успешных запросов */

    onSuccessSubmitted (submittedProfile) {
      this.isEditorVisible = false

      if (this.activeProfile) {
        this.listApi.rewriteItem(submittedProfile)
        this.resetActiveProfile(submittedProfile)

        return
      }

      this.listApi.addItem(submittedProfile)
    },

    onSuccessDestroyed () {
      this.listApi.removeItem(this.activeProfile)
      this.resetActiveProfile()
    },

    onSuccessFetched (fetchedProfile) {
      this.resetActiveProfile(fetchedProfile)

      this.$emit('onProfileSelect', {
        filters: fetchedProfile.data,
        errors: fetchedProfile.dataErrors,
      })
    },
  },
}
</script>
