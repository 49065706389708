import {
  IDoctorAppointmentsReportToClientDTO,
} from '@/vue_apps/ReportsModule/DoctorAppointments/interfaces/IDoctorAppointmentsReportToClientDTO'
import {
  DoctorAppointmentsReportItemCell,
} from '@/vue_apps/ReportsModule/DoctorAppointments/classes/DoctorAppointmentsReportItemCell'
import { DateHelpers } from '@/helpers/DateHelpers'
import { inArrayOrUndefined } from '@/_medods_standart_library/msl'
import {
  DoctorAppointmentsReportTotalItemCell,
} from '@/vue_apps/ReportsModule/DoctorAppointments/classes/DoctorAppointmentsReportTotalItemCell'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import { camelCase } from 'lodash'

export const doctorAppointmentsReportAdapter = {
  toServer (data) {
    return {
      report: DateHelpers.toLegacyPeriod(data.period),
      clinicIds: inArrayOrUndefined(data.clinic?.id),
      createdDate: data.createdDate,
      newPatients: data.newPatients,
      entryTypeIds: inArrayOrUndefined(data.entryTypes?.id),
      clientGroupIds: data.clientGroups.map((group) => group.id),
      appointmentByDms: data.paymentType?.id ?? '',
      appointmentTypeIds: data.appointmentTypes.map((type) => type.id),
      appointmentStatuses: data.appointmentStatuses.map((status) => status.id),
      attractionSourceIds: data.attractionSources.map((source) => source.id),
      appointmentSourceIds: data.appointmentSources.map((source) => source.id),
      administratorIds: inArrayOrUndefined(data.administrator?.id),
      doctorIds: inArrayOrUndefined(data.doctor?.id),
      referralIds: inArrayOrUndefined(data.referral?.id),

      sorting: data.__sorting,
      offset: data.offset,
      limit: data.limit,
    }
  },

  toClient (data: IDoctorAppointmentsReportToClientDTO) {
    return {
      data: data.data.map((item) => new DoctorAppointmentsReportItemCell(item.appointment)),
      total: new DoctorAppointmentsReportTotalItemCell(data),
      totalItems: data.count,
      totalPages: Math.ceil(data.count / DEFAULT_LIMIT),
    }
  },

  toExport (data) {
    return {
      ...doctorAppointmentsReportAdapter.toServer(data),
      headerRow: {
        appointmentDate: t('custom_date'),
        appointmentTime: t('custom_time'),
        appointmentStatus: t('status'),
        attractionSource: t('attraction_source'),
        clientFullName: t('patient'),
        clientPhone: t('phone'),
        entryTypeTitles: t('services'),
        administratorFullName: t('administrator'),
        createdDate: t('created_date'),
        doctorFullName: t('doctor'),
        appointmentNote: t('note'),
      },
      appointmentStatus: getAppointmentStatus(),
    }
  },
}

const getAppointmentStatus = () => Object.entries(t('enums.appointment.status'))
  .reduce((acc, [key, value]) => ({
    ...acc,
    [camelCase(key)]: value,
  }), {})
