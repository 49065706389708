import { request } from '@/lib/transport/request'

export default class ReportClient {
  constructor (url) {
    this.params = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    }

    this.url = url
  }

  /**
   * @param {object} params
   * @param {string} params.url
   * @deprecated
   */
  init (params) {
    this.url = params.url
  }

  /**
   * @param {object} body
   * @deprecated
   */
  get (body) {
    return request({
      ...this.params,
      url: this.url,
      data: JSON.stringify(body),
      contentType: 'application/json',
      dataType: 'json',
    }).promise
  }

  fetch (body) { return this.get(body) }
}
