<template>
  <expenses
    ref="report"
    :is-hidden="isHidden"
    v-bind="filters"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ReportWrapper from '@/vue_components/mixins/reportWrapper'
import Expenses from '@/vue_components/reports/Expenses/Expenses.vue'
import { getFilters } from '@/specific/reports/expenses'

export default defineComponent({
  name: 'ExpensesReportApp',

  components: { Expenses },

  mixins: [ReportWrapper],

  data () {
    return {
      filters: getFilters(),
    }
  },
})
</script>
