<template>
  <modal
    v-if="showRemoveScheduleTemplateModal"
    :modal-class="'remove-schedule-template-modal'"
    :modal-visibility="showRemoveScheduleTemplateModal"
    @close="$emit('removeNone')"
  >
    <span slot="header">{{ `${T.remove_interval} ${doctorFullName}` }}</span>
    <template slot="body">
      <div class="schedule-template-form-item">
        <div class="main-message">
          <span>{{ T.remove_all_interval_occurrences }}</span>
          <span>&nbsp;</span>
          <span class="modal-time">{{ startTime }}</span>
          <span> — </span>
          <span class="modal-time">{{ endTime }}</span>
        </div>
        <div class="action-select">
          <items-list
            v-model="removalType"
            v-bind="removalTypeButtonsProps"
          />
        </div>
      </div>
      <div
        v-if="removalType.includes(REMOVE_ALL_FROM_SPECIFIED_DATE)"
        class="schedule-template-form-item"
      >
        <div class="schedule-template-form-left">
          <label>
            <span>{{ T.start_date_of_deletion }}</span>
          </label>
        </div>
        <div class="schedule-template-form-right">
          <el-date-picker
            v-model="startDateOfDeletion"
            :clearable="false"
            :picker-options="datePickerOptions"
            :format="'dd.MM.yyyy'"
            type="date"
          />
        </div>
      </div>
    </template>
    <div slot="footer-right">
      <button
        class="btn btn-sm btn-danger btn-with-icon modal-save"
        @click="_emitEvent"
      >
        <span class="btn-with-icon_icon fad fa-exclamation-circle" />
        <span class="btn-with-icon_text">{{ T.delete }}</span>
      </button>
      <button
        class="btn btn-sm btn-primary btn-with-icon modal-close"
        @click="$emit('removeNone')"
      >
        <span class="btn-with-icon_icon fad fa-times" />
        <span class="btn-with-icon_text">{{ T.close }}</span>
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from '../../../modal.vue'
import ItemsList from '../../../items_list.vue'
import { formatDate } from '../../utils.js'
import { REMOVE_ALL, REMOVE_ALL_FROM_SPECIFIED_DATE } from '../../const.js'

export default {
  name: 'RemoveScheduleTemplateModal',
  components: {
    Modal,
    ItemsList,
  },
  props: {
    showRemoveScheduleTemplateModal: { type: Boolean, required: true },
  },
  data () {
    return {
      REMOVE_ALL_FROM_SPECIFIED_DATE,
      template: null,
      startTime: null,
      endTime: null,
      doctorFullName: null,
      removalType: null,
      startDateOfDeletion: null,
    }
  },
  computed: {
    removalTypeButtonsProps () {
      return {
        items: [
          { id: REMOVE_ALL_FROM_SPECIFIED_DATE, title: T.from_selected_date },
          { id: REMOVE_ALL, title: T.whole_interval },
        ],
        multiSelect: false,
        idItemField: 'id',
        displayedItemField: 'title',
        minSelectedItems: 1,
      }
    },
    datePickerOptions () {
      const template = this.template.template
      const startDate = window.moment(template.interval_start_date).valueOf()
      const endDate = window.moment(template.last_propagation_end_date).valueOf()

      return {
        firstDayOfWeek: 1,
        disabledDate (time) {
          const mill = time.getTime()

          return mill < startDate || mill > endDate
        },
      }
    },
  },
  watch: {
    startDateOfDeletion (newDate, oldDate) {
      if (!newDate) this.startDateOfDeletion = oldDate
    },
  },
  methods: {
    loadData ({ template, doctorFullName }) {
      const time = template.template.work_time.split('-')

      this.template = template
      this.startTime = time[0]
      this.endTime = time[1]
      this.doctorFullName = doctorFullName
      this.removalType = [REMOVE_ALL_FROM_SPECIFIED_DATE]
      this.startDateOfDeletion = window.moment(template.template.last_propagation_end_date).toDate()

      this.$emit('showMe')
    },

    _emitEvent () {
      let requestData = { template_id: this.template.id }
      const startDateOfDeletion = Utils.dateJStoRuby(this.startDateOfDeletion)
      const removeAll =
        this.removalType.includes(REMOVE_ALL) ||
        startDateOfDeletion === this.template.template.interval_start_date

      if (removeAll) {
        requestData = { ...requestData, rollback_all: true }
      } else {
        requestData = { ...requestData, start_date_of_deletion: startDateOfDeletion }
      }

      const headerMessage = T.remove_interval
      const message =
        removeAll
          ? T.delete_all_interval_occurrences
          : T.delete_all_interval_occurrences_with.format(formatDate(startDateOfDeletion))
      const removeScheduleTemplateData = 'removeScheduleTemplateData'

      const confirmationModalData = {
        field: removeScheduleTemplateData,
        [removeScheduleTemplateData]: {
          headerMessage,
          message,
          yesEvent: () => {
            this.$emit('remove', requestData)
            this.$emit('showConfirmationModal', false)
          },
          noEvent: () => this.$emit('showConfirmationModal', false),
        },
      }

      this.$emit('setConfirmationModalData', confirmationModalData)
      this.$emit('showConfirmationModal', true)
    },
  },
}
</script>
