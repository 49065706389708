import ApiRole from '@/vue_components/api/role.vue'
import { createVueApp } from '@/vue_components/create_vue_app'

$document.on('rez/api/roles', function () {
  if (gon.specific.moduleIsEnabled) {
    createVueApp({
      el: '#api_roles_root_container',
      name: 'ApiRoleApp',
      render: (h) => h(ApiRole),
    })
  }
})
