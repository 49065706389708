import { MReportPresenterBase } from '@/_api/Reports/_base/MReportPresenterBase'
import {
  doctorAppointmentsReportAdapter,
} from '@/vue_apps/ReportsModule/DoctorAppointments/api/doctorAppointmentsReportAdapter'

export class DoctorAppointmentsReportPresenter extends MReportPresenterBase {
  constructor () {
    super(Routes.report_doctor_appointments_path)
  }

  list (data, config = {}) {
    return super.list(data, {
      toServerAdapter: doctorAppointmentsReportAdapter.toServer,
      toClientAdapter: doctorAppointmentsReportAdapter.toClient,
      ...config,
    })
  }

  export (data: {} = {}) {
    super.export(data, t('reports.long_titles.doctor_appointments'))
  }
}
