import { isUndefined, omit, omitBy } from 'lodash'
import {
  IDoctorAppointmentsReportFiltersProfileFiltersToClient as IFiltersToClient,
  IDoctorAppointmentsReportFiltersProfileFiltersToServer as IFiltersToServer,
} from '../../interfaces/filtersTypes/doctorAppointmentsReport'
import {
  IDoctorAppointmentsReportFiltersGet as FiltersGet,
  IDoctorAppointmentsReportFiltersSet as FiltersSet,
} from '@/vue_apps/FiltersProfilesApp/interfaces/filtersTypes/doctorAppointmentsReport'
import { IFiltersProfilesFetchResponse } from '../../interfaces/IFIltersProfilesFetchResponse'
import { FiltersProfilesFormattersToServer as FormattersToServer } from '../../helpers/formatters/toServer'
import { FiltersProfilesFormattersToClient as FormattersToClient } from '../../helpers/formatters/toClient'
import { gatherErrors } from '../../helpers/gatherErrors'
import { IFiltersProfilesModel } from '../../interfaces/IFiltersProfilesModel'
import { cleanUnpermittedValues } from '../../helpers/formatters/shared'

export const doctorAppointmentsReportFiltersProfileAdapter = {
  toServer (data: IFiltersProfilesModel<FiltersGet>): IFiltersProfilesModel<IFiltersToServer> {
    const { data: filters } = data

    const adaptedFilters = {
      ...omit(filters, ['newPatients', 'entryTypes', 'clientGroups', 'appointmentTypes', 'appointmentStatuses', 'attractionSources', 'appointmentSources']),
      period: FormattersToServer.formatPeriod(filters.period),
      createdDate: FormattersToServer.formatPeriod(filters.createdDate),
      clinic: FormattersToServer.formatSingle(filters.clinic),
      newPatient: filters.newPatients,
      entryType: FormattersToServer.formatSingle(filters.entryTypes),
      clientGroup: FormattersToServer.formatMulti(filters.clientGroups),
      paymentType: FormattersToServer.formatSingle(filters.paymentType),
      appointmentType: FormattersToServer.formatMulti(filters.appointmentTypes),
      appointmentStatus: FormattersToServer.formatMulti(filters.appointmentStatuses),
      attractionSource: FormattersToServer.formatMulti(filters.attractionSources),
      appointmentSource: FormattersToServer.formatMulti(filters.appointmentSources),
      administrator: FormattersToServer.formatHuman(filters.administrator),
      doctor: FormattersToServer.formatHuman(filters.doctor),
      referral: FormattersToServer.formatHuman(filters.referral),
    }

    return { ...data, data: adaptedFilters }
  },

  toClient (data: IFiltersProfilesFetchResponse<IFiltersToClient>): IFiltersProfilesModel<FiltersSet> {
    const { data: filters } = data

    const validFilters = omitBy(cleanUnpermittedValues(filters), isUndefined) as IFiltersToClient

    const adaptedFilters: FiltersSet = {
      period: FormattersToClient.formatPeriod(validFilters.period),
      createdDate: FormattersToClient.formatPeriod(validFilters.createdDate),
      clinic: FormattersToClient.formatSingle(validFilters.clinic),
      newPatients: validFilters.newPatient,
      entryTypes: FormattersToClient.formatSingle(validFilters.entryType),
      clientGroups: FormattersToClient.formatMulti(validFilters.clientGroup),
      paymentType: FormattersToClient.formatSingle(validFilters.paymentType),
      appointmentTypes: FormattersToClient.formatMulti(validFilters.appointmentType),
      appointmentStatuses: FormattersToClient.formatMulti(validFilters.appointmentStatus),
      attractionSources: FormattersToClient.formatMulti(validFilters.attractionSource),
      appointmentSources: FormattersToClient.formatMulti(validFilters.appointmentSource),
      administrator: FormattersToClient.formatHuman(validFilters.administrator),
      doctor: FormattersToClient.formatHuman(validFilters.doctor),
      referral: FormattersToClient.formatHuman(validFilters.referral),
    }

    const definedAdaptedFilters = omitBy(adaptedFilters, isUndefined) as FiltersSet

    const renamedFilters = omitBy({
      ...omit(filters, ['newPatient', 'entryType', 'clientGroup', 'appointmentType', 'appointmentStatus', 'attractionSource', 'appointmentSource']),
      newPatients: filters.newPatient,
      entryTypes: filters.entryType,
      clientGroups: filters.clientGroup,
      appointmentTypes: filters.appointmentType,
      appointmentStatuses: filters.appointmentStatus,
      attractionSources: filters.attractionSource,
      appointmentSources: filters.appointmentSource,
    }, isUndefined)

    return {
      ...data,
      data: definedAdaptedFilters,
      dataErrors: gatherErrors(renamedFilters),
    }
  },
}
