import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getDoctorAppointmentsReportSchema = () =>
  new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addMinWidth('1920px')
        .addSorting()
        .addHeaders({
          dateCell: new MTableHeader(t('common.date')).addFixedWidth('100px'),
          timeCell: new MTableHeader(t('common.time')).addFixedWidth('110px'),
          statusCell: new MTableHeader(t('status')).addFixedWidth('80px'),
          attractionSourceCell: new MTableHeader(t('attraction_source')).addFixedWidth('210px'),
          clientCell: new MTableHeader(t('client')).addFixedWidth('250px'),
          phoneCell: new MTableHeader(t('phone')).addFixedWidth('160px').removeSorting(),
          entryTypesCell: new MTableHeader(t('service')).addFixedWidth('300px').removeSorting(),
          administratorCell: new MTableHeader(t('administrator')).addFixedWidth('150px'),
          createdAtCell: new MTableHeader(t('created_at')).addFixedWidth('150px'),
          doctorCell: new MTableHeader(t('doctor')).addFixedWidth('150px'),
          noteCell: new MTableHeader(t('note')),
        })
        .addFooter()
    )
