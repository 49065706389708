import PricesImport from '@/vue_components/EntryTypes/PricesImport/PricesImport'
import { createVueApp } from '@/vue_components/create_vue_app'

export const createPricesImportVue = (el) => {
  if (!document.querySelector(el)) { return }

  createVueApp({
    el,
    name: 'PriceImport',
    render: (h) => h(PricesImport),
  })
}
