import { getFilters } from '@/specific/reports/payments/getFilters'
import { exportReport } from '@/specific/reports/payments/exportReport'
import { createVueApp } from '@/vue_components/create_vue_app'
import PaymentsReportApp from '@/vue_components/reports/PaymentsReport/PaymentsReportApp.vue'

$document.on('rez/reports/payments', () => {
  Report.init()

  const reportTable = createVueApp({
    el: '#report_right_container',
    name: 'PaymentReportAppRoot',
    render: (h) => h(PaymentsReportApp),
    useChildren: true,
  })

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(getFilters())
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
  })

  $('.export').on('click', exportReport(getFilters))
})
