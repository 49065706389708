<template>
  <div class="support-container">
    <navigation
      class="support-nav"
      :boards-array="boardsArray"
      :is-loading="navigationIsLoading"
      @updateCannyBoardToken="updateToken"
      @updateBodyTitle="updateBodyTitle"
    />
    <panel-heading
      class="support-body"
      :icon="supportBodyIcon"
      :show-tooltip="true"
      :title="supportBodyTitle"
    >
      <h5
        v-if="isBannedEmail"
        class="red"
      >
        <i class="fad fa-fw fa-exclamation-triangle red" />
        {{ t('canny_feedback_banned_email_text') }}
      </h5>
      <router-view
        v-else
        :board-token="boardToken"
        :sso-token="ssoToken"
        :sso-user="ssoUser"
      />
    </panel-heading>
  </div>
</template>

<script>
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import SupportNavigation from './components/nav/SupportNavigation'
import { getCannyBoards, getSSOToken } from './rest/canny_endpoint'
import { getUserAvatarFullPath } from '@/vue_components/support/const/methods'
import { BANNED_EMAIL, INSTALLATION_TYPES } from './const/const'

export default {
  name: 'SupportModule',
  components: {
    PanelHeading,
    Navigation: SupportNavigation,
  },

  data () {
    return {
      navigationIsLoading: true,

      supportBodyTitle: t('support_nav'),
      supportBodyIcon: 'fad fa-fw fa-atlas',

      boardsArray: [],
      boardToken: '',
      ssoToken: null,
      ssoUser: {},
      isBannedEmail: false,
    }
  },

  created () {
    this.navigationIsLoading = true
    this.prepareSSOUser()
    this.getSSO()
    this.getBoards() /* здесь this.navigationIsLoading = false */
  },

  methods: {
    /**
     * Сборка юзера для sso токена:
     * 1) данные юзера
     * 2) данные компании (клиники)
     *
     * COMPANY ID: hostname:clinic:clinic_id
     * USER ID: hostname:user:user_id
     *
     * Название клиники для облачников: Название клиники (clinic_name.medods.ru)
     * Название клиники для локальников: Название клиники
     */
    prepareSSOUser () {
      const gonCurrentUser = { ...gon.application.current_user }
      if (gonCurrentUser.email === BANNED_EMAIL) {
        this.isBannedEmail = true
        this.navigationIsLoading = false

        throw new Error(t('canny_feedback_banned_email_text'))
      }

      const gonCurrentClinic = gon.application.current_clinic
      const hostname = window.location.hostname

      // TODO: удалить если canny повысит длину строки для user.name, company.name
      // TODO: вернуть clinicSuffix и clinicName
      const maxStringLen = 50 - 3
      let hostname50 = gon.application.installation_type === INSTALLATION_TYPES.CLOUD
        ? hostname
        : gonCurrentClinic.title

      if (hostname50.length > maxStringLen) { hostname50 = hostname50.slice(0, maxStringLen - 1).trim() + '...' }

      const userName50 = hostname50
      const companyName50 = hostname50

      this.ssoUser = {
        id: `${hostname}:user:${gonCurrentUser.id}`,
        name: userName50,
        avatarUrl: getUserAvatarFullPath(gonCurrentUser.id),
        email: gonCurrentUser.email,
        companies: [{
          id: `${hostname}:clinic:${gonCurrentClinic.id}`,
          name: companyName50,
        }],
      }
    },

    getSSO () {
      getSSOToken(this.ssoUser)
        .then((data) => { this.ssoToken = data.token })
        .catch((e) => { console.error('Get sso token error: ', e) })
    },

    getBoards () {
      getCannyBoards()
        .then((data) => {
          this.boardsArray = this.addBoardUrl(data.boards)
          this.boardToken = (this.boardsArray.find((item) => item.boardUrl === this.$route.params.boardId) || {}).token
        })
        .catch((e) => { console.error('Get canny boards Error: ', e) })
        .finally(() => { this.navigationIsLoading = false })
    },

    /*
    * В методе парсится ссылка на доску от canny:
    * https://medods.canny.io/admin/board/feature-requests
    * извлекается последний кусок "feature-requests" и добавляется к объектам,
    * далее vue-router уже нормально будет их парсить и переходить куда надо
    */
    addBoardUrl (boardArray) {
      return boardArray.map((item) => {
        const newItem = { ...item }
        const boardUrlSplit = newItem.url.split('/')

        newItem.boardUrl = boardUrlSplit[boardUrlSplit.length - 1] === ''
          ? boardUrlSplit[boardUrlSplit.length - 2] /* случай если в конце будет '/' */
          : boardUrlSplit[boardUrlSplit.length - 1] /* случай если в конце отсутствует '/' */

        return newItem
      })
    },

    updateToken (value) {
      this.boardToken = value
    },

    updateBodyTitle (payload) {
      this.supportBodyTitle = payload.text
      this.supportBodyIcon = payload.icon
    },
  },
}
</script>
