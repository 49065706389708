<template>
  <si-field>
    <div class="title cut">
      {{ row.title }}
    </div>
    <div class="amount cut">
      {{ row.amount }}
    </div>
    <div class="sum cut">
      {{ $filters.price(row.price) }}
    </div>
  </si-field>
</template>

<script>
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'

export default {
  name: 'ExpensesRowGrouped',

  components: {
    SiField,
  },

  props: {
    row: {
      type: Object,
      required: true,
    },
  },
}
</script>
