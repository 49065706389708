import { defineDateFormat, fetchExportFile } from '../export.js'
import ReportFiltersFactory from '@/specific/reports/ReportFiltersFactory'
import {
  getHeader,
  getReportType,
  REFERRALS_SUMMARY,
  REFERRALS_UNGROUPED,
  REFERRALS_UNGROUPED_REFERRAL,
} from '@/specific/reports/referrals/common'
import { MAX_LIMIT } from '@/specific/reports/const'
import { createFiltersProfilesAppForLegacy } from '@/vue_apps/FiltersProfilesApp'
import { FILTERS_PROFILES_TYPES } from '@/vue_apps/FiltersProfilesApp/const/consts'
import {
  getReferralsReportInitialFilters,
  saveReferralsReportInitialFilters as saveInitialFilters,
} from './initialFIlters'
import {
  referralsReportFiltersAccessors as filtersAccessors,
  resetCategorySelect,
  resetEntrySelect,
} from './filtersAccessors'
import {
  removeFilterValidationWrapper,
  showFiltersErrors,
} from '@/vue_apps/FiltersProfilesApp/helpers/legacyErrorsRenderingHelpers'
import { referralsReportFiltersSelectors as filtersSelectors } from './filtersSelectors'
import { createVueApp } from '@/vue_components/create_vue_app'
import ReferralsApp from '@/vue_components/reports/referrals/ReferralsApp.vue'

$document.on('rez/reports/referrals', function () {
  /* Приложение шаблонов */
  $('#report_form').on('change', removeFilterValidationWrapper)

  createFiltersProfilesAppForLegacy({
    filtersSetter ({ filters, errors }) {
      filtersAccessors.set(filters)
      showFiltersErrors(errors, filtersSelectors)
    },
    filtersGetter: filtersAccessors.get,
    profileType: FILTERS_PROFILES_TYPES.REFERRALS,
  })

  /* Отчёт */
  Report.init()
  saveInitialFilters()

  const initialFilters = getReferralsReportInitialFilters()

  const selectEntryType = $(filtersSelectors.entry)
  const selectCategory = $(filtersSelectors.category)

  selectEntryType.change($.proxy(function () {
    if (this.val()) {
      resetCategorySelect(initialFilters.category)
      selectCategory.attr('disabled', 'disabled')

      return
    }

    selectEntryType.removeAttr('disabled')
  }, selectEntryType))

  selectCategory.change($.proxy(function () {
    if (this.val()) {
      resetEntrySelect(initialFilters.entry)
      selectEntryType.attr('disabled', 'disabled')

      return
    }

    selectCategory.removeAttr('disabled')
  }, selectCategory))

  const reportTable = createVueApp({
    el: '#report_right_container',
    name: 'ReferralsReportApp',
    render: (h) => h(ReferralsApp),
    useChildren: true,
  })

  $('#report_submit').on('click', function () {
    reportTable.show()
    reportTable.updateFilters(filtersAccessors.get())
  })

  // скрытие фильтров для сводного отчёта, показ для подробного
  const hiddenFilters = $(filtersSelectors.referralSummaryFiltersContainer)

  $(filtersSelectors.reportTypeFilter).on('change', (event) => {
    if (event.target.value === REFERRALS_SUMMARY) {
      hiddenFilters.hide()
      hiddenFilters.find('select').val('').trigger('change')

      return
    }

    hiddenFilters.show()
  })

  $('#report_reset').on('click', () => {
    reportTable.hide()
    hiddenFilters.show()
  })

  $('.export').on('click', function () {
    const filters = filtersAccessors.get()
    const reportType = getReportType(filters)
    const headerRow = getHeader(reportType)
    filters.reportType = reportType

    const data = {
      dateTimeFormat: `${defineDateFormat()}, 15:04`,
      csvComma: gon.application.csv_character,
      floatingComma: gon.localization.currency.decimal_character,
      headerRow,
      totalRow: {
        total: t('total'),
      },
      referralTotalRow: {
        total: t('total'),
      },
      callType: {
        incoming: T.enums.call.call_type.incoming,
        outcoming: T.enums.call.call_type.outcoming,
      },
      callStatus: {
        accepted: T.enums.call.status.accepted,
        lost: T.enums.call.status.lost,
      },
      callResult: {
        inProgress: T.enums.call.result.in_progress,
        appointmentAdded: T.enums.call.result.appointment_added,
        refused: T.enums.call.result.refused,
        finished: T.enums.call.result.finished,
        appointmentConfirmed: T.enums.call.result.appointment_confirmed,
        appointmentCanceled: T.enums.call.result.appointment_canceled,
        onHold: T.enums.call.result.on_hold,
      },
      offset: 0,
      limit: MAX_LIMIT,
      report: {
        period: filters.period,
      },
      ...ReportFiltersFactory.defineFor('referrals', filters),
    }

    if (reportType === REFERRALS_UNGROUPED || reportType === REFERRALS_UNGROUPED_REFERRAL) {
      data.referralTotalRow = data.totalRow
    }

    fetchExportFile(data, Routes.report_referrals_path(), T.referrals)
  })
})
