import EgiszClientSendCard from '@/vue_components/egisz/egisz_client_card/EgiszClientSender'
import { createVueApp } from '@/vue_components/create_vue_app'

export const createEgiszClientCard = (el) => {
  if (!document.querySelector(el)) { return }

  createVueApp({
    el,
    name: 'CreateEgiszClientCardMaster',
    render: (h) => h(EgiszClientSendCard, {
      props: {
        clientId: gon.specific.client.id,
        canManage: Utils.can(Utils.ACTIONS.MANAGE, 'Egisz'),
      },
    }),
  })
}
