<template>
  <div
    v-show="visibility"
    id="mango_control_panel"
    class="mango"
  >
    <epic-spinner :visibility="showLoadingSpinner" />
    <table class="show_info_table">
      <tr>
        <td>{{ T.telephony.mango.mango_extension }}</td>
        <td>
          <div class="field-with-btn">
            <div class="field-with-btn_field">
              <el-input
                v-model="mango_extension"
                type="string"
                placeholder="ID"
              />
            </div>
            <div class="field-with-btn_btn">
              <el-button
                :loading="buttonLoading"
                type="warning"
                size="mini"
                icon="fad fa-fw fa-sync-alt"
                @click="syncUser"
              >
                {{ T.telephony.sync_user }}
              </el-button>
            </div>
          </div>
        </td>
      </tr>
    </table>
    <div class="control">
      <el-button
        type="success"
        size="mini"
        :class="'btn-with-icon'"
        @click="updateUser"
      >
        <span class="btn-with-icon_icon fad fa-save" />
        <span class="btn-with-icon_text">{{ t('save').toUpperCase() }}</span>
      </el-button>
    </div>
  </div>
</template>

<script>
import EpicSpinner from '../../../epic_spinner/epic_spinner.vue'

export default {
  name: 'MangoControlPanel',
  components: {
    EpicSpinner,
  },
  props: {
    user: {
      default: undefined,
      type: Object,
    },
    visibility: Boolean,
  },
  data () {
    return {
      buttonLoading: false,
      showLoadingSpinner: false,
      mango_extension: null,
    }
  },
  mounted () {
    if (this.user) {
      this.mango_extension = this.user.mango_extension
    }
  },
  methods: {
    syncUser () {
      this.buttonLoading = true
      $.ajax({
        url: Routes.sync_user_telephony_telephony_route_index_path('mango'),
        method: 'POST',
        data: {
          user_id: this.user.id,
        },
      })
        .success((data) => {
          this.mango_extension = data.mango_extension
          Notificator.success(T.telephony.user_updated)
        })
        .error((data) => {
          console.error(data)
          Notificator.error(T.telephony.mango.errors[data.responseJSON])
        })
        .complete(() => {
          this.buttonLoading = false
        })
    },

    updateUser () {
      $.ajax({
        url: Routes.user_path(this.user.id),
        method: 'PATCH',
        data: {
          user: {
            mango_extension: this.mango_extension,
          },
        },
      })
        .success((data) => {
          Notificator.success(T.telephony.settings_updated)
        })
        .error((data) => {
          console.error(data)
          Notificator.error(T.telephony.mango.errors[data.responseJSON])
        })
    },
  },
}
</script>
