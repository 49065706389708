<template>
  <search-select
    :clearable="clearable"
    custom-class="full-width"
    value-key="id"
    :value="value"
    :not-results="noResults"
    :loading="loading"
    :allow-search="allowSearch"
    :search-query.sync="searchQuery"
    :disabled="disabled"
    @change="$emit('change', $event)"
    @update:searchQuery="search"
    @clear="$emit('change', null)"
  >
    <el-option
      v-for="option in optionsList"
      :key="option.id"
      :value="option"
      :label="clientName(option)"
    >
      {{ clientName(option) }}
    </el-option>
  </search-select>
</template>

<script>
import SearchSelect from '@/vue_components/common/select/search_select/search_select'
import { ElSelectFix } from '@/vue_components/common/select/elSelectFix'
import { searchClients } from '@/vue_components/doctor_schedules/rest'
import { PAGE_SIZE_SELECTOR } from '@/vue_components/common/select/consts'
import formatter from '@/lib/formatters/formatter'

/**
 * @typedef {{
 *   id: number,
 *   name?: string,
 *   surname?: string,
 *   second_name?: string,
 *   birthdate?: string,
 *   masked_phone?: string
 *   formatted_phone?: string
 * }} ClientSearchItem
 *
 * @callback getClients
 * @param {{surname?: string, name?: string, second_name?: string, phone?: string}} query
 * @returns {Promise<ClientSearchItem[]>}
 */

export default {
  name: 'ClientSelect',

  components: { SearchSelect },

  mixins: [ElSelectFix],

  props: {
    dateFormat: {
      type: String,
      required: true,
    },
    /**
     * @type {import('vue').PropOptions<getClients>}
     */
    getClients: {
      type: Function,
      default: searchClients,
    },
    clinicId: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    /**
     * @type {import('vue').PropOptions<ClientSearchItem>}
     */
    value: {
      type: Object,
      default: () => null,
    },
    /**
     * @type {import('vue').PropOptions<ClientSearchItem[]>}
     */
    defaultOptions: {
      type: Array,
      default: () => [],
    },
    pageSize: {
      type: Number,
      default: PAGE_SIZE_SELECTOR,
    },
  },
  data () {
    return {
      error: false,
      loading: false,
      searchQuery: '',
      pagination: {
        currentPage: 1,
        pageCount: 1,
      },
      noResults: false,
    }
  },
  computed: {
    allowSearch () {
      return this.searchQuery.length >= 3
    },
  },
  methods: {
    clientName (client) {
      if (client) {
        const birthdate = client.birthdate
          ? moment(client.birthdate).format(this.dateFormat)
          : ''

        return [
          formatter.formatValue({
            name: client.name,
            surname: client.surname,
            secondName: client.second_name,
          }, ['client:fullName']),
          birthdate,
          this.getPhone(client),
        ]
          .filter(Boolean)
          .join(', ')
      }

      return ''
    },
    search (query) {
      if (this.allowSearch) {
        this.getClients(query)
          .then((clients) => {
            this.optionsList = clients
            this.noResults = !clients.length
          })
      } else {
        this.noResults = false
        this.optionsList = []
      }
    },
    getPhone (client) {
      if (!Services.security.canManageClientPhone) {
        return client.masked_phone || formatter.formatValue(client.phone, ['client:phone'])
      }

      return client.formatted_phone || client.phone
    },
  },
}
</script>
