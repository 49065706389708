import AppointmentDurationsCatalog from './appointment_durations'
import { createVueApp } from '@/vue_components/create_vue_app'

export const VueAppointmentDurationEntryPoint = () => {
  const el = '#vue_appointment_durations_catalog'

  if (!document.querySelector(el)) { return }
  createVueApp({
    el,
    render: (h) => h(AppointmentDurationsCatalog),
  })
}
