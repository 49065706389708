import ExportPage from '@/vue_components/export/export.vue'
import { createVueApp } from '@/vue_components/create_vue_app'

$document.on('rez/exports/index', () => {
  if (gon.specific.moduleIsEnabled) {
    return createVueApp({
      el: '#exports_container',
      name: 'ExportPageApp',
      render: (h) => h(ExportPage),
    })
  }
})
