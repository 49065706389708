import CompanyTypesCatalog from './company_types'
import { createVueApp } from '@/vue_components/create_vue_app'

export const VueCompanyTypesEntryPoint = () => {
  const el = '#vue_company_types_catalog'

  if (!document.querySelector(el)) { return }
  createVueApp({
    el,
    render: (h) => h(CompanyTypesCatalog),
  })
}
