<template>
  <si-table>
    <template #header>
      <si-field>
        <div class="catalogs_table__title">
          <span>
            {{ t('title') }}
          </span>
        </div>

        <div class="discount-reasons-table__status">
          <span>
            {{ t('status') }}
          </span>
        </div>
      </si-field>
    </template>

    <template #body>
      <si-field
        v-for="reason in reasons"
        :key="reason.id"
        class="catalogs_table__item"
        :class="{
          'not-active': !reason.active,
          active: selectedListItem === reason.id
        }"
        @click="$emit('ask-edit', reason)"
      >
        <div class="catalogs_table__title">
          <span class="fad fa-fw fa-badge-percent catalogs_table__icon" />

          <span class="cut">
            {{ reason.title }}
          </span>
        </div>

        <div
          class="discount-reasons-table__status"
        >
          <span>
            {{ setStatusName(reason.active) }}
          </span>
        </div>
      </si-field>
    </template>
  </si-table>
</template>

<script>
import SiTable from '@/vue_components/sort_items/si_table/si_table'
import SiField from '@/vue_components/sort_items/si_table/si_field'

export default {
  name: 'DiscountReasonsTable',

  components: {
    SiTable,
    SiField,
  },

  props: {
    reasons: {
      type: Array,
      default: () => [],
    },

    selectedListItem: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    setStatusName (status) {
      return status
        ? t('enums.discount_system.status.enabled')
        : t('enums.discount_system.status.disabled')
    },
  },
}
</script>
